import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { BtnLink, Container, Item, Sales, ToolItem, ItemContainer, ItemColor} from '../../Rep/DailySale/DailySaleElements'
import { TextField, Tooltip } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import { Grid, CircularProgress, Button} from '@mui/material'

export const Retire = () =>{
    
    return (
        <>
        <Button style={{width: 110}} variant='outlined' color="error">Retire</Button>
        </>
      )
}

export const Delete = () =>{
    
    return (
        <Grid style={{display: 'flex', marginLeft: -40}}>
            <Grid style={{display: 'flex', marginRight: 15}}>
                <Button variant='contained' color="info">Active</Button>
            </Grid>
            <Grid>
                <Button variant='contained' color="error">Delete</Button>
            </Grid>
        </Grid>
      )
}

export const TextBox = ({item, desc, width, disabled}) =>{
    
    return (
        <TextField style={{width: width, textAlign: 'center', justifyContent: 'center', alignContent: 'center'}} variant='outlined' disabled={disabled} defaultValue={item} label={desc}/>
      )
}

function User({employee}) {

    const [isActive, setIsActive] = useState(employee?.isActive)
    const [canEdit, setCanEdit] = useState(false)

    const [employeeID, setEmployeeID] = useState(employee?.employeeID)
    const [email, setEmail] = useState(employee?.email)
    const [role, setRole] = useState(employee?.role)
    const [store, setStore] = useState(employee?.workplace?.store)
    const [region, setRegion] = useState(employee?.workplace?.region)
    const [area, setArea] = useState(employee?.workplace?.area)



  return (
    <>
    {!canEdit ?
        <Container>
            <Sales>
                <ItemContainer><Item>{employee.isActive ? 'Active' : 'Retired'}</Item></ItemContainer>
                <ItemContainer><Item>{employee?.employeeID}</Item></ItemContainer>
                <ItemContainer><Item>{employee?.firstName} {employee?.lastName}</Item></ItemContainer>
                <ItemContainer><Item>{employee?.workplace.brand}</Item></ItemContainer>
                <ItemContainer><Item>{employee?.workplace.region}</Item></ItemContainer>
                <ItemContainer><Item>{employee?.workplace.area}</Item></ItemContainer>
                <ItemContainer><Item>{employee?.workplace.store}</Item></ItemContainer>
                <ItemContainer>
                    <Button style={{width: 110}} variant='contained' color="primary" onClick={() => setCanEdit(canedit => !canEdit)}>EDIT</Button>
                </ItemContainer>
                <ItemContainer>{isActive ? <Retire /> : <Delete />}</ItemContainer>
            </Sales>
        </Container>
    : 
    <Container style={{backgroundColor: '#fff'}}>
        <div style={{
            border: '5px solid #C9D6DF',
            borderBottom: '0px',
            display: 'flex',
            height: 50,
            borderTopRightRadius: 15,
            borderTopLeftRadius: 15,
            margin: 10,
            marginBottom: 0,
            padding: 15,
            paddingBottom: 0,
            justifyContent: 'space-evenly'
        }}>
            <ItemContainer><Item>{employee.isActive ? 'Active' : 'Retired'}</Item></ItemContainer>
            <ItemContainer><Item>{employee?.employeeID}</Item></ItemContainer>
            <ItemContainer><Item>{employee?.firstName} {employee?.lastName}</Item></ItemContainer>
            <ItemContainer><Item>{employee?.workplace.brand}</Item></ItemContainer>
            <ItemContainer><Item>{employee?.workplace.region}</Item></ItemContainer>
            <ItemContainer><Item>{employee?.workplace.area}</Item></ItemContainer>
            <ItemContainer><Item>{employee?.workplace.store}</Item></ItemContainer>
                <ItemContainer>
                    <Button style={{width: 110}} variant='contained' color="primary" onClick={() => setCanEdit(canedit => !canEdit)}>EDIT</Button>
                </ItemContainer>
            <ItemContainer>{isActive ? <Retire /> : <Delete />}</ItemContainer>
        </div>
        <div style={{
            border: '5px solid #C9D6DF',
            borderTop: '0px',
            display: 'flex',
            height: 50,
            borderBottomRightRadius: 15,
            borderBottomLeftRadius: 15,
            margin: 10,
            marginTop: 0,
            padding: 15,
            paddingTop: 0,
            justifyContent: 'space-evenly'
        }}>
            <Grid container spacing={1}>
                <Grid item xs={'auto'}>
                    <TextBox item={employeeID} desc={'Employee ID'} width={105} disabled={true}/>
                </Grid>
                <Grid item xs={'auto'}>
                    <TextBox item={email} desc={'Employee Email'} width={300} disabled={false}/>
                </Grid>
                <Grid item xs={'auto'}>
                    <TextBox item={region} desc={'Region'} width={100} disabled={false}/>
                </Grid>
                <Grid item xs={'auto'}>
                    <TextBox item={area} desc={'Area'} width={50} disabled={false}/>
                </Grid>
            </Grid>
        </div>
    </Container>
}
    </>
    )
}

export default User