import { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import { Outlet, Navigate, useNavigate } from 'react-router-dom'
import axios from 'axios'

const ProtectedRoutes = ({PermissionsRequired, MaxPermission}) => {

    const alert = useAlert()
    const navigate = useNavigate()
    const permission = sessionStorage.getItem('permission')
    const store = sessionStorage.getItem('store')

    // if(!permission){
    //     return
    // }
    // if((permission < PermissionsRequired) || (permission > MaxPermission)){
    //     return alert('no access')
    // }

    useEffect(() => {
        async function FetchData(){
            try{

            await axios.get(`/store/status`, {withCredentials: true});

            }catch(err){
                if(permission > 0 ){alert.error('Signed Out', {timeout: 5000})}else{alert.error('No Access')}
                sessionStorage.clear()
                navigate("/")
            }
        
    }
    FetchData()
    }, [])

     function auth(){
        let access = false

            if((permission >= PermissionsRequired && (permission <= MaxPermission)) || permission == 333){
                access = true
            }

        return access
    }

     function isLowerAuth(){
        let access = false

        if(permission != null && store!= null){
            if(permission < PermissionsRequired || permission > MaxPermission){
                access = true
                alert.error("Not Authorized") 
            }
        }

        if(!access){
            sessionStorage.clear()
            alert.error("Access Denied") 
        }

        return access
    }
  return (
    <>
    {auth() ? <Outlet/> : isLowerAuth() ? <Navigate to={`/${store}`}/> : <Navigate to='/'/>}
    </>
  )
}

export default ProtectedRoutes