import React, {useState} from 'react'
import { SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarLink, SidebarMenu, SideBtnWrap, SideBtn} from './SidebarElements'
import {Temporal} from '@js-temporal/polyfill'

const Sidebar = ({isOpen, toggle}) => {

    let store = sessionStorage.getItem('store')

    let currentDate = Temporal.Now.plainDateISO()
    const[year, setYear] = useState(currentDate.year)
    const[month, setMonth] = useState(currentDate.month)
    const[day, setDay] = useState(currentDate.day)
    const[week, setWeek] = useState(currentDate.add({days:1}).weekOfYear)

    const permission = sessionStorage.getItem('permission')

  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
            <CloseIcon />
        </Icon>
        <SidebarWrapper>
            <SidebarMenu>
                <SidebarLink to={"/" + store}>Home</SidebarLink>
                <SidebarLink to={"/" + store + "/daily/" + year + "/" + month + "/" + day}>Daily Sales</SidebarLink>
                <SidebarLink to={"/" + store + "/weekly/" + year + "/" + week}>Weekly Sales</SidebarLink>
                <SidebarLink to={"/" + store + "/monthly/" + year + "/" + month}>Monthly Sales</SidebarLink>
                <SidebarLink to={"/" + store + "/return"}>Returns</SidebarLink>
                {(permission >= 3) && 
                <SidebarLink to={"/" + store + "/management"}>Management</SidebarLink>
                }
                <SideBtnWrap style={{justifyContent:'center', paddingTop: 20}}>
                    <SideBtn to="/" onClick={() => {sessionStorage.clear()}}>Sign out</SideBtn>
                </SideBtnWrap>
            </SidebarMenu>
            
        </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar