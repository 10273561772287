import React, { useState, useEffect } from 'react'
import axios from 'axios'
import {Container, Header} from './ReturnPageElements'
import { useLocation } from 'react-router-dom'
import ReturnForm from '../ReturnForm'
import { Temporal } from '@js-temporal/polyfill'
import { Grid, Paper } from '@material-ui/core'
import { Button, MenuItem, TextField } from '@mui/material'
import { useAlert } from 'react-alert'
import ReturnSale from '../ReturnSale'


function Return() {
    const alert = useAlert()
    let currentDate = Temporal.Now.plainDateISO()
    const[year, setYear] = useState(currentDate.year)
    const[month, setMonth] = useState(currentDate.month)
    const[day, setDay] = useState(currentDate.day)

    const [returnRep, setReturnRep] = useState(null)

    const [employees, setEmployees] = useState([])

    const [oldSale, setOldSale] = useState(null)
    //user info

    const store = sessionStorage.getItem('store')

    let location = useLocation()

    const thisEmployee= {
      "firstName": sessionStorage.getItem('name'), 
      "employeeID": parseInt(sessionStorage.getItem('employee')),
      store: parseInt(sessionStorage.getItem('store'))
  }
  const handleSubmit = async (e) => {
    setOldSale(null)
    e.preventDefault();
      let inputDate = Temporal.PlainDate.from(document.getElementById('DATE').value)
        let info = {
          employeeID: parseInt(returnRep),
          store: store,
          year: inputDate.year,
          month: inputDate.month,
          day: inputDate.day
        }
      try{
         const getSale = await axios.put(`/sales/employee/sale`, {info}, {withCredentials: true})
         if(getSale?.data?.sale?.commission == 0 && getSale?.data?.sale?.sale?.bpo == 0){
            alert.error("No Sales Found!")
         }else{
          setOldSale(getSale?.data?.sale)
          alert.success('Sale Found!')
         }
      }catch(err){
        alert.error("No Sales Found!")
      }
    }

    function validateDate(e){
      let inputDate = Temporal.PlainDate.from(e.target.value)
      if((Temporal.PlainDate.compare(currentDate.subtract({days: 16}), inputDate) === -1) && (Temporal.PlainDate.compare(currentDate, inputDate) !== -1)){
        return
      }else{
        e.target.value = `${currentDate}`
        alert.error('Please enter a valid date within the last 15 days')
      }
    }


    useEffect( () => {
        async function FetchData(){
          try{
            const getActive = await axios.put(`/store/all`, {store}, {withCredentials: true})
            setEmployees(getActive?.data?.users)
          }catch(err){
            console.log(err.data)
          }
        }
        FetchData()
    }, [location])
    
  return (
    <>
        <Container>
            <Header>Return</Header>
        </Container>

        {(employees !== null) && 
        <Paper style={{paddingTop: 150, margin: 50, paddingBottom: 150}}>

          <form onSubmit={handleSubmit}>

        {!oldSale && <Grid container spacing={3} style={{justifyContent: 'center', alignItems: 'center'}}>
          <Grid item xs={'auto'} >
            <TextField style={{width: 225, height: 60}} type={'date'} id="DATE" defaultValue={`${currentDate}`} onChange={(e) => validateDate(e)}>date</TextField>
          </Grid>
          <Grid item xs={'auto'}>
            <TextField 
            style={{width: 225, height: 60}}
            label='Employee'
            variant='outlined'
            select
            defaultValue={''}
            required
            onChange={(e) => (setReturnRep(e.target.value))}
            >
            {employees?.map((employee) => (
                <MenuItem key={employee?.employeeID} value={employee?.employeeID}>
                    {employee?.firstName}
                </MenuItem>
            ))}
            </TextField>
          </Grid>
          <Grid item xs={'auto'} style={{alignItems: 'center'}}>
            <Button style={{width: 225, height: 50, borderRadius: 15}} type='submit' color='primary' variant="contained">Search</Button>
          </Grid>
        </Grid>}
        </form>
        {oldSale && <Grid container> 

        <Grid container item xs={12}  style={{alignItems: 'center', justifyContent: 'center'}}> 

          <Grid item xs={'auto'}> 
            <ReturnSale sale={oldSale} />
          </Grid>

          <Grid item xs={'auto'}> 
            <Button style={{width: 225, height: 50, borderRadius: 15, backgroundColor: 'red', color: 'white'}} onClick={() => setOldSale(null)}  variant="contained">Cancel</Button>
          </Grid>

        </Grid>  

        <Grid item xs={12}> 
          <ReturnForm Sale={oldSale} />
        </Grid>  

      </Grid> }
      </Paper>}

    </>
  )
}

export default Return