import styled from 'styled-components'
import {Link as LinkR} from 'react-router-dom'

export const Container = styled.div`
    width: 80%;
    margin: auto;
    font-size: 1rem;
`;

export const Sales = styled.div`
    background: #C9D6DF;
    display: flex;
    height: 50px;
    margin: 10px;
    border-radius: 10px;
    padding: 15px;
    justify-content: space-evenly;
    
`;

export const ItemContainer = styled.div`
    display: inline-grid;
    align-items: center;
    width: 100px;
    padding: 10px;
`;

export const ItemColor = styled.div`
    margin-top: 5px;
    margin-left: 25%;
    height: 5px;
    width: 50px; 
    opacity: 0.6;
    border-radius: 50px;
`;


export const Item = styled.div`
    text-align: center;
    font-weight: bold;
`;

export const ToolItem = styled.div`
    text-align: center;
    flex:1;
    order:0;
    background: none;
    font-size: 12px;
    width: 125px;
    padding: 10px;
`;

export const BtnLink = styled(LinkR)`
    border-radius: 10px;
    background: #393E46;
    white-space: nowarp;
    padding: 8px 25px;
    color: #F0F5F9;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:active {
        background: #222831
    }
`;