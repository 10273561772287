import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Sidebar from '../../Components/Rep/Sidebar'
import Navbar from '../../Components/Rep/Navbar'
import DailySale from '../../Components/Rep/DailySale'
import { useLocation, useParams } from 'react-router-dom'

const Sale = () => {

    const [isOpen, setIsOpen] = useState(false)
    const [sale, setSale] = useState()
    const location = useLocation()
    const {id} = useParams()
    const token = sessionStorage.getItem('accessToken')
    const[hasResults, setHasResults] = useState(false);

    
    const [isLoading, setIsLoading] = useState(false)

    //console.log(id)



    const toggle = () => {
        setIsOpen(!isOpen)
    }


    useEffect(() => {
        async function getUserInfo() {
            if(!hasResults){
                setHasResults(true);
                const userSale = await axios.get(`https://therift.herokuapp.com/sale/` + id, {headers:{authorization: 'Bearer ' + token}});
                
                setSale(userSale.data);
                
            }
        }

        getUserInfo()
        if(!sale){
            setHasResults(false);
            getUserInfo()
        }

        console.log(sale)
    }, [location])

  return (
    <>
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <Navbar toggle={toggle}/>

        <DailySale sale={sale} />
        
    </>
  )
}

export default Sale