import {MenuItem, TextField, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { Form } from './ManagementElements'
import {Button, CircularProgress, Grid} from '@mui/material';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { Temporal } from '@js-temporal/polyfill';

function Alerts({memo}) {
    
    const navigate = useNavigate()

    const alert = useAlert()

    const [isLoading, setIsLoading] = useState(false)
    let currentDate = Temporal.Now.plainDateISO()
    const[year, setYear] = useState(currentDate.year)
    const[month, setMonth] = useState(currentDate.month)
    const[day, setDay] = useState(currentDate.day)

    const [urgency, setUrgency] = useState("Low")

    let store = sessionStorage.getItem('store')
    let editable = false
    if(memo?.title === '') {editable = true}

    const priority = [
        {
            value: 'Critical',
            label: 'Critical'
        },
        {
            value: 'High',
            label: 'High'
        },
        {
            value: 'Medium',
            label: 'Medium'
        },
        {
            value: 'Low',
            label: 'Low'
        },

    ]

    const alertPopup = {
        store: '',
        title: '',
        date: '',
        urgency: 'Low',
        memo: '',
        updatedby: ''
    }



    const  handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true) 
        alertPopup.store = store
        alertPopup.title = document.getElementById('title').value
        alertPopup.date = `${year}/${month}/${day}`
        alertPopup.urgency = urgency
        alertPopup.memo = document.getElementById('memo').value
        alertPopup.updatedby = sessionStorage.getItem('name')
        try{

        let result = await axios.put(`/store/alert/set`, {alertPopup}, {withCredentials: true})
        result = result.data
        setIsLoading(false)

        alert.success(result.message)

        setTimeout(function() {window.location.reload()}, 1000);

        }catch(err){
            alert.error(err.response.data.message)
        }

        setIsLoading(false)
    }

    async function deletePost(){
        setIsLoading(true) 
        try{
        let result = await axios.put(`/store/alert/delete/` + store, {withCredentials: true})
        setIsLoading(false)

        alert.success(result.data.message)

        setTimeout(function() {window.location.reload()}, 1000);
        }catch(err){
            setIsLoading(false)
            alert.error(err.response.data.message)
        }
    }

  return (
    <Grid container style={{ marginBottom: 25, justifyContent: 'center'}}>
        <Grid container xs={12} style={{justifyContent: 'center'}}>
            <Typography variant='h4'>Alert</Typography>
        </Grid>
    
    <Form autoComplete='false' style={{justifyContent: 'center', borderStyle: 'solid', width: 550, borderColor:"#9e9e9e", borderRadius: 10, borderWidth: 1, marginTop: 25}} noValidate onSubmit={handleSubmit}> 
        
        { (isLoading) ? <div style={{justifyContent: 'center', marginTop: 15}}><CircularProgress /></div> : (editable) ? <>
        <Grid container style={{justifyContent: 'center', padding: 15}} spacing={3}>
            <Grid item xs={12}>
                <TextField
                id='title'
                label='Title'
                variant='outlined'
                type={'text'}
                defaultValue={memo?.title}
                required
                fullWidth
                autoComplete='false'
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                id='memo'
                label='Memo'
                variant='outlined'
                type={'text'}
                defaultValue={memo?.memo}
                style={{textAlign: 'center', justifyContect: 'center'}}
                autoComplete='off'
                multiline
                rows={8}
                maxRows={10}
                fullWidth
                />
            </Grid>
            <Grid item xs={'auto'}>
                <TextField 
                style={{margin: 10, width: 225}}
                id='urgency'
                label='Urgency'
                variant='outlined'
                select
                required
                defaultValue={urgency}
                onChange={(e) => (setUrgency(e.target.value))}
                >
                {priority.map((option) => (
                    <MenuItem disableRipple key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
                </TextField>
            </Grid>
        </Grid>
        <Button style={{margin: 10, borderRadius: 15}} variant="contained" type='submit' color='primary'>Post</Button>
        </> : <>
        <Grid container style={{justifyContent: 'center', padding: 15}} spacing={3}>
            <Grid item xs={12}>
                <TextField
                id='title'
                label='Title'
                variant='outlined'
                type={'text'}
                defaultValue={memo?.title}
                required
                disabled
                fullWidth
                autoComplete='false'
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                id='memo'
                label='Memo'
                variant='outlined'
                type={'text'}
                defaultValue={memo?.memo}
                style={{textAlign: 'center', justifyContect: 'center'}}
                autoComplete='off'
                multiline
                disabled
                rows={8}
                maxRows={10}
                fullWidth
                />
            </Grid>
            <Grid item xs={'auto'}>
                <TextField 
                style={{margin: 10, width: 225}}
                id='urgency'
                label='Urgency'
                variant='outlined'
                required
                disabled
                value={memo?.urgency}
                />
            </Grid>
        </Grid>
        <Button style={{margin: 10, borderRadius: 15}} variant="contained" onClick={() => deletePost()} color='error'>Delete</Button>
        </>}
    </Form>
    </Grid>
  )
}

export default Alerts