import React, { useState, useEffect } from 'react'
import axios from 'axios'
import DailySales from '../DailySale'
import DailySalesTotal from '../DailySalesTotal'
import {Item, SalesDiv, Container, Header, Btns, BtnLink, ItemContainer} from './DailySalesPageElements'
import { useLocation, useNavigate, useParams} from 'react-router-dom'
import {Temporal} from '@js-temporal/polyfill'
import { CircularProgress , Button, Modal, Paper, Grid} from '@mui/material'
import { useAlert } from 'react-alert'
import DailyStaff from '../DailyReps'
import DailyStats from '../DailyStats'
import DailyTally from '../DailyTally'

function Sales() {
    
    const alert = useAlert()
    const navigate = useNavigate()
    let location = useLocation()

    const store = sessionStorage.getItem('store')

    let {year, month, day} = useParams();
    year = parseInt(year)
    month = parseInt(month)
    day = parseInt(day)

    const [openReps, setOpenReps] = useState(false);
    const [openTally, setOpenTally] = useState(false);
    const [openStats, setOpenStats] = useState(false);
    const handleClose = () => {
        setOpenReps(false)
        setOpenStats(false)
        setOpenTally(false)
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.paper',
        borderRadius: 15,
        boxShadow: 24,
        p: 4,
      };

    const[inputYear, setInputYear] = useState(parseInt(year));
    const[inputMonth, setInputMonth] = useState(parseInt(month));
    const[inputDay, setInputDay] = useState(parseInt(day));

    const[repSales, setRepSales] = useState([]);

    const[storeSales, setStoreSales] = useState([]);
    
    const[storeStats, setStoreStats] = useState(null);
    
    const[noSaleReps, setNoSaleReps] = useState([]);

    
    const [isLoading, setIsLoading] = useState(true)


    let currentDate = Temporal.Now.plainDateISO()
    let inputDate = Temporal.PlainDate.from({ year: inputYear, month: inputMonth, day: inputDay })

    const [week, setWeek] = useState(inputDate.add({days:1}).weekOfYear)

    

    function doToggleReps(){
        setOpenReps((openReps) => true)
    }
    function doToggleStats(){
        setOpenStats((openStats) => true)
    }
    function doToggleTally(){
        setOpenTally((openTally) => true)
    }

    function Yesterday(){
        inputDate = inputDate.subtract({days: 1})

            setInputYear(inputDate.year)
            setInputMonth(inputDate.month)
            setInputDay(inputDate.day)
            navigate('/' + store + `/daily/` + inputDate.year + `/` + inputDate.month + `/` + inputDate.day)
            
            
    }

    function Tomorrow(){

        inputDate = inputDate.add({days: 1})

        if(Temporal.PlainDate.compare(currentDate, inputDate) !== -1){
            
            setInputYear(inputDate.year)
            setInputMonth(inputDate.month)
            setInputDay(inputDate.day)

            navigate('/' + store + `/daily/` + inputDate.year + `/` + inputDate.month + `/` + inputDate.day)
        }else{
            inputDate = inputDate.subtract({days: 1})
        }
    }



    useEffect(() => {
        setIsLoading(true)
        async function FetchData(){

            if(Temporal.PlainDate.compare(currentDate, inputDate) === -1){
                setInputYear(currentDate.year)
                setInputMonth(currentDate.month)
                setInputDay(currentDate.day)
            }
                try{
                const getUserDailySales = await axios.get(`/sales/daily/` + store + `/` + inputYear + `/` + inputMonth  + `/` + inputDay, {withCredentials: true});
                
                const getStoreDailySales = await axios.get(`/sales/store/daily/` + store + `/` + inputYear + `/` + inputMonth  + `/` + inputDay + `/` + week, {withCredentials: true})

                const getEmployees = await axios.get(`/store/users/` + store + `/` + inputYear + `/` + inputMonth  + `/` + inputDay, {withCredentials: true})

                const getStats = await axios.get(`/sales/stats/` + store + `/` + inputYear + `/` + inputMonth  + `/` + inputDay, {withCredentials: true})
                    

                setIsLoading(false)
                setStoreSales(getStoreDailySales.data.sale);
                setRepSales(getUserDailySales.data.sale);
                setNoSaleReps(getEmployees.data)
                setStoreStats(getStats.data.stats)
                }catch (err) {
                        setTimeout(function() {
                        window.location.reload()
                        }, 500);
                        console.log(err.response.data.message)
                        alert.error('Refreshing page')
                    if(err.response.data.message === 'Not Authorized! Login to gain access!' && err.response.data.success === false){
                        alert.error(err.response.data.message)
                        sessionStorage.clear()
                        setTimeout(function() {
                            navigate("/")
                            alert.success("Signed Out!")
                        }, 2000);
                    }
                }
                

                
            }

        FetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])



return (
    <>
        <Container style={{justifyContent: 'center', allignItems: 'center'}}>
            <Header>Daily Sales</Header>
            {!isLoading ? 
            <Grid container style={{backgroundColor: "white", justifyContent: 'center'}} spacing={3}>
                {Temporal.PlainDate.compare(currentDate, inputDate) === 0 && <Grid item><Button onClick={() => doToggleReps()} style={{backgroundColor: '#393E46', color: "white", paddingLeft: 20, paddingRight: 20, paddingTop: 10, paddingBottom: 10, borderRadius: 10}}>Daily Reps</Button></Grid>}
                {Temporal.PlainDate.compare(currentDate, inputDate) === 0 && <Grid item><Button onClick={() => doToggleTally()} style={{backgroundColor: '#393E46', color: "white", paddingLeft: 20, paddingRight: 20, paddingTop: 10, paddingBottom: 10, borderRadius: 10}}>Daily Tally</Button></Grid>}
                <Grid item><Button onClick={() => doToggleStats()}  style={{backgroundColor: '#393E46', color: "white", paddingLeft: 20, paddingRight: 20, paddingTop: 10, paddingBottom: 10, borderRadius: 10}}>statistics</Button></Grid>
            </Grid> 
            : null }
        </Container>
        {noSaleReps && 
        <Modal
        open={openReps}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Paper sx={style}>
            <DailyStaff employees={noSaleReps.users}/>
        </Paper>
        </Modal>
        }
        {noSaleReps && 
        <Modal
        open={openTally}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Paper sx={style}>
            <DailyTally StoreSales={storeSales}/>
        </Paper>
        </Modal>
        }
        {noSaleReps && 
        <Modal
        open={openStats}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Paper sx={style}>
            <DailyStats dailyStats={storeStats}/>
        </Paper>
        </Modal>
        }
        <Container>
            <Btns>
                <Item><BtnLink onClick={() => Yesterday()}>Back</BtnLink></Item>
                <Item></Item>
                <Item></Item>
                <Item></Item>
                <Item></Item>
                <Item></Item>
                <Item></Item>
                <Item></Item>
                <Item></Item>
                <Item></Item>
                <Item><BtnLink onClick={() => Tomorrow()}>Next</BtnLink></Item>
            </Btns>
        </Container>

        <Container>
            <SalesDiv>
                <ItemContainer><Item>REP</Item></ItemContainer>
                <ItemContainer><Item>NAC</Item></ItemContainer>
                <ItemContainer><Item>HUP</Item></ItemContainer>
                <ItemContainer><Item>MC</Item></ItemContainer>
                <ItemContainer><Item>ACC</Item></ItemContainer>
                <ItemContainer><Item>DP</Item></ItemContainer>
                <ItemContainer><Item>IGNITE</Item></ItemContainer>
                <ItemContainer><Item>MISC</Item></ItemContainer>
                <ItemContainer><Item>TOTAL SUBS</Item></ItemContainer>
            </SalesDiv>
        </Container>

        { isLoading && <Header style={{justifyContent: 'center', marginTop: 15}}><CircularProgress /></Header>}

        <div className="bg-light mt-4">
            {repSales && repSales.map((sale) => {
                return(
                <DailySales sale={sale} key={sale?.rep?.username} type='daily'/>
                )
            })}
        </div>
        <div>
            <DailySalesTotal sale={storeSales} type='daily'/>
        </div>
    </>
  )
}

export default Sales