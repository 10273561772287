import React from 'react'
import {Box, TextField, Grid} from '@mui/material'
import { Container } from '@mui/system'
import { Header } from '../DailySalesPage/DailySalesPageElements'
import WarningIcon from '@mui/icons-material/Warning';



const DailyAlert = ({memo}) => {
    if(memo.store === undefined){
        return window.location.reload()
    }

    sessionStorage.setItem('model', false)

    let color
   switch (memo?.urgency){
        case 'Low':
            color = 'green'
            break;
        case 'Medium':
            color = 'yellow'
            break;
        case 'High':
            color = 'orange'
            break;
        case 'Critical':
            color = 'red'
            break;
        default:
            color = 'blue'
            break;
   }

  return (
        <Container component={Box}>
                <Grid container style={{justifyContent: 'center', borderColor: 'black', border: 15, borderRadius: 25, alignItems: 'center'}}>
                    <Grid item xs={1}>
                        <WarningIcon fontSize='Large' style={{color: color, backgroundColor: 'black', borderRadius: 50, padding: 5}} />
                    </Grid>
                    <Grid container xs={10} style={{justifyContent: 'center'}}>
                        <Header style={{marginTop: 0, paddingBottom: 10}}>{memo?.title}</Header>
                    </Grid>
                    <Grid item xs={1}>
                        <WarningIcon fontSize='Large' style={{color: color, backgroundColor: 'black', borderRadius: 50, padding: 5}} />
                    </Grid>
                </Grid>

                <Grid container spacing={5} style={{justifyContent: 'center', marginTop: 0}}>
                    
                    <Grid item xs={'auto'}>
                    <TextField 
                        style={{width: 100, textAlign: 'center', justifyContect: 'center'}}
                        id='date'
                        type='text'
                        label='Date Posted'
                        autoComplete='off'
                        defaultValue={memo?.date}
                        variant= 'standard'
                        disabled
                    />
                    </Grid>

                    <Grid item xs={'auto'}>
                    <TextField 
                        style={{width: 100, textAlign: 'center', justifyContect: 'center'}}
                        id='urgency'
                        type='text'
                        label='Urgency'
                        autoComplete='off'
                        value={memo?.urgency}
                        variant= 'standard'
                        disabled
                    />
                    </Grid>
                    <Grid item xs={'auto'}>
                    <TextField 
                        style={{width: 100, textAlign: 'center', justifyContect: 'center'}}
                        id='postedby'
                        type='text'
                        label='Posted By'
                        autoComplete='off'
                        value={memo?.updatedby}
                        variant= 'standard'
                        disabled
                    />
                    </Grid>

                    <Grid item xs={10} style={{marginLeft: 25}}>
                    <TextField 
                        style={{textAlign: 'center', justifyContect: 'center'}}
                        id='memo'
                        label='Memo'
                        autoComplete='off'
                        multiline
                        rows={10}
                        value={memo?.memo}
                        variant= 'standard'
                        fullWidth
                        disabled
                        />
                    </Grid>
                </Grid>
        </Container>
  )
}

export default DailyAlert