import React, { useState } from 'react'
import axios from 'axios'
import {Temporal} from '@js-temporal/polyfill'
import { CircularProgress , Button,Box, Checkbox, TextField, Grid} from '@mui/material'
import { Container } from '@mui/system'
import { Header } from '../DailySalesPage/DailySalesPageElements'

const DailyStaff = ({employees}) => {
    const [isLoading, setIsLoading] = useState(false)
    const date = Temporal.Now.plainDateISO()

    const [users, setUsers] = useState(employees ? employees : { isActive: false, firstName: '', employeeID: ''})

    const FetchedUser = ({employee, index}) =>{
        const [toggle, setToggle] = useState(employee?.isActive)
        const changeToggle = (index, event) => {
            setToggle(toggle => !toggle)
            const values = [...users]
            values[index][event.target.name] = event.target.checked
            setUsers(values)  
        }
        return (
            <Grid container spacing={3} style={{marginTop: 5}}>

                <Grid item md={1} style={{marginLeft: 20, marginRight: 10}}>
                    <div style={{marginTop: 5}}>
                    <Checkbox 
                    label='is Working?'
                    variant='outlined'
                    name='isActive'
                    fullWidth
                    checked={toggle}
                    onChange={event => changeToggle(index, event)}
                    />
                    </div>
                </Grid>

                <Grid item md={5}>
                    <TextField 
                    label='Name'
                    variant='outlined'
                    name='firstName'
                    value={employee?.firstName}
                    disabled
                    fullWidth
                    />
                </Grid>

                <Grid item md={5}>
                    <TextField 
                    label='Employee ID'
                    variant='outlined'
                    name='employeeID'
                    value={employee?.employeeID}
                    disabled
                    fullWidth
                    />
                </Grid>
            </Grid> 
    )}



    const  handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        users[0].day = date.day
        users[0].month = date.month
        users[0].year = date.year
        users[0].week = date.add({days:1}).weekOfYear
        users[0].store = parseInt(sessionStorage.getItem('store'))
        let values = ([...users])
        try{
        const getUserDailySales = await axios.put(`/sales/create/daily`, {values}, {withCredentials: true});
        alert.success(getUserDailySales.data.message)
        }catch(err){
            console.log(err)
        }
        setIsLoading(false)
        window.location.reload()


    }
  return (
        <Container component={Box}>
                <Container fullWidth container style={{display: 'flex', justifyContent: 'center', marginBottom: 50}}>
                    <h1>Today's Sellers</h1>
                </Container>
                {isLoading ? <Header style={{justifyContent: 'center', marginTop: 15}}><CircularProgress /></Header> :
                <form>
                {(employees.length < 12 && users) ? users.map((employee, index) => (
                    <FetchedUser key={index} employee={employee} index={index}/>
                )) : null}
            <Container fullWidth container style={{display: 'flex', justifyContent: 'center', marginTop: 50}}>
                {(employees.length > 0 && users) ? <Button style={{width: 300}} variant='contained' onClick={handleSubmit}>Assign</Button> : 
                <Container fullWidth container style={{display: 'flex', justifyContent: 'center', marginBottom: 50}}>
                    <h3>Guess everyone is working!</h3>
                </Container> }
            </Container>
                </form>
            }
        </Container>
  )
}

export default DailyStaff