import {TextField, Typography} from '@material-ui/core'
import React, { useState } from 'react'
import {Button, CircularProgress, Grid, Card} from '@mui/material';
 import axios from 'axios'
// import { useNavigate } from 'react-router-dom';
 import { useAlert } from 'react-alert';
import { Temporal } from '@js-temporal/polyfill';






const Targets = ({employeesData, storeTargetsData}) => {

const alert = useAlert()
let employees = employeesData

const [storeTargets, setStoreTargets] = useState(storeTargetsData)
const [totalHours, setTotalHours] = useState(0)
let store = sessionStorage.getItem('store')
let currentDate = Temporal.Now.plainDateISO()
let year = currentDate.year
let month = currentDate.month
let week = currentDate.add({days:1}).weekOfYear


async function updateTargets(){
    let totalHours = storeTargets.targets.hours || 0
    let nacTotal = storeTargets.targets.nacTotal || 0
    let hupTotal = storeTargets.targets.hupTotal || 0
    let mcTotal = storeTargets.targets.mcTotal || 0
    let accessoriesTotal = storeTargets.targets.accessoriesTotal || 0
    let dpTotal = storeTargets.targets.dpTotal || 60
    let valid = true

    if(isNaN(totalHours) || isNaN(nacTotal) || isNaN(hupTotal) || isNaN(mcTotal) || isNaN(accessoriesTotal)|| isNaN(dpTotal)){
        return alert.error('Please fill all values!')
    }

    employees.forEach(async employee => {
        if(isNaN(employee.targets.hours)){
            valid = false
        }else{
        let hours = (employee.targets.hours / totalHours)
        employee.targets.nacTotal = Math.ceil(nacTotal * hours)
        employee.targets.hupTotal = Math.ceil(hupTotal * hours)
        employee.targets.mcTotal = Math.ceil(mcTotal * hours)
        employee.targets.accessoriesTotal = Math.ceil(accessoriesTotal * hours)
        employee.targets.dpTotal = document.getElementById('dpTotal').value
        console.log(employee.targets)}
    })
    if(valid){
        try{
        await axios.put(`/sales/monthly/set/rep/targets`, {employees}, {withCredentials: true})
        await axios.put(`/sales/monthly/set/store/targets`, {storeTargets}, {withCredentials: true})
        setTimeout(function() {window.location.reload()}, 500);
        alert.success('Monthly Targets Updated!')
        }catch(err){
            console.log(err)
            alert.error('Error Contact Admin')
        }
    }else{
        alert.error('Fill all Values!')
    }
}

function HandleChange(index, e){
        let data = [...employees]
        data[index]['targets'][e.target.id] = e.target.value
        employees = data
}

function HandleChangeStore(e){
    let data = storeTargets
        data['targets'][e.target.id] = e.target.value
        setStoreTargets(data)
        console.log(storeTargets.targets.nacTotal)
}



const EmployeeTarget = ({employee, index}) =>{
    
    return (
        <Grid item container fullwidth style={{alignItems: 'center', justifyContent: 'center'}} spacing={4} key={index}>
            <Grid item >
                <TextField 
                        style={{width: 140, textAlign: 'center', justifyContect: 'center'}}
                        id='name'
                        type='text'
                        label={employee?.employeeID}
                        autoComplete='off'
                        value={employee?.name}
                        variant= 'filled'
                        disabled
                    />
            </Grid>
            <Grid item>
                <TextField 
                        style={{width: 140, textAlign: 'center', justifyContect: 'center'}}
                        id='hours'
                        type='tel'
                        label='HOURS'
                        autoComplete='off'
                        variant= 'outlined'
                        defaultValue={employee?.targets?.hours}
                        onChange={(e) => HandleChange(index, e)}
                        required
                    />
            </Grid>
            <Grid item>
                <TextField 
                    style={{width: 140, textAlign: 'center', justifyContect: 'center'}}
                    id='nac'
                    type='tel'
                    label='NAC TARGET'
                    autoComplete='off'
                    variant= 'filled'
                    value={employee?.targets?.nacTotal}
                    disabled
                />
            </Grid>
            <Grid item>
            <TextField 
                    style={{width: 140, textAlign: 'center', justifyContect: 'center'}}
                    id='hup'
                    type='tel'
                    label='HUP TARGET'
                    autoComplete='off'
                    variant= 'filled'
                    value={employee?.targets?.hupTotal}
                    disabled
                />
            </Grid>
            <Grid item>
            <TextField 
                    style={{width: 140, textAlign: 'center', justifyContect: 'center'}}
                    id='MC'
                    type='tel'
                    label='MC TARGET'
                    autoComplete='off'
                    variant= 'filled'
                    value={employee?.targets?.mcTotal}
                    disabled
                />
            </Grid>
            <Grid item >
            <TextField 
                    style={{width: 140, textAlign: 'center', justifyContect: 'center'}}
                    id='acc'
                    type='tel'
                    label='ACC TARGET'
                    autoComplete='off'
                    variant= 'filled'
                    value={employee?.targets?.accessoriesTotal}
                    disabled
                />
            </Grid>
            <Grid item >
            <TextField 
                    style={{width: 140, textAlign: 'center', justifyContect: 'center'}}
                    id='dp'
                    type='tel'
                    label='DP TARGET'
                    autoComplete='off'
                    variant= 'filled'
                    value={employee?.targets?.dpTotal}
                    disabled
                />
            </Grid>
        </Grid>
      )
}

  return (
    <Card container style={{justifyContent: 'center',  marginBottom: 150}}> 
        <form>
            <Grid container style={{justifyContent: 'center', marginTop: 50, marginBottom: 50}}><Typography variant='h3'>Monthly</Typography></Grid>
            <Grid container style={{width: 1500}} spacing={3}> 

                <Grid item container fullwidth style={{alignItems: 'center', justifyContent: 'center'}} spacing={4}>
                    <Grid item >
                    <TextField 
                            style={{width: 140, textAlign: 'center', justifyContect: 'center'}}
                            id='STORE'
                            type='tel'
                            label='STORE'
                            autoComplete='off'
                            value={storeTargets?.store}
                            variant= 'filled'
                            disabled
                        />
                    </Grid>
                    <Grid item>
                    <TextField 
                            style={{width: 140, textAlign: 'center', justifyContect: 'center'}}
                            id='hours'
                            type='tel'
                            label='TOTAL HOURS'
                            autoComplete='off'
                            variant= 'outlined'
                            defaultValue={storeTargets?.targets?.hours}
                            onChange={(e) => HandleChangeStore(e)}
                            required
                        />
                    </Grid>
                    <Grid item>
                        <TextField 
                            style={{width: 140, textAlign: 'center', justifyContect: 'center'}}
                            id='nacTotal'
                            type='tel'
                            label='NAC TARGET'
                            variant= 'outlined'
                            defaultValue={storeTargets?.targets?.nacTotal}
                            onChange={(e) => HandleChangeStore(e)}
                            required
                        />
                    </Grid>
                    <Grid item>
                    <TextField 
                            style={{width: 140, textAlign: 'center', justifyContect: 'center'}}
                            id='hupTotal'
                            type='tel'
                            label='HUP TARGET'
                            autoComplete='off'
                            variant= 'outlined'
                            defaultValue={storeTargets?.targets?.hupTotal}
                            onChange={(e) => HandleChangeStore(e)}
                            required
                        />
                    </Grid>
                    <Grid item>
                    <TextField 
                            style={{width: 140, textAlign: 'center', justifyContect: 'center'}}
                            id='mcTotal'
                            type='tel'
                            label='MC TARGET'
                            autoComplete='off'
                            variant= 'outlined'
                            defaultValue={storeTargets?.targets?.mcTotal}
                            onChange={(e) => HandleChangeStore(e)}
                            required
                        />
                    </Grid>
                    <Grid item >
                    <TextField 
                            style={{width: 140, textAlign: 'center', justifyContect: 'center'}}
                            id='accessoriesTotal'
                            type='tel'
                            label='ACC TARGET'
                            autoComplete='off'
                            variant= 'outlined'
                            defaultValue={storeTargets?.targets?.accessoriesTotal}
                            onChange={(e) => HandleChangeStore(e)}
                            required
                        />
                    </Grid>
                    <Grid item >
                    <TextField 
                            style={{width: 140, textAlign: 'center', justifyContect: 'center'}}
                            id='dpTotal'
                            type='tel'
                            label='DP TARGET'
                            autoComplete='off'
                            variant= 'outlined'
                            defaultValue={storeTargets?.targets?.dpTotal}
                            onChange={(e) => HandleChangeStore(e)}
                            required
                        />
                    </Grid>
                </Grid>
                
            </Grid>
            {employees?.map((employee, index) => (<Grid style={{marginTop: 10}}><EmployeeTarget employee={employee} index={index}/></Grid>))}
            <Grid container xs={'auto'} style={{justifyContent: 'center', marginTop: 20, marginBottom: 50}}>
                {employees != undefined ? <Button style={{width: 300, backgroundClip: 'blue', borderRadius: 15, height: 50}} variant='contained' color={'primary'} onClick={() => updateTargets()}>Update Monthly Targets</Button> :
                <Button style={{width: 300, backgroundClip: 'red', borderRadius: 15, height: 50}} variant='contained' color={'error'} onClick={() => window.location.reload()}>Refresh Page</Button>}
            </Grid>
        </form>
    </Card>
  )
}

export default Targets