import React, { useState, useEffect } from 'react'
import axios from 'axios'
import {Item, SalesDiv, Container, Header} from './HomePageElements'
import { useLocation, useNavigate} from 'react-router-dom'
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom'


function Home() {

  const jwt = sessionStorage.getItem('jwt')

  const sdk = new ChartsEmbedSDK({
    baseUrl: 'https://charts.mongodb.com/charts-project-0-yruqm',
    chartId: '62c35fc4-ebb5-4a59-8075-32e9b5267bb0',
  })
  
  const chart = sdk.createChart({
    chartId: '62c35fc4-ebb5-4a59-8075-32e9b5267bb0',
  })

    //user info
    const[name, setName] = useState(sessionStorage.getItem('name'))

    let location = useLocation()

    const navigate = useNavigate()



    useEffect(() => {
        
        async function FetchData(){
          
          chart.render(document.getElementById("chart"))
        }

        FetchData()

    }, [location])
    
  return (
    <>
        <Container>
            <Header>Welcome back {name}!</Header>
        </Container>
        
        <Container id='chart' style={{width: 1500, height: 800}}>
        </Container>
        
    </>
  )
}

export default Home