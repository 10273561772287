import './sidebar.scss'
import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupIcon from '@mui/icons-material/Group';
import StoreIcon from '@mui/icons-material/Store';
import Reports from '@mui/icons-material/BarChart';
import Logs from '@mui/icons-material/ReceiptLong';
import Settings from '@mui/icons-material/SettingsApplications';

import React, { useEffect } from 'react'



function Sidebar() {



  return (
    <div className='sidebar'>
        <div className="top"><span className='logo'>Sales Tracker</span></div>
        <hr/>
        <div className="center">
            <ul>
                <p className="title">MAIN</p>
                <li><DashboardIcon className='icon'/><span>Dashbord</span></li>
                <li><GroupIcon className='icon'/><span>Users</span></li>
                <li><StoreIcon className='icon'/><span>Stores</span></li>
                <p className="title">MISC</p>
                <li><Reports className='icon'/><span>Reports</span></li>
                <li><Logs className='icon'/><span>Logs</span></li>
                <li><Settings className='icon'/><span>Settings</span></li>
                <p className="title">THEME</p>
            </ul>
            </div>
        <div className="bottom">
        <div className="colorOption"></div>
        <div className="colorOption"></div>
        </div>
        </div>
  )
}

export default Sidebar