import React from "react";
import {
  BtnLink,
  Container,
  Item,
  Sales,
  ToolItem,
  ItemContainer,
  SalesDiv,
} from "./ReturnSaleElements";
import { Tooltip } from "@mui/material";

function ReturnSale({ sale }) {
  return (
    <>
      <Container>
        <SalesDiv style={{ color: "white" }}>
          <ItemContainer>
            <Item>REP</Item>
          </ItemContainer>
          <ItemContainer>
            <Item>NAC</Item>
          </ItemContainer>
          <ItemContainer>
            <Item>HUP</Item>
          </ItemContainer>
          <ItemContainer>
            <Item>MC</Item>
          </ItemContainer>
          <ItemContainer>
            <Item>ACC</Item>
          </ItemContainer>
          <ItemContainer>
            <Item>DP</Item>
          </ItemContainer>
          <ItemContainer>
            <Item>IGNITE</Item>
          </ItemContainer>
          <ItemContainer>
            <Item>MISC</Item>
          </ItemContainer>
          <ItemContainer>
            <Item>DATE</Item>
          </ItemContainer>
        </SalesDiv>
      </Container>
      <Container>
        <Sales>
          <ItemContainer>
            <Item>{sale?.name}</Item>
          </ItemContainer>

          <Tooltip
            placement="top"
            title={
              <div
                style={{
                  backgroundColor: "#393E46",
                  borderRadius: 5,
                  color: "white",
                  padding: 10,
                  margin: 3,
                }}
              >
                <ToolItem>MTM NAC: {sale?.sale?.nacByod}</ToolItem>
                <ToolItem>2YR NAC: {sale?.sale?.nacTerm}</ToolItem>
                <ToolItem>BSC NAC: {sale?.sale?.nacBasic}</ToolItem>
                <ToolItem>PREPAID: {sale?.sale?.nacPrepaid}</ToolItem>
              </div>
            }
          >
            <ItemContainer>
              <Item>{sale?.nacTotal}</Item>
            </ItemContainer>
          </Tooltip>

          <Tooltip
            placement="top"
            title={
              <div
                style={{
                  backgroundColor: "#393E46",
                  borderRadius: 5,
                  color: "white",
                  padding: 10,
                  margin: 3,
                }}
              >
                <ToolItem>HUP: {sale?.sale?.hup}</ToolItem>
                <ToolItem>TVM: {sale?.sale?.tvm}</ToolItem>
                <ToolItem>TVM%: {sale?.tvmAttach}%</ToolItem>
              </div>
            }
          >
            <ItemContainer>
              <Item>{sale?.hupTotal}</Item>
            </ItemContainer>
          </Tooltip>

          <Tooltip
            placement="top"
            title={
              <div
                style={{
                  backgroundColor: "#393E46",
                  borderRadius: 5,
                  color: "white",
                  padding: 10,
                  margin: 3,
                }}
              >
                <ToolItem>MC: {sale?.sale?.mc}</ToolItem>
                <ToolItem>MC ATTACH: {sale?.mcAttach}%</ToolItem>
              </div>
            }
          >
            <ItemContainer>
              <Item>{sale?.mcTotal}</Item>
            </ItemContainer>
          </Tooltip>

          <Tooltip
            placement="top"
            title={
              <div
                style={{
                  backgroundColor: "#393E46",
                  borderRadius: 5,
                  color: "white",
                  padding: 10,
                  margin: 3,
                }}
              >
                <ToolItem>ACC: ${sale?.sale?.accessories}</ToolItem>
                <ToolItem>ARS: ${sale?.ars}</ToolItem>
              </div>
            }
          >
            <ItemContainer>
              <Item>${sale?.sale?.accessories}</Item>
            </ItemContainer>
          </Tooltip>

          <Tooltip
            placement="top"
            background="clear"
            title={
              <div
                style={{
                  backgroundColor: "#393E46",
                  borderRadius: 5,
                  color: "white",
                  padding: 10,
                  margin: 3,
                }}
              >
                <ToolItem>DP: {sale?.sale?.dp}</ToolItem>
                <ToolItem>DP%: {sale?.dpAttach}%</ToolItem>
              </div>
            }
          >
            <ItemContainer>
              <Item>{sale?.dpAttach}%</Item>
            </ItemContainer>
          </Tooltip>
          <Tooltip
            placement="top"
            title={
              <div
                style={{
                  backgroundColor: "#393E46",
                  borderRadius: 5,
                  color: "white",
                  padding: 10,
                  margin: 3,
                }}
              >
                <ToolItem>TV: {sale?.sale?.tv}</ToolItem>
                <ToolItem>Internet: {sale?.sale?.internet}</ToolItem>
                <ToolItem>Home Phone: {sale?.sale?.smartHome}</ToolItem>
              </div>
            }
          >
            <ItemContainer>
              <Item>
                {(sale?.sale?.tv +
                  sale?.sale?.internet +
                  sale?.sale?.smartHome) |
                  0}
              </Item>
            </ItemContainer>
          </Tooltip>
          <Tooltip
            placement="top"
            title={
              <div
                style={{
                  backgroundColor: "#393E46",
                  borderRadius: 5,
                  color: "white",
                  padding: 10,
                  margin: 3,
                }}
              >
                <ToolItem>MBB: {sale?.sale?.mbb}</ToolItem>
                <ToolItem>EXPRESS: {sale?.sale?.express}</ToolItem>
                <ToolItem>VALUEPACK: {sale?.sale?.valuepack}</ToolItem>
                <ToolItem>ARPU: ${sale?.sale?.arpu}</ToolItem>
                <ToolItem>BPO USED: ${sale?.sale?.bpo}</ToolItem>
                {/* {parseInt(sessionStorage.getItem("employee")) ===
                sale?.employeeID ? (
                  <ToolItem>EST EARNING: ${sale?.commission}</ToolItem>
                ) : null} */}
              </div>
            }
          >
            <ItemContainer>
              <Item>Extras</Item>
            </ItemContainer>
          </Tooltip>
          <ItemContainer>
            <Item>{sale?.date}</Item>
          </ItemContainer>
        </Sales>
      </Container>
    </>
  );
}

export default ReturnSale;
