import React, { useEffect, useState}from 'react'
import {FaBars} from 'react-icons/fa'
import {Nav, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavItem, NavLinks, NavLinksBlack, NavBtn, NavBtnLink} from './NavbarElements'
import axios from 'axios'
import fido from '../../../Assets/FidoLogo.png'
import rogers from '../../../Assets/RogersLogo.png'
import none from '../../../Assets/NoLogo.png'
import {useLocation, useNavigate} from 'react-router-dom'
import {Temporal} from '@js-temporal/polyfill'
import { useAlert } from 'react-alert'
import { Grid } from '@mui/material'


const Navbar = ({toggle}) => {
    const alert = useAlert()
    const[logo, setLogo] = useState(rogers)
    const[name, setName] = useState(sessionStorage.getItem('name'))
    const[area, setArea] = useState(sessionStorage.getItem('area'))
    const navigate = useNavigate()
    let location = useLocation()

    let currentDate = Temporal.Now.plainDateISO()

    useEffect(() => {
    }, [location])

    async function logOut(){
        try{
        let {data} = await await axios.get(`/store/logout`, {withCredentials: true})
        sessionStorage.clear()
        alert.success(data.message)
        sessionStorage.clear()
        setTimeout(function() {navigate('/')}, 2000);
        }catch(err) {
            alert.error(err.response.data.message)
            sessionStorage.clear()
            setTimeout(function() {navigate('/')}, 2000);
        }
    }

  return (
    <>
        <Nav>
        <div style={{justifyContent: 'center', alignItems: 'center', marginTop: 7}}>
          <img src={logo} style={{width: 30, height: 30, borderRadius: 50, backgroundColor: "white", padding: 10}} alt="Logo"/>
        </div>
            <NavbarContainer>
            
                <NavLogo to={"/area"}>{area}</NavLogo>
                <MobileIcon onClick={toggle}>
                    <FaBars />
                </MobileIcon>
                <NavMenu>
                    <Grid container spacing={3} style={{alignItems: 'center', marginLeft: 15, marginRight: 40}}>
                        <Grid item>
                        <NavItem>
                            <NavLinks to={"/area"}>Home</NavLinks>
                        </NavItem>
                        </Grid>
                        <Grid item>
                        <NavItem>
                            <NavLinks to={"/area/daily/" + currentDate.year + '/' + currentDate.month + '/' + currentDate.day}>Daily</NavLinks>
                        </NavItem>
                        </Grid>
                    </Grid>
                </NavMenu>
                <NavBtn>
                    <NavLinksBlack to={"/area"}>{name}</NavLinksBlack>
                    <NavBtnLink to="/" onClick={() => logOut()}>Sign out</NavBtnLink>
                </NavBtn>
            </NavbarContainer>
        </Nav>
    </>
  )
}

export default Navbar
