import React, {useState} from 'react'
import Navbar from '../../Components/Area/Navbar'
import HomePage from '../../Components/Area/HomePage'

const Home = () => {

    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

  return (
    <>
        <Navbar toggle={toggle}/>

        <HomePage/>
        
    </>
  )
}

export default Home