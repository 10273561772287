import React, { useState, useEffect } from 'react'
import { Form } from './ManagementElements'
import {Button, CircularProgress, Grid, TextField, Typography} from '@mui/material';
import axios from 'axios'
import { useNavigate, useLocation } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { Temporal } from '@js-temporal/polyfill';

function StoreInfo() {

    const navigate = useNavigate()

    let location = useLocation()

    const alert = useAlert()
    const [color, setColor] = useState('primary')
    const [isLoading, setIsLoading] = useState(false)

    let currentDate = Temporal.Now.plainDateISO()
    const[year, setYear] = useState(currentDate.year)
    const[month, setMonth] = useState(currentDate.month)

    const [storeData, setStoreData] = useState(null);

    let store = sessionStorage.getItem('store')


    useEffect(() => {
        setIsLoading(true)
        async function FetchData(){

            try{
                const getStoreData = await axios.get(`/sales/store/` + store + `/` + year + `/` + month, {withCredentials: true});
                setStoreData(getStoreData.data.info)
                setIsLoading(false)

            }catch (err) {
                if(err.response.status === 500){
                    setIsLoading(false)
                    console.log(err.response.data.message)
                    alert.error(err.response.data.message)
                }
                if(err.response.data.message === 'Not Authorized! Login to gain access!' && err.response.data.success === false){
                    alert.error(err.response.data.message)
                    sessionStorage.clear()
                    setTimeout(function() {
                        navigate("/")
                        alert.success("Signed Out!")
                    }, 2000);
                }
            }
                

                
            }

        FetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])



    const  handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        let storeInfo = {
            csat: parseFloat(document.getElementById('csat').value),
            bpo: parseFloat(document.getElementById('bpo').value)
        }
       
        if(sessionStorage.getItem('permission') >= 3){
            
            try{
            let {data} = await axios.put(`/sales/store/update/` + store + `/` + year + `/` + month, {storeInfo}, {withCredentials: true});
            alert.success('Updated Store')
            setTimeout(function() {window.location.reload()}, 500);
            }catch (err){
                alert.error(err.response)
            }
            
        }
        setIsLoading(false)
    }

  return (
    <>
    
    <Form autoComplete='false' style={{justifyContent: 'center', borderStyle: 'solid', width: 250, borderColor:"#9e9e9e", borderRadius: 10, borderWidth: 1, marginTop: 25}} noValidate onSubmit={handleSubmit}>
    { isLoading ? <div style={{justifyContent: 'center', marginTop: 15}}><CircularProgress /></div> : (!isLoading && storeData !== null) ?
    <Grid container style={{ marginBottom: 25, justifyContent: 'center'}} spacing={3}>
        
        <Grid container xs={12} style={{justifyContent: 'center', marginTop: 10}}>
            <Typography variant='h5' style={{ color: 'black', marginLeft: 10}}>Store Details</Typography>
        </Grid>
        
        <Grid item xs={6}>
            <TextField 
                style={{ textAlign: 'center', justifyContect: 'center'}}
                id='csat'
                type='tel'
                label={'CSAT'}
                autoComplete='off'
                variant= 'outlined'
                defaultValue={storeData?.csat} />
        </Grid>
        
        <Grid item xs={6}>
            <TextField 
                style={{textAlign: 'center', justifyContect: 'center'}}
                id='bpo'
                type='tel'
                label={'BPO'}
                autoComplete='off'
                variant= 'outlined'
                defaultValue={storeData?.bpo} />
        </Grid>
        
        <Grid item xs={6} style={{justifyContent: 'center'}}>
            <Button style={{margin: 10, backgroundColor: 'blue'}} variant="contained" type='submit' color={color}>Submit</Button>
        </Grid>

    </Grid>: null}
    </Form>
    </>
  )
}

export default StoreInfo