import React, { useState } from 'react'
import axios from 'axios'
import {Temporal} from '@js-temporal/polyfill'
import { CircularProgress, Button, Box, Checkbox, TextField, Grid} from '@mui/material'
import { Container } from '@mui/system'
import { Header } from '../DailySalesPage/DailySalesPageElements'
import { useAlert } from 'react-alert'

const DailyStats = ({dailyStats}) => {
    if(dailyStats.store === undefined){
        window.location.reload()
    }
    const alert = useAlert()
    const [isLoading, setIsLoading] = useState(false)
    const [edit, setEdit] = useState(false)
    const currentDate = Temporal.Now.plainDateISO()

    const store = sessionStorage.getItem('store')

    let inputDate = Temporal.PlainDate.from({ year: dailyStats?.year, month: dailyStats?.month, day: dailyStats?.day })

    let dailyStatsPost = {
        traffic: "",
        conversion: "",
        actionsandoffers: "",
        tvm: "",
        epu: "",
        notes: "",
        updatedby: sessionStorage.getItem('employee')
    }

    async function postStats(){
        setIsLoading(true)
        dailyStatsPost.traffic = document.getElementById('traffic').value
        dailyStatsPost.conversion = document.getElementById('conversion').value
        dailyStatsPost.actionsandoffers = document.getElementById('actionsandoffers').value
        dailyStatsPost.tvm = document.getElementById('tvm').value
        dailyStatsPost.epu = document.getElementById('epu').value
        dailyStatsPost.notes = document.getElementById('notes').value
        try{
            await axios.put(`/sales/stats/` + store + `/` + dailyStats?.year + `/` + dailyStats?.month  + `/` + dailyStats?.day, {dailyStatsPost}, {withCredentials: true})
            setTimeout(function() {
            window.location.reload()
            setIsLoading(false)
            }, 500);
            
            alert.success("Daily Stats Saved!")
        }catch(err){
            setIsLoading(false)
            console.log(err.response)
            alert.error("Failed!")
        }
    }

  return (
        <Container component={Box}>
                <Grid container style={{justifyContent: 'center'}}>
                    <Grid item>
                        <Header style={{marginTop: 0}}>Daily Statistics</Header>
                    </Grid>
                </Grid>
                {isLoading ?
                
                <Grid container style={{justifyContent: 'center'}}>
                    <Grid item>
                        <CircularProgress/>
                    </Grid>
                </Grid> : 

                !edit ?

                <Grid container spacing={5} style={{justifyContent: 'center'}}>
                    
                    <Grid item>
                    <TextField 
                        style={{width: 100, textAlign: 'center', justifyContect: 'center'}}
                        id='traffic'
                        type='text'
                        label='Traffic'
                        autoComplete='off'
                        defaultValue={dailyStats?.traffic}
                        variant= 'outlined'
                        disabled
                    />
                    </Grid>

                    <Grid item>
                    <TextField 
                        style={{width: 100, textAlign: 'center', justifyContect: 'center'}}
                        id='conversion'
                        type='text'
                        label='Conversion'
                        autoComplete='off'
                        defaultValue={dailyStats?.conversion}
                        variant= 'outlined'
                        disabled
                    />
                    </Grid>

                    <Grid item>
                    <TextField 
                        style={{width: 100, textAlign: 'center', justifyContect: 'center'}}
                        id='actionsandoffers'
                        type='text'
                        label='Action/Offers'
                        autoComplete='off'
                        defaultValue={dailyStats?.actionsandoffers}
                        variant= 'outlined'
                        disabled
                    />
                    </Grid>

                    <Grid item>
                    <TextField 
                        style={{width: 100, textAlign: 'center', justifyContect: 'center'}}
                        id='tvm'
                        type='text'
                        label='TVM'
                        autoComplete='off'
                        defaultValue={dailyStats?.tvm}
                        variant= 'outlined'
                        disabled
                    />
                    </Grid>

                    <Grid item>
                    <TextField 
                        style={{width: 100, textAlign: 'center', justifyContect: 'center'}}
                        id='epu'
                        type='text'
                        label='EPU'
                        autoComplete='off'
                        defaultValue={dailyStats?.epu}
                        variant= 'outlined'
                        disabled
                    />
                    </Grid>

                    <Grid item xs={10}>
                    <TextField 
                        style={{textAlign: 'center', justifyContect: 'center'}}
                        id='notes'
                        label='Daily Note'
                        autoComplete='off'
                        defaultValue={dailyStats?.notes}
                        multiline
                        rows={8}
                        maxRows={10}
                        variant= 'outlined'
                        disabled
                        fullWidth
                    />
                    </Grid>
                    
                    {Temporal.PlainDate.compare(currentDate, inputDate) === 0 && <Grid item xs={10} style={{textAlign: 'center', justifyContect: 'center'}}>
                        <Button style={{width: 300}} variant='contained' onClick={() => setEdit(true)}>Edit</Button>
                    </Grid>}

                </Grid> 
                
                : 
                
                <Grid container spacing={5} style={{justifyContent: 'center'}}>
                    
                    <Grid item>
                    <TextField 
                        style={{width: 100, textAlign: 'center', justifyContect: 'center'}}
                        id='traffic'
                        type='text'
                        label='Traffic'
                        autoComplete='off'
                        defaultValue={dailyStats?.traffic}
                        variant= 'outlined'
                    />
                    </Grid>

                    <Grid item>
                    <TextField 
                        style={{width: 100, textAlign: 'center', justifyContect: 'center'}}
                        id='conversion'
                        type='text'
                        label='Conversion'
                        autoComplete='off'
                        defaultValue={dailyStats?.conversion}
                        variant= 'outlined'
                    />
                    </Grid>

                    <Grid item>
                    <TextField 
                        style={{width: 100, textAlign: 'center', justifyContect: 'center'}}
                        id='actionsandoffers'
                        type='text'
                        label='Action/Offers'
                        autoComplete='off'
                        defaultValue={dailyStats?.actionsandoffers}
                        variant= 'outlined'
                    />
                    </Grid>

                    <Grid item>
                    <TextField 
                        style={{width: 100, textAlign: 'center', justifyContect: 'center'}}
                        id='tvm'
                        type='text'
                        label='TVM'
                        autoComplete='off'
                        defaultValue={dailyStats?.tvm}
                        variant= 'outlined'
                    />
                    </Grid>

                    <Grid item>
                    <TextField 
                        style={{width: 100, textAlign: 'center', justifyContect: 'center'}}
                        id='epu'
                        type='text'
                        label='EPU'
                        autoComplete='off'
                        defaultValue={dailyStats?.epu}
                        variant= 'outlined'
                    />
                    </Grid>

                    <Grid item xs={10}>
                    <TextField 
                        style={{textAlign: 'center', justifyContect: 'center'}}
                        id='notes'
                        label='Daily Note'
                        autoComplete='off'
                        defaultValue={dailyStats?.notes}
                        multiline
                        rows={8}
                        maxRows={10}
                        variant= 'outlined'
                        fullWidth
                    />
                    </Grid>

                    <Grid item xs={10} style={{textAlign: 'center', justifyContect: 'center'}}>
                        <Button style={{width: 300}} variant='contained' color='primary' onClick={() => postStats()}>Submit</Button>
                    </Grid>
                    
                    <Grid item xs={10} style={{textAlign: 'center', justifyContect: 'center'}}>
                        <Button style={{width: 150}} variant='contained' color='error' onClick={() => setEdit(false)}>Cancel</Button>
                    </Grid>

                </Grid>}

        </Container>
  )
}

export default DailyStats