import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import {positions, transitions, Provider as AlertProvider} from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'

const options = {
  position: positions.BOTTOM_CENTER,
  timeout: 2000,
  offset: '30px',
  transition: transitions.SCALE
}
//Added AlertProvider as a overlay so it can be seen on top of all elements.
ReactDOM.render(
    <AlertProvider template={AlertTemplate} {...options}>
    <App />
    </AlertProvider>,
  document.getElementById('root')
);

