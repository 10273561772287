import React, {useState} from 'react'
import Sidebar from '../../Components/Rep/Sidebar'
import Navbar from '../../Components/Rep/Navbar'
import HomePage from '../../Components/Rep/HomePage'

const Home = () => {

    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

  return (
    <>
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <Navbar toggle={toggle}/>

        <HomePage/>
        
    </>
  )
}

export default Home