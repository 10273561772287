import React, {useState} from 'react'
import Sidebar from '../../Components/Rep/Sidebar'
import Navbar from '../../Components/Rep/Navbar'
import DailySales from '../../Components/Rep/DailySalesPage'

const RepSales = () => {

    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

  return (
    <>
        <Sidebar isOpen={isOpen} toggle={toggle}/>
        <Navbar toggle={toggle}/>
        
        <DailySales />
    </>
  )
}

export default RepSales