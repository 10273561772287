import React from 'react'

import { Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

let uri = "https://cdn.dribbble.com/users/285475/screenshots/2083086/media/bbcfd1a1fecd97c1835792283a601f10.gif"
let uri2 = "https://www.wildlifeonline.me.uk/assets/ugc/images/albino_grey_squirrel03.jpg"
const Nuts = () => {
  
  const navigate = useNavigate()
  setTimeout(function() {
  navigate(`/${sessionStorage.getItem('store')}`)
}, 10000);

  return (
    <Container style={{justifyContent: 'center', alignContent: 'center'}}>
    <Typography variant="h1" component="h2" >Ahh Nuts! Nothing Found</Typography>
    <div>
    <img style={{justifyContent: 'center', alignContent: 'center', marginLeft: 300}} src={uri2} width={500} height={800}/>
    </div>

    <Typography variant="h4" component="h4" style={{color: 'white', marginLeft: 350, cursor: "default"}}>Enjoying Karam's Nuts?</Typography>
    </Container>
  )
}

export default Nuts