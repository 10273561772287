import React, { useEffect, useState}from 'react'
import {FaBars} from 'react-icons/fa'
import {Nav, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavItem, NavLinks, NavLinksBlack, NavBtn, NavBtnLink} from './NavbarElements'
import axios from 'axios'
import fido from '../../../Assets/FidoLogo.png'
import rogers from '../../../Assets/RogersLogo.png'
import none from '../../../Assets/NoLogo.png'
import {useLocation, useNavigate} from 'react-router-dom'
import {Temporal} from '@js-temporal/polyfill'
import { useAlert } from 'react-alert'
import { Grid } from '@mui/material'


const Navbar = ({toggle}) => {
    const alert = useAlert()
    const[logo, setLogo] = useState(none)
    const[name, setName] = useState()
    const[store, setStore] = useState()
    const navigate = useNavigate()
    let location = useLocation()
    const [employee, setEmployee] = useState(sessionStorage.getItem('employee'))
    const permission = sessionStorage.getItem('permission')


    

    let currentDate = Temporal.Now.plainDateISO()
    const[year, setYear] = useState(currentDate.year)
    const[month, setMonth] = useState(currentDate.month)
    const[day, setDay] = useState(currentDate.day)
    const[week, setWeek] = useState(currentDate.add({days:1}).weekOfYear)

    useEffect(() => {
        async function getUserInfo() {
            if(sessionStorage.getItem('brand') === 'Fido'){
                setLogo(fido)
            }else if(sessionStorage.getItem('brand') === 'Rogers'){
                setLogo(rogers)
            }
            setStore(sessionStorage.getItem('store'))
            setName(sessionStorage.getItem('name'))
        }

        getUserInfo()
    }, [location])

    async function logOut(){
        try{
        let {data} = await await axios.get(`/store/logout`, {withCredentials: true})
        sessionStorage.clear()
        alert.success(data.message)
        sessionStorage.clear()
        setTimeout(function() {navigate('/')}, 2000);
        }catch(err) {
            alert.error(err.response.data.message)
            sessionStorage.clear()
            setTimeout(function() {navigate('/')}, 2000);
        }
    }

  return (
    <>
        <Nav>
        <div style={{justifyContent: 'center', alignItems: 'center', marginTop: 7}}>
          <img src={logo} style={{width: 30, height: 30, borderRadius: 50, backgroundColor: "white", padding: 10}} alt="Logo"/>
        </div>
            <NavbarContainer>
            
                <NavLogo to={"/" + store}>{store}</NavLogo>
                <MobileIcon onClick={toggle}>
                    <FaBars />
                </MobileIcon>
                <NavMenu>
                    <Grid container spacing={3} style={{alignItems: 'center', marginLeft: 15, marginRight: 40}}>
                        <Grid item>
                        <NavItem>
                            <NavLinks to={"/" + store} onClick={() => window.location("/" + store).reload(false)}>New Sale</NavLinks>
                        </NavItem>
                        </Grid>
                        <Grid item>
                        <NavItem>
                            <NavLinks to={"/" + store + "/daily/" + year + "/" + month + "/" + day} onClick={() => window.location("/" + store + "/daily/" + year + "/" + month + "/" + day).reload(false)}>Daily</NavLinks>
                        </NavItem>
                        </Grid>
                        <Grid item>
                        <NavItem>
                            <NavLinks to={"/" + store + "/weekly/" + year + "/" + week} onClick={() => window.location("/" + store + "/weekly/" + year + "/" + week).reload(false)}>Weekly</NavLinks>
                        </NavItem>
                        </Grid>
                        <Grid item>
                        <NavItem>
                            <NavLinks to={"/" + store + "/monthly/" + year + "/" + month} onClick={() => window.location("/" + store + "/monthly/" + year + "/" + month).reload(false)}>Monthly</NavLinks>
                        </NavItem>
                        </Grid>
                        <Grid item>
                        <NavItem>
                            <NavLinks to={"/" + store + "/return"} onClick={() => window.location("/" + store + "/return").reload(false)}>Returns</NavLinks>
                        </NavItem>
                        </Grid>
                        <Grid item>
                        {(permission >= 3) && <NavItem>
                            <NavLinks to={"/" + store + "/management"} onClick={() => window.location("/" + store + "/management").reload(false)}>Management</NavLinks>
                        </NavItem>}
                        </Grid>
                    </Grid>
                </NavMenu>
                <NavBtn>
                    <NavLinksBlack to="/profile">{name}</NavLinksBlack>
                    <NavBtnLink to="/" onClick={() => logOut()}>Sign out</NavBtnLink>
                </NavBtn>
            </NavbarContainer>
        </Nav>
    </>
  )
}

export default Navbar
