import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { BtnLink, Container, Item, Sales, ToolItem, ItemContainer, ItemColor} from './DailySaleElements'
import { Tooltip } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';

function Sale({sale}) {

                    let show = true
                    let guest = parseInt(sale?.store.toString() + sale?.store.toString())
                    if(sale?.employeeID === guest && sale?.commission === 0){
                        show = false
                    }

  return (
    <>
               {show && <Container>
                   <Sales >
                        <ItemContainer><Item>{sale?.brand}</Item></ItemContainer>
                        <ItemContainer><Item>{sale?.store}</Item></ItemContainer>

                        <Tooltip placement="top" title={
                            <div style={{backgroundColor: "#393E46", borderRadius: 5, color: 'white', padding:10, margin:3}}>
                            <ToolItem>MTM NAC: {sale?.sale?.nacByod}</ToolItem>
                            <ToolItem>2YR NAC: {sale?.sale?.nacTerm}</ToolItem>
                            <ToolItem>BSC NAC: {sale?.sale?.nacBasic}</ToolItem>
                            <ToolItem>PREPAID: {sale?.sale?.nacPrepaid}</ToolItem>
                            </div>
                        }>
                        <ItemContainer><Item>{sale?.nacTotal} / {sale?.targets?.nacTotal ? sale?.targets?.nacTotal : 0}</Item><ItemColor style={{backgroundColor: `${(sale?.nacTotal >= sale?.targets?.nacTotal) ? 'green' : (sale?.nacTotal >= (parseInt(sale?.targets?.nacTotal)) / 2) ? 'Yellow' : '#EE4B2B'}`}}></ItemColor></ItemContainer>
                        
                        </Tooltip>

                        <Tooltip placement="top" title={
                            <div style={{backgroundColor: "#393E46", borderRadius: 5, color: 'white', padding:10, margin:3}}>
                            <ToolItem>HUP: {sale?.sale?.hup}</ToolItem>
                            <ToolItem>TVM: {sale?.sale?.tvm}</ToolItem>
                            <ToolItem>TVM%: {sale?.tvmAttach}%</ToolItem>
                            </div>
                        }>
                        <ItemContainer><Item>{sale?.hupTotal} / {sale?.targets?.hupTotal ? sale?.targets?.hupTotal : 0}</Item><ItemColor style={{backgroundColor: `${(sale?.hupTotal >= sale?.targets?.hupTotal) ? 'green' : (sale?.hupTotal >= (parseInt(sale?.targets?.hupTotal)) / 2) ? 'Yellow' : '#EE4B2B'}`}}></ItemColor></ItemContainer>
                        </Tooltip>

                        <Tooltip placement="top" title={
                            <div style={{backgroundColor: "#393E46", borderRadius: 5, color: 'white', padding:10, margin:3}}>
                            <ToolItem>MC: {sale?.sale?.mc}</ToolItem>
                            <ToolItem>MC ATTACH: {sale?.mcAttach}%</ToolItem>
                            </div>
                        }>
                        <ItemContainer><Item>{sale?.mcTotal} / {sale?.targets?.mcTotal ? sale?.targets?.mcTotal : 0}</Item><ItemColor style={{backgroundColor: `${(sale?.mcTotal >= sale?.targets?.mcTotal) ? 'green' : (sale?.mcTotal >= (parseInt(sale?.targets?.mcTotal)) / 2) ? 'Yellow' : '#EE4B2B'}`}}></ItemColor></ItemContainer>
                        </Tooltip>

                        <Tooltip placement="top" title={
                            <div style={{backgroundColor: "#393E46", borderRadius: 5, color: 'white', padding:10, margin:3}}>
                            <ToolItem>ACC: ${sale?.sale?.accessories}</ToolItem>
                            <ToolItem>ARS: ${sale?.ars}</ToolItem>
                            </div>
                        }>
                        <ItemContainer><Item>${sale?.sale?.accessories}/{sale?.targets?.accessoriesTotal ? sale?.targets?.accessoriesTotal : 0}</Item><ItemColor style={{backgroundColor: `${(sale?.sale?.accessories >= sale?.targets?.accessoriesTotal) ? 'green' : (sale?.sale?.accessories >= (parseInt(sale?.targets?.accessoriesTotal)) / 2) ? 'Yellow' : '#EE4B2B'}`}}></ItemColor></ItemContainer>
                        
                        </Tooltip>

                        <Tooltip placement="top" background='clear' title={
                            <div style={{backgroundColor: "#393E46", borderRadius: 5, color: 'white', padding:10, margin:3}}>
                            <ToolItem>DP: {sale?.sale?.dp} / {sale?.subsTerm}</ToolItem>
                            <ToolItem>DP%: {sale?.dpAttach}%</ToolItem>
                            </div>
                        }>
                       <ItemContainer><Item>{sale?.dpAttach}/{sale?.targets?.dpTotal ? sale?.targets?.dpTotal : 0}%</Item><ItemColor style={{backgroundColor: `${(sale?.dpAttach >= sale?.targets?.dpTotal) ? 'green' : (sale?.sale?.dp >= (parseInt(sale?.targets?.dpTotal)) / 2) ? 'Yellow' : '#EE4B2B'}`}}></ItemColor></ItemContainer>
                       
                        </Tooltip>
                        <Tooltip placement="top" title={
                            <div style={{backgroundColor: "#393E46", borderRadius: 5, color: 'white', padding:10, margin:3}}>
                             
                            <ToolItem>MBB: {sale?.sale?.mbb}</ToolItem>
                            <ToolItem>EXPRESS: {sale?.sale?.express}</ToolItem>
                            <ToolItem>VALUEPACK: {sale?.sale?.valuepack}</ToolItem>
                            <ToolItem>BPO USED: ${sale?.sale?.bpo}</ToolItem>
                            {(parseInt(sessionStorage.getItem('employee')) === sale?.employeeID) ? <ToolItem>EST EARNING: ${sale?.commission}</ToolItem> : null}
                            </div>
                        }>
                        <ItemContainer><Item>Extras</Item></ItemContainer>
                        </Tooltip>
                        
                        <Tooltip placement="top" title={
                            <div style={{backgroundColor: "#393E46", borderRadius: 5, color: 'white', padding:10, margin:3}}>
                            {sale?.date ? <ToolItem>TOTAL SUBS: {sale?.subsTotal}</ToolItem> : null}
                            <ToolItem>TOTAL SUBS: {sale?.subsTotal}</ToolItem>
                            <ToolItem>TERM SUBS: {sale?.subsTerm}</ToolItem>
                            </div>
                        }>
                         <ItemContainer><Item>{sale?.subsTotal}</Item></ItemContainer>
                         </Tooltip>
                    </Sales>
                </Container> }
    </>
  )
}

export default Sale