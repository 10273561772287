
export function TotalSales(sales) {
    let iaccessories = 0
    let ibpo = 0
    let idp = 0
    let iexpress = 0
    let ihup = 0
    let iinternet = 0
    let imbb = 0
    let imc = 0
    let inacBasic = 0
    let inacByod = 0
    let inacPrepaid = 0
    let inacTerm = 0
    let ismartHome = 0
    let itv = 0
    let itvm = 0
    let ivaluepack = 0
    let iwatch = 0


    let iaccessoriesTotal = 0
    let idpTotal = 0
    let ihupTotal = 0
    let imcTotal = 0
    let inacTotal = 0

    let hupTotal = 0
    let mbbTotal = 0
    let mcTotal = 0
    let nacTotal = 0
    let subsTerm = 0
    let subsTotal = 0

    sales.map((sale) => {
        iaccessories += sale.sale.accessories
        ibpo += sale.sale.bpo
        idp += sale.sale.dp
        iexpress += sale.sale.express
        ihup += sale.sale.hup
        iinternet += sale.sale.internet
        imbb += sale.sale.mbb
        imc += sale.sale.mc
        inacBasic += sale.sale.nacBasic
        inacByod += sale.sale.nacByod
        inacPrepaid += sale.sale.nacPrepaid
        inacTerm += sale.sale.nacTerm
        ismartHome += sale.sale.smartHome
        itv += sale.sale.tv
        itvm += sale.sale.tvm
        ivaluepack += sale.sale.valuepack
        iwatch += sale.sale.watch


        
        iaccessoriesTotal += sale.targets.accessoriesTotal
        idpTotal += sale.targets.dpTotal
        ihupTotal += sale.targets.hupTotal
        imcTotal += sale.targets.mcTotal
        inacTotal += sale.targets.nacTotal
    });

    hupTotal = (ihup + itvm) || 0
    mbbTotal = (imbb) || 0
    mcTotal = (imc) || 0
    nacTotal = (inacBasic + inacByod + inacPrepaid + inacTerm) || 0
    subsTerm = ((ihup + itvm) + inacTerm) || 0
    subsTotal = ((ihup + itvm) + (inacBasic + inacByod + inacPrepaid + inacTerm) + imc) || 0

    
    let item = subsTerm
    if (item === 0){
        item = 1
    }
    let dpAttach = ((idp / item) * 100).toFixed(2) || 0
    let tvmAttach = ((itvm / hupTotal) * 100).toFixed(2) || 0
    let mbbAttach = ((imbb / subsTotal) * 100).toFixed(2) || 0
    let mcAttach = ((imc / subsTotal) * 100).toFixed(2) || 0
    let ars = (iaccessories / subsTotal).toFixed(2) || 0


    let newSale = {
        date: sales[0].date,
        day: sales[0].day,
        week: sales[0].week,
        month: sales[0].month,
        year: sales[0].year,

        sale: {
            accessories: iaccessories,
            bpo: ibpo,
            dp: idp,
            express: iexpress,
            hup: ihup,
            internet: iinternet,
            mbb: imbb,
            mc: imc,
            nacBasic: inacBasic,
            nacByod: inacByod,
            nacPrepaid: inacPrepaid,
            nacTerm: inacTerm,
            smartHome: ismartHome,
            tv: itv,
            tvm: itvm,
            valuepack: ivaluepack,
            watch: iwatch,
        },
        targets: {
            accessoriesTotal: iaccessoriesTotal,
            dpTotal: (idpTotal / sales.length),
            hupTotal: ihupTotal,
            mcTotal: imcTotal,
            nacTotal: inacTotal,
        },
        
        hupTotal: hupTotal,
        mbbTotal: mbbTotal,
        mcTotal: mcTotal,
        nacTotal: nacTotal,
        subsTerm: subsTerm,
        subsTotal: subsTotal,

        dpAttach: dpAttach,
        tvmAttach: tvmAttach,
        mbbAttach: mbbAttach,
        mcAttach: mcAttach,
        ars: ars,
    }
  return newSale
}

