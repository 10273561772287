import React, { useState, useEffect } from "react";
import axios from "axios";
import { SalesDiv, Container, Header } from "./ManagementPageElements";
import { useLocation, useNavigate } from "react-router-dom";
import { Temporal } from "@js-temporal/polyfill";
import Register from "../Management/Register";
import Retire from "../Management/Retire";

import Remove from "../Management/Remove";

import TargetsMonthly from "../Management/TargetsMonthly";
import TargetsWeekly from "../Management/TargetsWeekly";

import StoreInfo from "../Management/StoreInfo";

import Alerts from "../Management/Alerts";

import { Button, CircularProgress, Grid } from "@mui/material";

import { TextField, Input, MenuItem } from "@material-ui/core";
import { useAlert } from "react-alert";
import DailySale from "../DailySale/index";
import DailySalesTotal from "../DailySalesTotal/index";
import { TotalSales } from "../../Common/TotalSales";

function Management() {
  const alert = useAlert();
  let currentDate = Temporal.Now.plainDateISO();
  const [year, setYear] = useState(currentDate.year);
  const [month, setMonth] = useState(currentDate.month);
  const [week, setWeek] = useState(currentDate.add({ days: 1 }).weekOfYear);
  const [day, setDay] = useState(currentDate.day);

  const [isLoading, setIsLoading] = useState(false);

  const [active, setActive] = useState(null);
  const [inactive, setInactive] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [employeesWeekly, setEmployeesWeekly] = useState([]);
  const [storeTargetsWeekly, setStoreTargetsWeekly] = useState(null);
  const [storeTargets, setStoreTargets] = useState(null);
  const [memo, setMemo] = useState();

  const [reps, setReps] = useState([]);

  //user info

  const store = sessionStorage.getItem("store");
  let location = useLocation();

  const [users, setUsers] = useState(false);
  const [targets, setTargets] = useState(false);
  const [storeInfo, setStoreInfo] = useState(false);
  const [alerts, setAlerts] = useState(false);

  const [target, setTarget] = useState();
  const [sales, setSales] = useState([]);
  const [totalSales, setTotalSales] = useState();

  const navigate = useNavigate();

  function clickedUsers() {
    setTargets(false);
    setUsers(true);
    setStoreInfo(false);
    setAlerts(false);
  }

  function clickedTargets() {
    setUsers(false);
    setTargets(true);
    setStoreInfo(false);
    setAlerts(false);
  }
  function clickedStore() {
    setUsers(false);
    setTargets(false);
    setStoreInfo(true);
    setAlerts(false);
  }

  function clickedAlerts() {
    setUsers(false);
    setTargets(false);
    setStoreInfo(false);
    setAlerts(true);
  }

  useEffect(() => {
    setIsLoading(true);
    async function FetchData() {
      try {
        const getActive = await axios.put(
          `/store/active`,
          { store },
          { withCredentials: true }
        );

        const getInactive = await axios.put(
          `/store/inactive`,
          { store },
          { withCredentials: true }
        );

        const getEmployees = await axios.get(
          `/sales/monthly/targets/` + store + `/` + year + `/` + month,
          { withCredentials: true }
        );

        const getStoreWeekly = await axios.get(
          `/sales/store/weekly/` + store + `/` + year + `/` + week,
          { withCredentials: true }
        );

        const getEmployeesWeekly = await axios.get(
          `/sales/weekly/targets/` + store + `/` + year + `/` + week,
          { withCredentials: true }
        );

        const getStore = await axios.get(
          `/sales/store/monthly/` + store + `/` + year + `/` + month,
          { withCredentials: true }
        );

        const getMemo = await axios.get(`/store/alert/get/` + store, {
          withCredentials: true,
        });

        setActive(getActive?.data?.users);

        setEmployees(getEmployees?.data?.monthly);

        setStoreTargets(getStore?.data?.monthly);

        setEmployeesWeekly(getEmployeesWeekly?.data?.weekly);

        setStoreTargetsWeekly(getStoreWeekly?.data?.weekly);

        setInactive(getInactive?.data?.users);

        if (getMemo.data.success == false) {
          setMemo({
            store: "",
            title: "",
            date: "",
            urgency: "",
            memo: "",
            updatedby: "",
          });
        } else {
          setMemo(getMemo.data.alert);
        }

        setIsLoading(false);
      } catch (err) {
        alert.error(err.response);
        console.log(err);
      }
    }

    FetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  async function getSales() {
    try {
      setIsLoading(true);
      let startDate = Temporal.PlainDate.from(
        document.getElementById("DATE1").value
      );
      let endDate = Temporal.PlainDate.from(
        document.getElementById("DATE2").value
      );
      const getUserDailySales = await axios.get(
        `/sales/rep/custom/${store}/${target}/${startDate.toString()}/${endDate.toString()}`,
        { withCredentials: true }
      );
      setSales(getUserDailySales.data.sales);
      let arr = [];
      sales.map((sale) => {
        arr.push(sale?.sale[0]);
      });
      let total = await TotalSales(arr);
      setTotalSales(total);
    } catch (e) {
      console.log(e);
    }

    setIsLoading(false);
  }

  return (
    <>
      <Container>
        <Header>Management</Header>
      </Container>

      {isLoading ? (
        <SalesDiv style={{ justifyContent: "center" }}>
          <CircularProgress />
        </SalesDiv>
      ) : (
        <Grid
          container
          spacing={8}
          style={{ justifyContent: "center", marginTop: 50, marginBottom: 50 }}
        >
          <Grid item xs={"auto"} style={{ justifyContent: "center" }}>
            <Button
              style={{
                width: 225,
                backgroundClip: "blue",
                borderRadius: 15,
                height: 50,
              }}
              variant="contained"
              color={users ? "success" : "primary"}
              onClick={() => clickedUsers()}
            >
              Users
            </Button>
          </Grid>
          <Grid item xs={"auto"} style={{ justifyContent: "center" }}>
            <Button
              style={{
                width: 225,
                backgroundClip: "blue",
                borderRadius: 15,
                height: 50,
              }}
              variant="contained"
              color={storeInfo ? "success" : "primary"}
              onClick={() => clickedStore()}
            >
              Store
            </Button>
          </Grid>
          <Grid item xs={"auto"} style={{ justifyContent: "center" }}>
            <Button
              style={{
                width: 225,
                backgroundClip: "blue",
                borderRadius: 15,
                height: 50,
              }}
              variant="contained"
              color={targets ? "success" : "primary"}
              onClick={() => clickedTargets()}
            >
              Targets
            </Button>
          </Grid>
          <Grid item xs={"auto"} style={{ justifyContent: "center" }}>
            <Button
              style={{
                width: 225,
                backgroundClip: "blue",
                borderRadius: 15,
                height: 50,
              }}
              variant="contained"
              color={alerts ? "success" : "primary"}
              onClick={() => clickedAlerts()}
            >
              Alerts
            </Button>
          </Grid>
        </Grid>
      )}

      {users && (
        <Container style={{ marginTop: 25 }}>
          {isLoading ? (
            <SalesDiv style={{ justifyContent: "center" }}>
              <CircularProgress />
            </SalesDiv>
          ) : (
            <Grid
              container
              style={{
                justifyContent: "center",
                marginTop: 50,
                marginBottom: 150,
              }}
            >
              <Grid item xs={5}>
                <Register />
              </Grid>
              <Grid item xs={"auto"} direction={"row"}>
                {active?.length > 0 ? (
                  <Grid item xs={"auto"}>
                    <Retire employees={active} />
                  </Grid>
                ) : null}
                {inactive?.length > 0 && inactive[0]?.isActive === false ? (
                  <Grid item xs={"auto"}>
                    <Remove employees={inactive} />
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          )}
        </Container>
      )}

      {targets && (
        <Grid
          container
          xs={"auto"}
          style={{ marginTop: 25, justifyContent: "center" }}
        >
          <TargetsWeekly
            employeesData={employeesWeekly}
            storeTargetsData={storeTargetsWeekly}
          />
          <br />
          <TargetsMonthly
            employeesData={employees}
            storeTargetsData={storeTargets}
          />
        </Grid>
      )}

      {storeInfo && (
        <Grid conatiner style={{ justifyContent: "center" }}>
          <StoreInfo />
        </Grid>
      )}

      {alerts && (
        <Grid conatiner style={{ justifyContent: "center" }}>
          <Alerts memo={memo} />
        </Grid>
      )}

      <>
        <Header
          style={{
            justifyContent: "center",
            justifyItems: "center",
            marginTop: 0,
          }}
        >
          <div>Start:</div>
          <Input id="DATE1" type="date" defaultValue={`${currentDate}`}></Input>
        </Header>
        <Header
          style={{
            justifyContent: "center",
            justifyItems: "center",
            marginTop: 0,
          }}
        >
          <div>End:</div>
          <Input
            id="DATE2"
            type="date"
            defaultValue={`${currentDate.add({ days: 1 })}`}
          ></Input>
        </Header>
        <Header
          style={{
            justifyContent: "center",
            justifyItems: "center",
            marginTop: 0,
          }}
        >
          <TextField
            style={{ margin: 10, width: 225 }}
            id="employee"
            label="Employee"
            variant="outlined"
            select
            onChange={(e) => setTarget(e.target.value)}
          >
            {active?.map((employee) => (
              <MenuItem key={employee?.employeeID} value={employee?.employeeID}>
                {employee?.firstName}
              </MenuItem>
            ))}
          </TextField>
        </Header>
        <Header style={{ marginTop: 50 }}>
          <Button
            style={{
              justifyContent: "center",
              backgroundColor: "#1E2022",
              borderRadius: 20,
              textAlign: "ceneter",
              fontSize: 25,
              color: "white",
              padding: 15,
              fontWeight: "initial",
              height: 50,
              width: 225,
            }}
            onClick={() => getSales()}
          >
            Submit
          </Button>
        </Header>
        {!isLoading && sales
          ? sales.map((sale) => (
              <DailySale sale={sale?.sale[0]} type="custom" />
            ))
          : null}

        {!isLoading && totalSales ?
              <DailySalesTotal sale={totalSales} type="daily" />
          : null}
      </>
    </>
  );
}

export default Management;
