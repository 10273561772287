import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  BtnLink,
  Container,
  Item,
  ItemColor,
  ItemContainer,
  Sales,
  ToolItem,
} from "./DailySalesTotalElements";
import { Tooltip } from "@mui/material";
import { useLocation } from "react-router-dom";

function Sale({ sale, type }) {
  let stringMonth = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ][sale?.month - 1];

  const [Target, setTarget] = useState();
  const token = sessionStorage.getItem("accessToken");
  let location = useLocation();

  let acc = sale?.sale?.accessories;
  if (acc != null) {
    acc = acc.toFixed(2);
  }

  useEffect(() => {
    async function FetchData() {
      // let targetdata = await axios.get(`/target/store/` + sale?.store?.store + `/` + sale?.date?.year + `/` + sale?.date?.month, {headers:{authorization: 'Bearer ' + token}});
      // setTarget(targetdata.data)
    }
    FetchData();
  }, [location]);
  return (
    <Container style={{ marginBottom: 150 }}>
      <Sales>
        <ItemContainer>
          <Item>
            {type == "daily"
              ? `${sale?.date}`
              : type == "weekly"
              ? `Week: ${sale?.week}`
              : `${sale?.quarter} ${stringMonth}`}
          </Item>
        </ItemContainer>

        <Tooltip
          placement="top"
          title={
            <div
              style={{
                backgroundColor: "#393E46",
                borderRadius: 5,
                color: "white",
                padding: 10,
                margin: 3,
              }}
            >
              <ToolItem>MTM NAC: {sale?.sale?.nacByod}</ToolItem>
              <ToolItem>2YR NAC: {sale?.sale?.nacTerm}</ToolItem>
              <ToolItem>BSC NAC: {sale?.sale?.nacBasic}</ToolItem>
              <ToolItem>PREPAID: {sale?.sale?.nacPrepaid}</ToolItem>
            </div>
          }
        >
          <ItemContainer>
            <Item>
              {sale?.nacTotal - sale?.sale?.nacPrepaid} /{" "}
              {sale?.targets?.nacTotal ? sale?.targets?.nacTotal : 0}
            </Item>
            <ItemColor
              style={{
                backgroundColor: `${
                  sale?.nacTotal - sale?.sale?.nacPrepaid >=
                  sale?.targets?.nacTotal
                    ? "green"
                    : sale?.nacTotal - sale?.sale?.nacPrepaid >=
                      parseInt(sale?.targets?.nacTotal) / 2
                    ? "Yellow"
                    : "#FF4433"
                }`,
              }}
            ></ItemColor>
          </ItemContainer>
        </Tooltip>

        <Tooltip
          placement="top"
          title={
            <div
              style={{
                backgroundColor: "#393E46",
                borderRadius: 5,
                color: "white",
                padding: 10,
                margin: 3,
              }}
            >
              <ToolItem>HUP: {sale?.sale?.hup}</ToolItem>
              <ToolItem>TVM: {sale?.sale?.tvm}</ToolItem>
              <ToolItem>TVM%: {sale?.tvmAttach}%</ToolItem>
            </div>
          }
        >
          <ItemContainer>
            <Item>
              {sale?.hupTotal} /{" "}
              {sale?.targets?.hupTotal ? sale?.targets?.hupTotal : 0}
            </Item>
            <ItemColor
              style={{
                backgroundColor: `${
                  sale?.hupTotal >= sale?.targets?.hupTotal
                    ? "green"
                    : sale?.hupTotal >= parseInt(sale?.targets?.hupTotal) / 2
                    ? "Yellow"
                    : "#FF4433"
                }`,
              }}
            ></ItemColor>
          </ItemContainer>
        </Tooltip>

        <Tooltip
          placement="top"
          title={
            <div
              style={{
                backgroundColor: "#393E46",
                borderRadius: 5,
                color: "white",
                padding: 10,
                margin: 3,
              }}
            >
              <ToolItem>MC: {sale?.sale?.mc}</ToolItem>
              <ToolItem>MC ATTACH: {sale?.mcAttach}%</ToolItem>
            </div>
          }
        >
          <ItemContainer>
            <Item>
              {sale?.mcTotal} /{" "}
              {sale?.targets?.mcTotal ? sale?.targets?.mcTotal : 0}
            </Item>
            <ItemColor
              style={{
                backgroundColor: `${
                  sale?.mcTotal >= sale?.targets?.mcTotal
                    ? "green"
                    : sale?.mcTotal >= parseInt(sale?.targets?.mcTotal) / 2
                    ? "Yellow"
                    : "#FF4433"
                }`,
              }}
            ></ItemColor>
          </ItemContainer>
        </Tooltip>

        <Tooltip
          placement="top"
          title={
            <div
              style={{
                backgroundColor: "#393E46",
                borderRadius: 5,
                color: "white",
                padding: 10,
                margin: 3,
              }}
            >
              <ToolItem>ACC: ${acc}</ToolItem>
              <ToolItem>ARS: ${sale?.ars}</ToolItem>
            </div>
          }
        >
          <ItemContainer>
            <Item>
              ${acc}/
              {sale?.targets?.accessoriesTotal
                ? sale?.targets?.accessoriesTotal
                : 0}
            </Item>
            <ItemColor
              style={{
                backgroundColor: `${
                  sale?.sale?.accessories >= sale?.targets?.accessoriesTotal
                    ? "green"
                    : sale?.sale?.accessories >=
                      parseInt(sale?.targets?.accessoriesTotal) / 2
                    ? "Yellow"
                    : "#FF4433"
                }`,
              }}
            ></ItemColor>
          </ItemContainer>
        </Tooltip>

        <Tooltip
          placement="top"
          title={
            <div
              style={{
                backgroundColor: "#393E46",
                borderRadius: 5,
                color: "white",
                padding: 10,
                margin: 3,
              }}
            >
              <ToolItem>
                DP: {sale?.sale?.dp} / {sale?.subsTerm}
              </ToolItem>
              <ToolItem>DP%: {sale?.dpAttach}%</ToolItem>
            </div>
          }
        >
          <ItemContainer>
            <Item>
              {sale?.dpAttach}/
              {sale?.targets?.dpTotal ? sale?.targets?.dpTotal : 0}%
            </Item>
            <ItemColor
              style={{
                backgroundColor: `${
                  sale?.dpAttach >= sale?.targets?.dpTotal
                    ? "green"
                    : sale?.sale?.dp >= parseInt(sale?.targets?.dpTotal) / 2
                    ? "Yellow"
                    : "#FF4433"
                }`,
              }}
            ></ItemColor>
          </ItemContainer>
        </Tooltip>
        <Tooltip
          placement="top"
          title={
            <div
              style={{
                backgroundColor: "#393E46",
                borderRadius: 5,
                color: "white",
                padding: 10,
                margin: 3,
              }}
            >
              <ToolItem>TV: {sale?.sale?.tv}</ToolItem>
              <ToolItem>Internet: {sale?.sale?.internet}</ToolItem>
              <ToolItem>Home Phone: {sale?.sale?.smartHome}</ToolItem>
              <ToolItem>WHI: {sale?.sale?.watch}</ToolItem>
            </div>
          }
        >
          <ItemContainer>
            <Item>
              {(sale?.sale?.tv + sale?.sale?.internet + sale?.sale?.smartHome + sale?.sale?.watch) |
                0}
            </Item>
            <ItemColor
              style={{
                backgroundColor: `${
                  sale?.sale?.tv +
                    sale?.sale?.internet +
                    sale?.sale?.smartHome + sale?.sale?.watch>
                  0
                    ? "green"
                    : "#EE4B2B"
                }`,
              }}
            ></ItemColor>
          </ItemContainer>
        </Tooltip>
        <Tooltip
          placement="top"
          title={
            <div
              style={{
                backgroundColor: "#393E46",
                borderRadius: 5,
                color: "white",
                padding: 10,
                margin: 3,
              }}
            >
              {sale?.date ? (
                <ToolItem>TOTAL SUBS: {sale?.subsTotal + sale?.sale?.watch - sale?.mcTotal}</ToolItem>
              ) : null}
              <ToolItem>EXPRESS: {sale?.sale?.express}</ToolItem>
              <ToolItem>VALUEPACK: {sale?.sale?.valuepack}</ToolItem>
              <ToolItem>
                ARPU: $
                {(sale?.sale?.arpu / (sale?.subsTotal - sale?.mcTotal)).toFixed(
                  2
                )}
              </ToolItem>
              <ToolItem>BPO USED: ${sale?.sale?.bpo}</ToolItem>
              {/* {parseInt(sessionStorage.getItem("employee")) ===
              sale?.employeeID ? (
                <ToolItem>EST EARNING: ${sale?.commission}</ToolItem>
              ) : null} */}
            </div>
          }
        >
          <ItemContainer>
            <Item>Extras</Item>
          </ItemContainer>
        </Tooltip>
        <Tooltip
          placement="top"
          title={
            <div
              style={{
                backgroundColor: "#393E46",
                borderRadius: 5,
                color: "white",
                padding: 10,
                margin: 3,
              }}
            >
              <ToolItem>TOTAL SUBS: {sale?.subsTotal + sale?.sale?.watch - sale?.mcTotal}</ToolItem>
              <ToolItem>TERM SUBS: {sale?.subsTerm}</ToolItem>
            </div>
          }
        >
          <ItemContainer>
            <Item>
              {type == "weekly" && "Subs: "}
              {sale?.subsTotal + sale?.sale?.watch - sale?.mcTotal}
            </Item>
          </ItemContainer>
        </Tooltip>
      </Sales>
    </Container>
  );
}

export default Sale;
