import {Container, MenuItem, TextField, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { Form } from '../../Rep/Management/ManagementElements'
import {Button, CircularProgress, Grid} from '@mui/material';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { useAlert } from 'react-alert';

function Register() {

    const navigate = useNavigate()

    const alert = useAlert()

    const [color, setColor] = useState('primary')
    const [isLoading, setIsLoading] = useState(false)
    

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [employeeID, setEmployeeID] = useState()
    const [password, setPassword] = useState('')
    const [password2, setPassword2] = useState('')
    const [role, setRole] = useState('Admin')
    const [region, setRegion] = useState()
    const [area, setArea] = useState()
    const [store, setStore] = useState()



    const roles = [
        {
            value: 'Associate',
            label: 'Associate'
        },
        {
            value: 'MOD',
            label: 'MOD'
        },
        {
            value: 'Manager',
            label: 'Manager'
        },
        {
            value: 'AreaManager',
            label: 'Area Manager'
        },
        {
            value: 'RegionalManager',
            label: 'Regional Manager'
        },
        {
            value: 'National',
            label: 'National'
        },
        {
            value: 'Admin',
            label: 'Admin'
        }
    ]

    const regions = [
        {
            value: 'West',
            label: 'Western'
        },
        {
            value: 'Central',
            label: 'Central'
        },
        {
            value: 'East',
            label: 'Eastern'
        }
    ]

    



    const  handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        if(password === password2){

            let person = {
                firstName: firstName,
                lastName: lastName,
                email: email,
                employeeID: employeeID,
                password: password,
                role: role,
                region: region,
                area: area,
                store: store
            }
            console.log(person)

            try{
                const {data} = await axios.put(`/admin/register`, {person}, {withCredentials: true}) 
                console.log(data)
                alert.success('User Created!')
                setTimeout(function() {
                    window.location.reload()
                }, 2000);
                
            }catch(err){
                alert.error('Error: ' + err.response.data.message,  {timeout: 4000})
            }
        }else{
            console.log('Password dont match')
        }
            setIsLoading(false)
    }

  return (
    <Grid container style={{ marginBottom: 25, justifyContent: 'center'}}>
        <Grid container xs={12} style={{justifyContent: 'center'}}>
            <Typography variant='h4'>Register User</Typography>
        </Grid>
    
    <Form autoComplete='false' style={{justifyContent: 'center', borderStyle: 'solid', width: 550, borderColor:"#9e9e9e", borderRadius: 10, borderWidth: 1, marginTop: 25}} onSubmit={handleSubmit}> 
        
        { (isLoading) ? <div style={{justifyContent: 'center', marginTop: 15}}><CircularProgress /></div> : (!isLoading && color !== 'primary') ? <Button style={{margin: 10, width: 150}} variant="contained" onClick={() => (window.location.reload())} color={color}>{color}</Button> : (!isLoading) ? <>
        <Grid container style={{justifyContent: 'center'}}>
            <Grid item xs={'auto'}>
                <TextField
                style={{margin: 10}}
                label='First Name'
                variant='outlined'
                type={'text'}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
                autoComplete='false'
                />
            </Grid>
            <Grid item xs={'auto'}>
                <TextField
                style={{margin: 10}}
                label='Last Name'
                variant='outlined'
                type={'text'}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
                autoComplete='false'
                />
            </Grid>
            <Grid item xs={'auto'}>
                <TextField
                style={{margin: 10}}
                label='Email'
                variant='outlined'
                type={'email'}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                autoComplete='false'
                />
            </Grid>
            <Grid item xs={'auto'}>
                <TextField 
                style={{margin: 10}}
                label='Employee Number'
                variant='outlined'
                type={'tel'}
                value={employeeID}
                onChange={(e) => setEmployeeID(e.target.value)}
                required
                autoComplete='false'
                />
            </Grid>
            <Grid item xs={'auto'}>
                <TextField 
                style={{margin: 10}}
                label='Password'
                variant='outlined'
                type={'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                autoComplete='false'
                />
            </Grid>
            <Grid item xs={'auto'}>
                <TextField 
                style={{margin: 10}}
                label='Confirm Password'
                variant='outlined'
                type={'password'}
                value={password2}
                onChange={(e) => setPassword2(e.target.value)}
                required
                autoComplete='false'
                />
            </Grid>
            <Grid item xs={'auto'}>
                <TextField 
                style={{margin: 10, width: 225}}
                label='Employee Role'
                variant='outlined'
                select
                value={role}
                required
                onChange={(e) => (setRole(e.target.value))}
                >
                {roles.map((option) => (
                    <MenuItem disableRipple key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
                </TextField>
            </Grid>
            { (role === 'Admin' || role === 'National') ? null :
            <Grid item xs={'auto'}>
                <TextField 
                style={{margin: 10, width: 225}}
                label='Region'
                variant='outlined'
                select
                value={region}
                disabled={(role === 'Admin' || role === 'National') ? true : false}
                required={(role === 'Admin' || role === 'National') ? false : true}
                onChange={(e) => (setRegion(e.target.value))}
                >
                {regions.map((option) => (
                    <MenuItem disableRipple key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
                </TextField>
            </Grid>}
            
            { (role === 'Admin' || role === 'National' || role === 'RegionalManager') ? null :
            <Grid item xs={'auto'}>
                <TextField 
                style={{margin: 10}}
                label='Area'
                variant='outlined'
                type={'tel'}
                value={area}
                onChange={(e) => (setArea(e.target.value))}
                disabled={(role === 'Admin' || role === 'National' || role === 'RegionalManager') ? true : false}
                required={(role === 'Admin' || role === 'National' || role === 'RegionalManager') ? false : true}
                autoComplete='false'
                />
            </Grid>}
            { (role === 'Admin' || role === 'National' || role === 'RegionalManager' || role === 'AreaManager') ? null :
            <Grid item xs={'auto'}>
                <TextField 
                style={{margin: 10}}
                label='Store'
                variant='outlined'
                type={'tel'}
                value={store}
                onChange={(e) => (setStore(e.target.value))}
                disabled={(role === 'Admin' || role === 'National' || role === 'RegionalManager' || role === 'AreaManager') ? true : false}
                required={(role === 'Admin' || role === 'National' || role === 'RegionalManager' || role === 'AreaManager') ? false : true}
                autoComplete='false'
                />
            </Grid>}
        </Grid>
        <Button style={{margin: 10, backgroundColor: 'blue'}} variant="contained" type='submit' color={color}>Submit</Button>
        </> : null}
    </Form>
    </Grid>
  )
}

export default Register