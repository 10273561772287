import React, { useEffect, useState } from "react";
import axios from "axios";
import { Temporal } from "@js-temporal/polyfill";
import { Header, Error } from "../HomePage/HomePageElements";
import { useForm } from "react-hook-form";
import {
  TextField,
  IconButton,
  Container,
  Slider,
  Box,
  MenuItem,
  CircularProgress,
  Input,
} from "@material-ui/core";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { Button, Grid, Tooltip, Typography } from "@mui/material";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";

export const FormItems = ({ amount, id, desc, setMax }) => {
  const [item, setItem] = useState(parseInt(amount));
  const [name, setName] = useState(id);
  const [bg, setBg] = useState("#C9D6DF");

  useEffect(() => {
    if (item > 0) {
      setBg("#FFF");
    } else {
      setBg("#C9D6DF");
    }
  }, [item]);

  return (
    <>
      <div
        style={{
          padding: 25,
          margin: 25,
          backgroundColor: `${bg}`,
          borderRadius: 15,
        }}
      >
        <div style={{ textAlign: "center", justifyContent: "center" }}>
          <Tooltip placement="top" title={<div>{desc}</div>}>
            <label
              style={{ fontSize: 20, justifyContent: "center" }}
              htmlFor={name}
            >
              {name}
            </label>
          </Tooltip>
        </div>

        <br />

        <div style={{ textAlign: "center", justifyContent: "center" }}>
          <IconButton
            onClick={() => {
              if (item !== 0) {
                setItem(item - 1);
              }
            }}
          >
            <RemoveIcon />
          </IconButton>

          <TextField
            style={{ width: 45, textAlign: "center", justifyContect: "center" }}
            id={name}
            type="text"
            variant="outlined"
            value={item}
            onChange={null}
          />

          <IconButton
            onClick={() => {
              if (item < setMax) {
                setItem(item + 1);
              }
            }}
          >
            <AddIcon />
          </IconButton>
        </div>
      </div>
    </>
  );
};

export const FormSlider = ({ amount, id, desc, setMax }) => {
  const alert = useAlert();
  const [item, setItem] = useState(parseInt(amount));
  const [name, setName] = useState(id);
  const [bg, setBg] = useState("#C9D6DF");

  function changedValue(e) {
    if (!isNaN(e.target.value)) {
      if (e.target.value < setMax + 1) {
        setItem(e.target.value);
      } else if (e.target.value > setMax) {
        alert.error(`${name} cannot excced ${setMax}!`);
        e.target.value = setMax;
        setItem(e.target.value);
      }
    } else {
      alert.error(`${name} must be a number!`);
      setItem(0);
      e.target.value = 0;
    }
  }

  useEffect(() => {
    if (item > 0) {
      setBg("#FFF");
    } else {
      setBg("#C9D6DF");
    }
  }, [item]);

  return (
    <>
      <div
        style={{
          padding: 25,
          margin: 25,
          backgroundColor: `${bg}`,
          borderRadius: 15,
          width: 140,
        }}
      >
        <div style={{ textAlign: "center", justifyContent: "center" }}>
          <Tooltip placement="top" title={<div>{desc}</div>}>
            <label
              style={{ fontSize: 20, justifyContent: "center" }}
              htmlFor={name}
            >
              {name}
            </label>
          </Tooltip>
        </div>

        <br />

        <div style={{ textAlign: "center", justifyContent: "center" }}>
          <TextField
            style={{
              width: 140,
              textAlign: "center",
              justifyContect: "center",
            }}
            id={name}
            type="tel"
            autoComplete="off"
            variant="outlined"
            defaultValue={item}
            onChange={(e) => changedValue(e)}
          />

          {/* <Slider 
    max={max}
    min={0}
    defaultValue={item}
    onChange={() => changedValue()}
    valueLabelDisplay='auto'
    /> */}
        </div>
      </div>
    </>
  );
};

let SaleDetails = {
  nacByod: 0,
  nacTerm: 0,
  tvm: 0,
  hup: 0,
  mbb: 0,
  nacBasic: 0,
  mc: 0,
  express: 0,
  dp: 0,
  nacPrepaid: 0,
  accessories: 0,
  valuepack: 0,
  bpo: 0,
  watch: 0,
  internet: 0,
  tv: 0,
  smartHome: 0,
  arpu: 0,
  employeeID: null,
  store: null,
  year: null,
  month: null,
  day: null,
  week: null,
  action: null,
};

function ReturnForm({ Sale }) {
  const alert = useAlert();
  let currentDate = Temporal.Now.plainDateISO();
  const [dateString, setDatestring] = useState(currentDate.toString());
  const [isLoading, setIsLoading] = useState(false);
  const [oldSale, setOldSale] = useState(Sale);
  let brand = sessionStorage.getItem("brand");

  async function post() {
    setIsLoading(true);
    try {
      SaleDetails.nacByod = parseFloat(document.getElementById("NAC").value);
      SaleDetails.nacTerm = parseFloat(document.getElementById("TNAC").value);
      SaleDetails.mbb = parseFloat(document.getElementById("RENEW").value);
      SaleDetails.hup = parseFloat(document.getElementById("HUP").value);
      SaleDetails.arpu = parseFloat(document.getElementById("ARPU").value);
      SaleDetails.express = parseFloat(
        document.getElementById("EXPRESS").value
      );
      SaleDetails.nacPrepaid = parseFloat(
        document.getElementById("PREPAID").value
      );
      SaleDetails.dp = parseFloat(document.getElementById("DP").value);
      SaleDetails.valuepack = parseFloat(
        document.getElementById("VALUEPACK").value
      );
      SaleDetails.watch = parseFloat(document.getElementById("WHI").value);

      SaleDetails.internet = parseFloat(
        document.getElementById("INTERNET").value
      );
      SaleDetails.tv = parseFloat(document.getElementById("TV").value);
      SaleDetails.smartHome = parseFloat(
        document.getElementById("HOMEPHONE").value
      );
      SaleDetails.bpo = parseFloat(document.getElementById("BPO").value);
      SaleDetails.tvm = parseFloat(document.getElementById("TVM").value);
      SaleDetails.nacBasic = parseFloat(document.getElementById("BASIC").value);
      SaleDetails.accessories = parseFloat(
        document.getElementById("ACCESSORIES").value
      );
      SaleDetails.mc = parseFloat(document.getElementById("MC").value);
      SaleDetails.action = "Subtract";
      SaleDetails.employeeID = Sale.employeeID;
      SaleDetails.store = Sale.store;
      SaleDetails.week = Sale.week;
      SaleDetails.year = Sale.year;
      SaleDetails.month = Sale.month;
      SaleDetails.day = Sale.day;

      if (
        SaleDetails.nacByod > 0 ||
        SaleDetails.nacTerm > 0 ||
        SaleDetails.mbb > 0 ||
        SaleDetails.hup > 0 ||
        SaleDetails.express > 0 ||
        SaleDetails.nacPrepaid > 0 ||
        SaleDetails.dp > 0 ||
        SaleDetails.valuepack > 0 ||
        SaleDetails.watch > 0 ||
        SaleDetails.bpo > 0 ||
        SaleDetails.tvm > 0 ||
        SaleDetails.nacBasic > 0 ||
        SaleDetails.accessories > 0 ||
        SaleDetails.mc > 0 ||
        SaleDetails.tv > 0 ||
        SaleDetails.internet > 0 ||
        SaleDetails.smartHome > 0
      ) {
        if (
          ((SaleDetails.nacByod > 0 ||
            SaleDetails.nacTerm > 0 ||
            SaleDetails.hup > 0 ||
            SaleDetails.nacPrepaid > 0 ||
            SaleDetails.dp > 0 ||
            SaleDetails.tvm > 0 ||
            SaleDetails.nacBasic > 0) &&
            SaleDetails.arpu > 0) ||
          (SaleDetails.arpu == 0 &&
            SaleDetails.nacByod == 0 &&
            SaleDetails.nacTerm == 0 &&
            SaleDetails.hup == 0 &&
            SaleDetails.nacPrepaid == 0 &&
            SaleDetails.dp == 0 &&
            SaleDetails.tvm == 0 &&
            SaleDetails.nacBasic == 0)
        ) {
          const { data } = await axios.put(
            `/sales/post`,
            { SaleDetails },
            { withCredentials: true }
          );
          alert.success(data.message);
          setTimeout(function () {
            window.location.reload();
            setIsLoading(false);
          }, 500);
        }
      } else {
        setIsLoading(false);
        alert.error("Add ARPU!");
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err.response.data.message);
      alert.error(err.response.data.message);
    }
  }

  return (
    <Container
      style={{
        justifyContent: "center",
        backgroundColor: "#C9D6DF",
        borderRadius: 20,
        textAlign: "ceneter",
        marginBottom: 150,
      }}
    >
      {oldSale !== null ? (
        <Formik>
          <Form>
            <Header>Return Sale</Header>

            <Grid container style={{ justifyContent: "center" }}>
              <FormItems
                amount="0"
                id="NAC"
                desc="New Month to Month Activation"
                setMax={Sale?.sale.nacByod}
              />
              <FormItems
                amount="0"
                id="TNAC"
                desc="New Term Activation"
                setMax={Sale?.sale.nacTerm}
              />
              <FormItems
                amount="0"
                id="TVM"
                desc="New TVM Upgrade"
                setMax={Sale?.sale.tvm}
              />
              <FormItems
                amount="0"
                id="HUP"
                desc="New Hardware Upgrade"
                setMax={Sale?.sale.hup}
              />
              <FormItems
                amount="0"
                id="BASIC"
                desc="New CRTC Plan Activation"
                setMax={Sale?.sale.nacBasic}
              />
              <FormItems
                amount="0"
                id="MC"
                desc="MasterCard"
                setMax={Sale?.sale.mc}
              />
              <FormItems
                amount="0"
                id="EXPRESS"
                desc="New Express Pickup"
                setMax={Sale?.sale.express}
              />
              <FormItems
                amount="0"
                id="DP"
                desc="Device Protection"
                setMax={Sale?.sale.dp}
              />
              <FormItems
                amount="0"
                id="PREPAID"
                desc="New Prepaid Activation"
                setMax={Sale?.sale.nacPrepaid}
              />
              <FormSlider
                amount="0"
                id="ACCESSORIES"
                desc="Essential Accessories"
                setMax={Sale?.sale.accessories}
              />
              <FormItems
                amount="0"
                id="VALUEPACK"
                desc="ValuePack Attach"
                setMax={Sale?.sale.valuepack}
              />
              <FormSlider
                amount="0"
                id="BPO"
                desc="Back Pocket Offer"
                setMax={Sale?.sale.bpo}
              />
              <FormSlider
                amount="0"
                id="ARPU"
                desc="Avg Revenue Per Unit (MSF + DP)"
                setMax={Sale?.sale.arpu}
              />
              <FormItems
                amount="0"
                id="WHI"
                desc="Wireless Home Internet Activation"
                setMax={Sale?.sale.watch}
              />
              <FormItems
                amount="0"
                id="RENEW"
                desc="Internet Renewal"
                setMax={Sale?.sale.mbb}
              />
              <FormItems
                amount="0"
                id="INTERNET"
                desc="Ignite Internet Activation"
                setMax={Sale?.sale.internet}
              />
              <FormItems
                amount="0"
                id="TV"
                desc="Ignite TV Activation"
                setMax={Sale?.sale.tv}
              />
              <FormItems
                amount="0"
                id="HOMEPHONE"
                desc="Home Phone Activation"
                setMax={Sale?.sale.smartHome}
              />
            </Grid>
            {isLoading && (
              <Header style={{ justifyContent: "center", marginTop: 10 }}>
                <CircularProgress />
              </Header>
            )}

            <Header style={{ marginTop: 50 }}>
              <Button
                style={{
                  justifyContent: "center",
                  backgroundColor: "#1E2022",
                  borderRadius: 20,
                  textAlign: "ceneter",
                  fontSize: 25,
                  color: "white",
                  padding: 15,
                  fontWeight: "initial",
                }}
                onClick={() => post()}
              >
                Submit
              </Button>
            </Header>
          </Form>
        </Formik>
      ) : null}
    </Container>
  );
}

export default ReturnForm;
