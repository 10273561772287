import React, { useState, useEffect } from 'react'
import axios from 'axios'
import RepSales from '../../Rep/DailySale'
import StoreSales from '../../Rep/DailySalesTotal'
import {Item, SalesDiv, Container, Header, Btns, BtnLink, ItemContainer} from './WeeklySalePageElements'
import { useLocation, useNavigate, useParams} from 'react-router-dom'
import {Temporal} from '@js-temporal/polyfill'
import { CircularProgress } from '@mui/material'
import { useAlert } from 'react-alert'

function Sales() {
    
    const alert = useAlert()
    const navigate = useNavigate()
    let location = useLocation()

    const store = sessionStorage.getItem('store')

    let {year, week} = useParams();
    year = parseInt(year)
    week = parseInt(week)


    const[repSales, setRepSales] = useState([]);
    const[storeSales, setStoreSales] = useState([]);
    
    const[noSaleReps, setNoSaleReps] = useState([]);

    
    const [isLoading, setIsLoading] = useState(true)

    
    let currentDate = Temporal.Now.plainDateISO()


    function Yesterday(){

            if(week < 1){
                year=(year - 1)
                week=(52)
            }else{
                week=(week - 1)
            }
            navigate('/' + store + `/weekly/` + year + `/` + week)
    }

    function Tomorrow(){
        if(week > currentDate.weekOfYear && (year === currentDate.year || year > currentDate.year)){
            week=(currentDate.add({days:1}).weekOfYear)
            year=(currentDate.year)
        }else{
            if(year > 52 && year < currentDate.year){
                year=(year + 1)
                week=(1)
            }if(week < currentDate.weekOfYear){
                week=(week + 1)
            }else{
                week=(currentDate.add({days:1}).weekOfYear)
            }
        }
        navigate('/' + store + `/weekly/` + year + `/` + week)
    }



    useEffect(() => {
        setIsLoading(true)
        async function FetchData(){
                try{
                const getUserDailySales = await axios.get(`/sales/weekly/` + store + `/` + year + `/` + week, {withCredentials: true});
                
                const getStoreDailySales = await axios.get(`/sales/store/weekly/` + store + `/` + year + `/` + week, {withCredentials: true})

                setIsLoading(false)
                setStoreSales(getStoreDailySales.data.weekly);
                setRepSales(getUserDailySales.data.weekly);

                }catch (err) {
                    setTimeout(function() {
                        window.location.reload()
                        }, 500);
                        console.log(err.response.data.message)
                        alert.error('Refreshing page')
                    if(err.response.data.message === 'Not Authorized! Login to gain access!' && err.response.data.success === false){
                        alert.error(err.response.data.message)
                        sessionStorage.clear()
                        setTimeout(function() {
                            navigate("/")
                            alert.success("Signed Out!")
                        }, 2000);
                    }
                }
                

                
            }

        FetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])



return (
    <>
        <Container style={{justifyContent: 'center', allignItems: 'center'}}>
            <Header>Weekly Sales</Header>
        </Container>

        <Container>
            <Btns>
                <Item><BtnLink onClick={() => Yesterday()}>Back</BtnLink></Item>
                <Item></Item>
                <Item></Item>
                <Item></Item>
                <Item></Item>
                <Item></Item>
                <Item></Item>
                <Item></Item>
                <Item></Item>
                <Item></Item>
                <Item><BtnLink onClick={() => Tomorrow()}>Next</BtnLink></Item>
            </Btns>
        </Container>

        <Container>
            <SalesDiv>
                <ItemContainer><Item>REP</Item></ItemContainer>
                <ItemContainer><Item>NAC</Item></ItemContainer>
                <ItemContainer><Item>HUP</Item></ItemContainer>
                <ItemContainer><Item>MC</Item></ItemContainer>
                <ItemContainer><Item>ACC</Item></ItemContainer>
                <ItemContainer><Item>DP</Item></ItemContainer>
                <ItemContainer><Item>IGNITE</Item></ItemContainer>
                <ItemContainer><Item>MISC</Item></ItemContainer>
                <ItemContainer><Item>DETAILS</Item></ItemContainer>
            </SalesDiv>
        </Container>

        { isLoading && <Header style={{justifyContent: 'center', marginTop: 15}}><CircularProgress /></Header>}

        <div className="bg-light mt-4">
            {repSales && repSales.map((sale) => {
                return(
                <RepSales sale={sale} key={sale?.employeeID} type='weekly'/>
                )
            })}
        </div>
        <div>
            <StoreSales sale={storeSales} type='weekly'/>
        </div>
    </>
  )
}

export default Sales