import React, { useEffect, useState } from "react";
import axios from "axios";
import { Temporal } from "@js-temporal/polyfill";
import { Header, Error } from "../HomePage/HomePageElements";
import { useForm } from "react-hook-form";
import {
  TextField,
  IconButton,
  Container,
  Slider,
  Box,
  MenuItem,
  CircularProgress,
  Input,
} from "@material-ui/core";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { Button, Grid, Tooltip, Typography } from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";

export const FormItems = ({ amount, id, desc }) => {
  const [item, setItem] = useState(parseInt(amount));
  const [name, setName] = useState(id);
  const [bg, setBg] = useState("#C9D6DF");

  useEffect(() => {
    if (item > 0) {
      setBg("#FFF");
    } else {
      setBg("#C9D6DF");
    }
  }, [item]);

  return (
    <div
      style={{
        padding: 25,
        margin: 25,
        backgroundColor: `${bg}`,
        borderRadius: 15,
      }}
    >
      <div style={{ textAlign: "center", justifyContent: "center" }}>
        <Tooltip placement="top" title={<div>{desc}</div>}>
          <label
            style={{ fontSize: 20, justifyContent: "center" }}
            htmlFor={name}
          >
            {name}
          </label>
        </Tooltip>
      </div>

      <br />

      <div style={{ textAlign: "center", justifyContent: "center" }}>
        <IconButton
          onClick={() => {
            if (item !== 0) {
              setItem(item - 1);
            }
          }}
        >
          <RemoveIcon />
        </IconButton>

        <TextField
          style={{ width: 45, textAlign: "center", justifyContect: "center" }}
          id={name}
          type="text"
          variant="outlined"
          value={item}
          onChange={null}
        />

        <IconButton
          onClick={() => {
            setItem(item + 1);
          }}
        >
          <AddIcon />
        </IconButton>
      </div>
    </div>
  );
};

export const FormSlider = ({ amount, id, max, desc }) => {
  const [item, setItem] = useState(parseInt(amount));
  const [name, setName] = useState(id);
  const [bg, setBg] = useState("#C9D6DF");
  let prevValue = amount;

  function changedValue() {
    let val = parseFloat(document.getElementById(`${name}`).value);
    if (!isNaN(val)) {
      setItem(val);
      prevValue = val;
    } else {
      setItem(prevValue);
    }
  }

  useEffect(() => {
    if (item > 0) {
      setBg("#FFF");
    } else {
      setBg("#C9D6DF");
    }
  }, [item]);

  return (
    <div
      style={{
        padding: 25,
        margin: 25,
        backgroundColor: `${bg}`,
        borderRadius: 15,
        width: 140,
      }}
    >
      <div style={{ textAlign: "center", justifyContent: "center" }}>
        <Tooltip placement="top" title={<div>{desc}</div>}>
          <label
            style={{ fontSize: 20, justifyContent: "center" }}
            htmlFor={name}
          >
            {name}
          </label>
        </Tooltip>
      </div>

      <br />

      <div style={{ textAlign: "center", justifyContent: "center" }}>
        <TextField
          style={{ width: 140, textAlign: "center", justifyContect: "center" }}
          id={name}
          type="tel"
          autoComplete="off"
          variant="outlined"
          defaultValue={item}
          onChange={() => changedValue()}
        />

        {/* <Slider 
    max={max}
    min={0}
    defaultValue={item}
    onChange={() => changedValue()}
    valueLabelDisplay='auto'
    /> */}
      </div>
    </div>
  );
};

export const FormMc = ({ id, desc }) => {
  const [item, setItem] = useState("N/A");
  const [name, setName] = useState(id);
  const [bg, setBg] = useState("#C9D6DF");

  useEffect(() => {
    if (item != "N/A") {
      setBg("#FFF");
    } else {
      setBg("#C9D6DF");
    }
  }, [item]);

  return (
    <div
      style={{
        padding: 25,
        margin: 25,
        backgroundColor: `${bg}`,
        borderRadius: 15,
        width: 140,
      }}
    >
      <div style={{ textAlign: "center", justifyContent: "center" }}>
        <Tooltip placement="top" title={<div>{desc}</div>}>
          <label
            style={{ fontSize: 20, justifyContent: "center" }}
            htmlFor={name}
          >
            {name}
          </label>
        </Tooltip>
      </div>

      <br />

      <div style={{ textAlign: "center", justifyContent: "center" }}>
        <TextField
          style={{ width: 140, textAlign: "center", justifyContect: "center" }}
          id={name}
          type="tel"
          variant="outlined"
          defaultValue={item}
          onChange={() => setItem(document.getElementById(`${name}`).value)}
          select
        >
          <MenuItem value="N/A" onClick={() => setBg("#C9D6DF")}>
            N/A
          </MenuItem>
          <MenuItem value="Approved" onClick={() => setBg("#FFF")}>
            Approved
          </MenuItem>
          <MenuItem value="Review" onClick={() => setBg("#FFF")}>
            Review
          </MenuItem>
        </TextField>

        {/* <Slider 
    max={max}
    min={0}
    defaultValue={item}
    onChange={() => changedValue()}
    valueLabelDisplay='auto'
    /> */}
      </div>
    </div>
  );
};

const INITIAL_FORM_STATE = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
};

const FORM_VALIDATION = Yup.object().shape({
  firstName: Yup.string(),
  lastName: Yup.string(),
  email: Yup.string(),
});

let SaleDetails = {
  nacByod: 0,
  nacTerm: 0,
  tvm: 0,
  hup: 0,
  mbb: 0,
  nacBasic: 0,
  mc: 0,
  express: 0,
  dp: 0,
  nacPrepaid: 0,
  accessories: 0,
  valuepack: 0,
  bpo: 0,
  watch: 0,
  internet: 0,
  tv: 0,
  smartHome: 0,
  arpu: 0,
  employeeID: null,
  store: null,
  year: null,
  month: null,
  day: null,
  week: null,
  action: null,
};

function SaleForm({ employees }) {
  const alert = useAlert();
  let currentDate = Temporal.Now.plainDateISO();
  let user = sessionStorage.getItem("employee");
  const [dateString, setDatestring] = useState(currentDate.toString());
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [selectedUser, setSelectedUser] = useState(user);

  const brand = sessionStorage.getItem("brand");
  const permission = sessionStorage.getItem("permission");

  async function post() {
    setError(false);
    setIsLoading(true);
    try {
      SaleDetails.nacByod = parseFloat(document.getElementById("NAC").value);
      SaleDetails.nacTerm = parseFloat(document.getElementById("TNAC").value);
      SaleDetails.mbb = parseFloat(document.getElementById("RENEW").value);
      SaleDetails.hup = parseFloat(document.getElementById("HUP").value);
      SaleDetails.arpu = parseFloat(document.getElementById("ARPU").value);
      SaleDetails.express = parseFloat(
        document.getElementById("EXPRESS").value
      );
      SaleDetails.nacPrepaid = parseFloat(
        document.getElementById("PREPAID").value
      );
      SaleDetails.dp = parseFloat(document.getElementById("DP").value);
      SaleDetails.valuepack = parseFloat(
        document.getElementById("VALUEPACK").value
      );
      SaleDetails.watch = parseFloat(document.getElementById("WHI").value);

      SaleDetails.internet = parseFloat(
        document.getElementById("INTERNET").value
      );
      SaleDetails.tv = parseFloat(document.getElementById("TV").value);
      SaleDetails.smartHome = parseFloat(
        document.getElementById("HOMEPHONE").value
      );

      SaleDetails.bpo = parseFloat(document.getElementById("BPO").value);
      SaleDetails.tvm = parseFloat(document.getElementById("TVM").value);
      SaleDetails.nacBasic = parseFloat(document.getElementById("BASIC").value);
      SaleDetails.accessories = parseFloat(
        document.getElementById("ACCESSORIES").value
      );
      SaleDetails.mc = parseFloat(document.getElementById("MC").value);
      SaleDetails.action = "Add";
      SaleDetails.employeeID = parseInt(selectedUser);
      SaleDetails.store = sessionStorage.getItem("store");

      let currentDate = Temporal.Now.plainDateISO();
      let inputDate = Temporal.PlainDate.from(
        document.getElementById("DATE").value
      );
      SaleDetails.week = inputDate.add({ days: 1 }).weekOfYear;
      if (Temporal.PlainDate.compare(currentDate, inputDate) != -1) {
        SaleDetails.year = parseFloat(inputDate.year);
        SaleDetails.month = parseFloat(inputDate.month);
        SaleDetails.day = parseFloat(inputDate.day);
        if (
          SaleDetails.nacByod > 0 ||
          SaleDetails.nacTerm > 0 ||
          SaleDetails.mbb > 0 ||
          SaleDetails.hup > 0 ||
          SaleDetails.express > 0 ||
          SaleDetails.nacPrepaid > 0 ||
          SaleDetails.dp > 0 ||
          SaleDetails.valuepack > 0 ||
          SaleDetails.watch > 0 ||
          SaleDetails.bpo > 0 ||
          SaleDetails.tvm > 0 ||
          SaleDetails.nacBasic > 0 ||
          SaleDetails.accessories > 0 ||
          SaleDetails.mc > 0 ||
          SaleDetails.tv > 0 ||
          SaleDetails.internet > 0 ||
          SaleDetails.smartHome > 0
        ) {
          if (SaleDetails.accessories >= 0 && SaleDetails.bpo >= 0) {
            if (
              ((SaleDetails.nacByod > 0 ||
                SaleDetails.nacTerm > 0 ||
                SaleDetails.hup > 0 ||
                SaleDetails.nacPrepaid > 0 ||
                SaleDetails.dp > 0 ||
                SaleDetails.tvm > 0 ||
                SaleDetails.nacBasic > 0) &&
                SaleDetails.arpu > 0) ||
              (SaleDetails.arpu == 0 &&
                SaleDetails.nacByod == 0 &&
                SaleDetails.nacTerm == 0 &&
                SaleDetails.hup == 0 &&
                SaleDetails.nacPrepaid == 0 &&
                SaleDetails.dp == 0 &&
                SaleDetails.tvm == 0 &&
                SaleDetails.nacBasic == 0)
            ) {
              const { data } = await axios.put(
                `/sales/post`,
                { SaleDetails },
                { withCredentials: true }
              );

              alert.success(data.message);
              setTimeout(function () {
                window.location.reload();
                setIsLoading(false);
              }, 500);
            } else {
              setIsLoading(false);
              alert.error("Add ARPU!");
            }
          } else {
            setIsLoading(false);
            alert.error("Invalid Accessories or BPO");
          }
        } else {
          setIsLoading(false);
          alert.error("Add Item!");
        }
      } else {
        setIsLoading(false);
        alert.error("Invalid Date");
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err.response.data.message);
      alert.error(err.response.data.message);
    }
  }

  //<input type='date' name='date' value={dateString} onChange={e => setDatestring(e.target.value)}/>

  function updateEmployee(e) {
    setSelectedUser(e.target.value);
  }
  return (
    <Container
      style={{
        justifyContent: "center",
        backgroundColor: "#C9D6DF",
        borderRadius: 20,
        textAlign: "ceneter",
        marginBottom: 150,
      }}
    >
      <Formik
        initialValues={{ ...INITIAL_FORM_STATE }}
        validationSchema={FORM_VALIDATION}
        onSubmit={(values) => {
          console.log(values);
        }}
      >
        <Form>
          <Header>Add Sale</Header>

          <Header
            style={{
              justifyContent: "center",
              justifyItems: "center",
              marginTop: 0,
            }}
          >
            <Input
              id="DATE"
              type="date"
              defaultValue={`${currentDate}`}
            ></Input>
          </Header>
          <Header
            style={{
              justifyContent: "center",
              justifyItems: "center",
              marginTop: 0,
            }}
          >
            {" "}
            {permission >= 1 ? (
              <TextField
                style={{ margin: 10, width: 225 }}
                id="employee"
                label="Employee"
                variant="outlined"
                select
                onChange={(e) => updateEmployee(e)}
              >
                {employees?.map((employee) => (
                  <MenuItem
                    key={employee?.employeeID}
                    value={employee?.employeeID}
                  >
                    {employee?.firstName}
                  </MenuItem>
                ))}
              </TextField>
            ) : null}
          </Header>

          <Grid container style={{ justifyContent: "center" }}>
            <FormItems
              amount="0"
              id="NAC"
              desc="New Month to Month Activation"
            />
            <FormItems amount="0" id="TNAC" desc="New Term Activation" />
            <FormItems amount="0" id="TVM" desc="New TVM Upgrade" />
            <FormItems amount="0" id="HUP" desc="New Hardware Upgrade" />
            <FormItems amount="0" id="BASIC" desc="New CRTC Plan Activation" />
            <FormItems amount="0" id="MC" desc="MasterCard" />
            <FormItems amount="0" id="EXPRESS" desc="New Express Pickup" />
            <FormItems amount="0" id="DP" desc="Device Protection" />
            <FormItems amount="0" id="PREPAID" desc="New Prepaid Activation" />
            <FormSlider
              amount="0"
              id="ACCESSORIES"
              max="7000"
              desc="Essential Accessories"
            />
            <FormItems amount="0" id="VALUEPACK" desc="ValuePack Attach" />
            <FormSlider
              amount="0"
              id="BPO"
              max="500"
              desc="Back Pocket Offer"
            />
            <FormSlider
              amount="0"
              id="ARPU"
              max="500"
              desc="Avg Revenue Per Unit (MSF + DP)"
            />
            <FormItems amount="0" id="WHI" desc="Wireless Home Internet" />
            <FormItems amount="0" id="RENEW" desc="Internet Renewal" />
            <FormItems
              amount="0"
              id="INTERNET"
              desc="Ignite Internet Activation"
            />
            <FormItems amount="0" id="TV" desc="Ignite TV Activation" />
            <FormItems
              amount="0"
              id="HOMEPHONE"
              desc="Ignite Home Phone Activation"
            />
          </Grid>
          {isLoading && (
            <Header style={{ justifyContent: "center", marginTop: 10 }}>
              <CircularProgress />
            </Header>
          )}

          <Header style={{ marginTop: 50 }}>
            <Button
              style={{
                justifyContent: "center",
                backgroundColor: "#1E2022",
                borderRadius: 20,
                textAlign: "ceneter",
                fontSize: 25,
                color: "white",
                padding: 15,
                fontWeight: "initial",
                height: 50,
                width: 225,
              }}
              onClick={() => post()}
            >
              Submit
            </Button>
          </Header>
        </Form>
      </Formik>
    </Container>
  );
}

export default SaleForm;
