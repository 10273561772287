import axios from 'axios'
import React, { useState } from 'react'
import {Container, Inner, Header, BtnLink, Form, Input} from './LoginFormElements'
import {useNavigate} from 'react-router-dom'
import { CircularProgress } from '@material-ui/core'
import { useAlert } from 'react-alert'


function Login() {

    const alert = useAlert()
    const navigate = useNavigate()
    const [employeeID, setEmployeeID] = useState('')
    const [password, setPassword] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    let perms = 0
    
    const handleSubmit = async (e) => {
        setIsLoading(true)
        e.preventDefault();
        try{
            const {data} = await axios.put(`/store/login`, {employeeID, password}, {withCredentials: true})
            console.log(data)
            sessionStorage.setItem('store', data.info.route)
            sessionStorage.setItem('employee', data.info.employee)
            sessionStorage.setItem('name', data.info.name)
            sessionStorage.setItem('brand', data.info.brand)
            sessionStorage.setItem('area', data.info.area)
            sessionStorage.setItem('region', data.info.region)
            sessionStorage.setItem('role', data.info.role)  
            sessionStorage.setItem('jwt', data.aJWT)
            sessionStorage.setItem('model', true)
            sessionStorage.setItem('storename', data.store.name)

            if(data.info.role === 'Creator'){
                perms = 333
            }else if(data.info.role === 'Admin'){
                perms = 400
            }else if(data.info.role === 'National'){
                perms = 300
            }else if(data.info.role === 'RegionalManager'){
                perms = 200
            }else if(data.info.role === 'AreaManager'){
                perms = 100
            }else if(data.info.role === 'Manager'){
                perms = 3
            }else if(data.info.role === 'MOD'){
                perms = 2
            }else if(data.info.role === 'Associate'){
                perms = 1
            }else{
                perms = 0
            }

            sessionStorage.setItem('permission', perms)

            alert.success(`Welcome ${data.info.role}`)

            
            navigate("/" + data.info.route)
            setIsLoading(false)

            
        } catch (err) {
            alert.error(`User: UNAUTHORIZED`)
            sessionStorage.clear()
            setTimeout(function() {window.location.reload()}, 2000);
        }
    }



  return (
        <Container>
            <Inner>
                <Header>Login</Header>
                <Form onSubmit={handleSubmit}> 
                    <Input type="text" placeholder='Username' required onChange={(e) => setEmployeeID(e.target.value)} onFocus={e => {e.target.select()}} ></Input>
                    <Input type="password" placeholder='Password' required onChange={(e) => setPassword(e.target.value)}></Input>
                    { isLoading && <div style={{justifyContent: 'center', marginTop: 15}}><CircularProgress /></div>}
                    <BtnLink type="submit">Sign In</BtnLink>
                </Form>
            </Inner>
        </Container>
  )
}

export default Login