import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useLocation, useNavigate} from 'react-router-dom'
import { Temporal } from '@js-temporal/polyfill'
import { Grid, CircularProgress, Button, Modal, Paper} from '@mui/material'
import { ItemContainer, Container, Item, SalesDiv, Header } from '../../Rep/DailySalesPage/DailySalesPageElements'
import NewUser from './NewUser'
import User from './User'

export default function Users() {

    const [isLoading, setIsLoading] = useState(false)

    const [open, setOpen] = useState(false)
    const handleClose = () => {
        setOpen(false)
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.paper',
        borderRadius: 15,
        boxShadow: 24,
        p: 4,
      };

    const [main, setMain] = useState(true)
    const [addUser, setAddUser] = useState(false)
    const [editUser, setEditUser] = useState(false)

    const [sort, setSort] = useState('employeeID')

    const [employees, setEmployees] = useState([])

    useEffect(() => {
        setIsLoading(true)
        async function FetchData(){
                try{
                let {data} = await axios.get(`/store/users/` + sort, {withCredentials: true});
                console.log(data)
                setEmployees(data.employees)
                setIsLoading(false)
                    
                }catch (err) {
                       console.log(err.response.data)
                }
                

                
            }

        FetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sort])


  return (
    <>
    <Container>
        <Header>User Management</Header>
    </Container>

    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Paper sx={style}>
            <NewUser />
        </Paper>
    </Modal>

    {isLoading ? <Header style={{justifyContent: 'center'}}><CircularProgress /></Header> :
    main ?
    <>
        <Container>
            <SalesDiv>
                <ItemContainer style={{cursor: 'grab'}} onClick={() => setSort('isActive')}><Item>STATUS</Item></ItemContainer>
                <ItemContainer style={{cursor: 'grab'}} onClick={() => setSort('employeeID')}><Item>ID</Item></ItemContainer>
                <ItemContainer><Item>REP</Item></ItemContainer>
                <ItemContainer style={{cursor: 'grab'}} onClick={() => setSort('brand')}><Item>BRAND</Item></ItemContainer>
                <ItemContainer style={{cursor: 'grab'}}><Item>REGION</Item></ItemContainer>
                <ItemContainer style={{cursor: 'grab'}}><Item>AREA</Item></ItemContainer>
                <ItemContainer style={{cursor: 'grab'}} onClick={() => setSort('store')}><Item>STORE</Item></ItemContainer>
                <ItemContainer><Item>ACTION</Item></ItemContainer>
                <ItemContainer><Button variant='contained' style={{width: 110}} onClick={() => setOpen(true)}>ADD USER</Button></ItemContainer>
            </SalesDiv>
        </Container>

        <Item style={{color: 'black', marginBottom: 50}}>
        {employees && employees.map((employee, key) => {
            return(
            <User employee={employee} key={key}/>
            )
        })}
        </Item>
    </>
    : 
    <>

    </>
}
    </>
  )
}
