import {MenuItem, TextField, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import {Button, CircularProgress, Grid} from '@mui/material';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { useAlert } from 'react-alert';

function UpdateUser({employee}) {

    const navigate = useNavigate()

    const alert = useAlert()

    const [color, setColor] = useState('primary')
    const [isLoading, setIsLoading] = useState(false)

    const [newPassword, setNewPassword] = useState()
    const [newPassword2, setNewPassword2] = useState()
    const [oldPassword, setOldPassword] = useState()
    

    const [role, setRole] = useState(employee?.role)

    const roles = [
        {
            value: 'Associate',
            label: 'Associate'
        },
        {
            value: 'MOD',
            label: 'MOD'
        },
        {
            value: 'Manager',
            label: 'Manager'
        },
        {
            value: 'AreaManager',
            label: 'Area Manager'
        },
        {
            value: 'RegionalManager',
            label: 'Regional Manager'
        },
        {
            value: 'National',
            label: 'National'
        },
        {
            value: 'Admin',
            label: 'Admin'
        },
        {
            value: 'Creator',
            label: 'Creator'
        }
    ]

    let person = {
        employeeID: employee?.employeeID,
        newPassword: '',
        oldPassword: ''
    }



async function handleSubmit() {

        if(newPassword !== newPassword2 || newPassword.length < 4) return alert.error('Passwords dont match or are too short!')

        if(oldPassword == null || oldPassword.length < 3) return alert.error('Invalid Password')

        if(oldPassword === newPassword) return alert.error('Cant use current password as new password')


        person.newPassword = newPassword
        person.oldPassword = oldPassword

        setIsLoading(true)
            try{
            let result = await axios.put(`/store/update/password`, {person}, {withCredentials: true})
            result = result.data
            setIsLoading(false)
            alert.success(result.message)
            setTimeout(function() {window.location.reload()}, 1000);
            }catch(err){
                alert.error(err.response.data.message)
            }
        setIsLoading(false)
    }

  return (
    <Grid container style={{ marginBottom: 25, marginTop: 50, justifyContent: 'center'}}>
        <Grid container xs={12} style={{justifyContent: 'center'}}>
            <Typography variant='h4'>Change Password</Typography>
        </Grid>
    
    <Grid autoComplete='false' style={{justifyContent: 'center', borderStyle: 'solid', width: 550, borderColor:"#9e9e9e", borderRadius: 10, borderWidth: 1, marginTop: 25}} noValidate onSubmit={handleSubmit}> 
        
        { (isLoading) ? <Grid container style={{justifyContent: 'center', marginTop: 15, marginBottom: 15}}><CircularProgress /></Grid> : (!isLoading && color !== 'primary') ? <Button style={{margin: 10, width: 150}} variant="contained" onClick={() => (window.location.reload())} color={color}>{color}</Button> : (!isLoading) ? <>
        <Grid container style={{justifyContent: 'center'}}>
            <Grid item xs={'auto'}>
                <TextField
                style={{margin: 10}}
                label='First Name'
                variant='filled'
                type={'text'}
                value={employee?.firstName}
                disabled
                autoComplete='false'
                />
            </Grid>
            <Grid item xs={'auto'}>
                <TextField
                style={{margin: 10}}
                label='Last Name'
                variant='filled'
                type={'text'}
                value={employee?.lastName}
                disabled
                autoComplete='false'
                />
            </Grid>
            <Grid item xs={'auto'}>
                <TextField
                style={{margin: 10}}
                label='Email'
                variant='filled'
                type={'email'}
                value={employee?.email}
                disabled
                autoComplete='false'
                />
            </Grid>
            <Grid item xs={'auto'}>
                <TextField 
                style={{margin: 10}}
                label='Employee Number'
                variant='filled'
                type={'tel'}
                value={employee?.employeeID}
                disabled
                autoComplete='false'
                />
            </Grid>
            <Grid item xs={'auto'}>
                <TextField 
                style={{margin: 10}}
                id='newpassword'
                label='New Password'
                variant='outlined'
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                type={'password'}
                autoComplete='false'
                />
            </Grid>
            <Grid item xs={'auto'}>
                <TextField 
                style={{margin: 10}}
                label='Confirm New Password'
                id='newpasswordconfrim'
                variant='outlined'
                type={'password'}
                value={newPassword2}
                onChange={(e) => setNewPassword2(e.target.value)}
                autoComplete='false'
                />
            </Grid>
            <Grid item xs={'auto'}>
                <TextField 
                style={{margin: 10}}
                label='Old Password'
                id='oldpassword'
                variant='outlined'
                type={'password'}
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                autoComplete='false'
                />
            </Grid>
            <Grid item xs={8}>
                <TextField 
                style={{marginBottom: 10}}
                label='Employee Role'
                variant='filled'
                select
                fullWidth
                value={role}
                disabled
                >
                {roles.map((option) => (
                    <MenuItem disableRipple key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
                </TextField>
            </Grid>
        </Grid>
        <Button fullWidth style={{height: 40, backgroundColor: 'blue'}} variant="contained" onClick={() => handleSubmit()} color={color}>Submit</Button>
        </> : null}
    </Grid>
    </Grid>
  )
}

export default UpdateUser