import {Container, MenuItem, TextField, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { Form } from './ManagementElements'
import {Button, CircularProgress, Grid} from '@mui/material';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { useAlert } from 'react-alert';

function Register() {

    const navigate = useNavigate()

    const alert = useAlert()

    const [color, setColor] = useState('primary')
    const [isLoading, setIsLoading] = useState(false)
    
    const [error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')

    const [firstName, setFirstName] = useState('')
    const [firstNameError, setFirstNameError] = useState(false)
    const [firstNameErrorMsg, setFirstNameErrorMsg] = useState('')

    const [lastName, setLastName] = useState('')
    const [lastNameError, setLastNameError] = useState(false)
    const [lastNameErrorMsg, setLastNameErrorMsg] = useState('')

    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState(false)
    const [emailErrorMsg, setEmailErrorMsg] = useState('')


    const [employeeID, setEmployeeID] = useState('')
    const [employeeIDError, setEmployeeIDError] = useState(false)
    const [employeeIDErrorMsg, setEmployeeIDErrorMsg] = useState('')

    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [passwordError, setPasswordError] = useState(false)
    const [passwordErrorMsg, setPasswordErrorMsg] = useState('')

    const [role, setRole] = useState('Associate')

    let store = sessionStorage.getItem('store')

    const roles = [
        {
            value: 'Associate',
            label: 'Associate'
        },
        {
            value: 'MOD',
            label: 'MOD'
        },
        {
            value: 'Manager',
            label: 'Manager'
        }
    ]

    let person = {
        firstName: '',
        lastName: '',
        email: '',
        employeeID: 0,
        password: '',
        store: 0,
        role: ''
    }



    const  handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
       

        if(firstName === '' || !isNaN(firstName)){
            setFirstNameError(true)
            setFirstNameErrorMsg('Invalid Input')
        }

        if(lastName === '' || !isNaN(lastName)){
            setLastNameError(true)
            setLastNameErrorMsg('Invalid Input')
        }

        if(email === '' || !isNaN(email)){
            setEmailError(true)
            setEmailErrorMsg('Invalid Input')
        }


        if(employeeID === ""){
            setEmployeeIDError(true)
            setEmployeeIDErrorMsg('Invalid Input')
            navigate('/error')
        } else if (isNaN(employeeID)){
            setEmployeeIDError(true)
            setEmployeeIDErrorMsg('Must be a Number')
        } else if (employeeID.length < 7) {
            setEmployeeIDError(true)
            setEmployeeIDErrorMsg('Invalid Employee Number')
        }

        if(password !== confirmPassword){
            setPasswordError(true)
            setPasswordErrorMsg('Password does not match!')
        }else if(password.length < 4){
            setPasswordError(true)
            setPasswordErrorMsg('Password must be longer!')
        }
        if(password === confirmPassword && password.length > 3){
            
            person.firstName = firstName
            person.lastName = lastName
            person.email = email
            person.employeeID = employeeID
            person.password = password
            person.role = role
            person.store = store
            try{
            let result = await axios.put(`/store/register`, {person}, {withCredentials: true})
            result = result.data
            setIsLoading(false)
            alert.success(result.message)
            setTimeout(function() {window.location.reload()}, 1000);
            }catch(err){
                alert.error(err.response.data.message)
            }

        }else{
            setColor('error')
        }
        setIsLoading(false)
    }

  return (
    <Grid container style={{ marginBottom: 25, justifyContent: 'center'}}>
        <Grid container xs={12} style={{justifyContent: 'center'}}>
            <Typography variant='h4'>Register</Typography>
        </Grid>
    
    <Form autoComplete='false' style={{justifyContent: 'center', borderStyle: 'solid', width: 550, borderColor:"#9e9e9e", borderRadius: 10, borderWidth: 1, marginTop: 25}} noValidate onSubmit={handleSubmit}> 
        
        { (isLoading) ? <div style={{justifyContent: 'center', marginTop: 15}}><CircularProgress /></div> : ((!isLoading && color !== 'primary') && (!firstNameError && !employeeIDError && !passwordError)) ? <Button style={{margin: 10, width: 150}} variant="contained" onClick={() => (window.location.reload())} color={color}>{color}</Button> : (!isLoading) ? <>
        <Grid container style={{justifyContent: 'center'}}>
            <Grid item xs={'auto'}>
                <TextField
                style={{margin: 10}}
                label='First Name'
                variant='outlined'
                type={'text'}
                required
                onChange={(e) => (setFirstName(e.target.value), setFirstNameError(false), setFirstNameErrorMsg(''), setColor('primary'))}
                error={firstNameError}
                helperText={firstNameErrorMsg}
                autoComplete='false'
                />
            </Grid>
            <Grid item xs={'auto'}>
                <TextField
                style={{margin: 10}}
                label='Last Name'
                variant='outlined'
                type={'text'}
                required
                onChange={(e) => (setLastName(e.target.value), setLastNameError(false), setLastNameErrorMsg(''), setColor('primary'))}
                error={lastNameError}
                helperText={lastNameErrorMsg}
                autoComplete='false'
                />
            </Grid>
            <Grid item xs={'auto'}>
                <TextField
                style={{margin: 10}}
                label='Email'
                variant='outlined'
                type={'email'}
                required
                onChange={(e) => (setEmail(e.target.value), setEmailError(false), setEmailErrorMsg(''), setColor('primary'))}
                error={emailError}
                helperText={emailErrorMsg}
                autoComplete='false'
                />
            </Grid>
            <Grid item xs={'auto'}>
                <TextField 
                style={{margin: 10}}
                label='Employee Number'
                variant='outlined'
                type={'tel'}
                required
                onChange={(e) => (setEmployeeID(e.target.value), setEmployeeIDError(false), setEmployeeIDErrorMsg(''), setColor('primary'))}
                error={employeeIDError}
                helperText={employeeIDErrorMsg}
                autoComplete='false'
                />
            </Grid>
            <Grid item xs={'auto'}>
                <TextField 
                style={{margin: 10}}
                label='Password'
                variant='outlined'
                type={'password'}
                required
                onChange={(e) => (setPassword(e.target.value), setPasswordError(false), setPasswordErrorMsg(''), setColor('primary'))}
                error={passwordError}
                helperText={passwordErrorMsg}
                autoComplete='false'
                />
            </Grid>
            <Grid item xs={'auto'}>
                <TextField 
                style={{margin: 10}}
                label='Confirm Password'
                variant='outlined'
                type={'password'}
                required
                onChange={(e) => (setConfirmPassword(e.target.value), setPasswordError(false), setPasswordErrorMsg(''), setColor('primary')) }
                error={passwordError}
                helperText={passwordErrorMsg}
                autoComplete='false'
                />
            </Grid>
            <Grid item xs={'auto'}>
                <TextField 
                style={{margin: 10, width: 225}}
                label='Employee Role'
                variant='outlined'
                select
                value={role}
                required
                onChange={(e) => (setRole(e.target.value), setColor('primary'))}
                >
                {roles.map((option) => (
                    <MenuItem disableRipple key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
                </TextField>
            </Grid>
        </Grid>
        <Button style={{margin: 10, backgroundColor: 'blue'}} variant="contained" type='submit' color={color}>Submit</Button>
        </> : null}
    </Form>
    </Grid>
  )
}

export default Register