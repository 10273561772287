import {Container, MenuItem, TextField, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { Form } from './ManagementElements'
import {Button, CircularProgress, Grid} from '@mui/material';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { useAlert } from 'react-alert';

function Retire({employees}) {
    const alert = useAlert()
    const navigate = useNavigate()

    const [color, setColor] = useState('primary')
    const [isLoading, setIsLoading] = useState(false)

    const [retiredRep, setRetiredRep] = useState()
    
    const [error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')

    let store = sessionStorage.getItem('store')
    

    let person = {
        employeeID: 0,
        store: 0,
        token: ''
    }


    const  handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
       
        if(retiredRep !== undefined && sessionStorage.getItem('role') === "Manager"){
            
            person.employeeID = retiredRep
            person.store = store

            try{
                let result = await axios.put(`/store/delete`, {person}, {withCredentials: true})
                result = result.data
                    alert.success(result.message)
                    setTimeout(function() {window.location.reload()}, 1000);
                }catch (err){
                    console.log(err.response.data.message)
                }
        }else{
            setColor('error')
            setError(true)
            setErrorMsg('Select Employee')
        }
        setIsLoading(false)
    }

  return (
    <Grid container>
        <Grid container xs={12} style={{justifyContent: 'center'}}>
            <Typography variant='h4'>Remove</Typography>
        </Grid>
        <Grid item xs={12}>
        <Form autoComplete='false' style={{justifyContent: 'center', borderStyle: 'solid', width: 250, borderColor:"#9e9e9e", borderRadius: 10, borderWidth: 1, marginTop: 25}} noValidate onSubmit={handleSubmit}> 
        
        { (isLoading) ? <div style={{justifyContent: 'center'}}><CircularProgress /></div> : ((!isLoading && color !== 'primary' && retiredRep !== undefined)) ? <Button style={{margin: 10, width: 150}} variant="contained" onClick={() => (window.location.reload())} color={color}>{color}</Button> : (!isLoading) ? <>
        {employees !== null ? <TextField 
        style={{margin: 10, width: 225}}
        label='Employee'
        variant='outlined'
        select
        defaultValue={''}
        required
        onChange={(e) => (setRetiredRep(e.target.value), setColor('primary'), setError(false), setErrorMsg(''))}
        error={error}
        helperText={errorMsg}
        >
        {employees?.map((employee) => (
            <MenuItem key={employee?.employeeID} value={employee?.employeeID}>
                {employee?.firstName}
            </MenuItem>
        ))}
        </TextField> : null}
        <Button style={{margin: 10, backgroundColor: 'blue'}} variant="contained" type='submit' color={color}>Submit</Button>
        </> : null}
        </Form>
        </Grid>
    </Grid>
  )
}

export default Retire