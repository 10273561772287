import React from "react";
import { Button, Grid } from "@mui/material";

const DailyTally = ({ StoreSales }) => {
  // console.log(StoreSales);
  function Copy() {
    var table = document.querySelector("table");

    // create a Range object
    var range = document.createRange();
    // set the Node to select the "range"
    range.selectNode(table);
    // add the Range to the set of window selections
    window.getSelection().addRange(range);

    // execute 'copy', can't 'cut' in this case
    document.execCommand("copy");
  }

  const getDPAttach = () => {
    let dpAttach = StoreSales.sale.dp / StoreSales.subsTerm;
    dpAttach = dpAttach.toFixed(2) * 100;
    if (isNaN(dpAttach)) {
      return 0;
    }
    return `${dpAttach}%`;
  };

  return (
    <Grid container style={{ justifyContent: "center", height: 400 }}>
      <Grid item>
        <h1>Daily Tally</h1>
      </Grid>
      <Grid container item style={{ justifyContent: "center" }}>
        <Grid item>
          <p>
            {StoreSales.brand.toUpperCase()}{" "}
            {sessionStorage.getItem("storename").toUpperCase()}
          </p>
          <p>NAC- {StoreSales?.nacTotal - StoreSales?.sale?.nacPrepaid} / 6</p>
          <p>HUP- {StoreSales?.hupTotal} / 4</p>
          <p>PPD- {StoreSales?.sale?.nacPrepaid} / 1</p>
          <p>DP- {getDPAttach()} / 60%</p>
          <p>ACC- ${StoreSales?.sale?.accessories} / $314</p>
          <p>MC- {StoreSales?.mcTotal} / 1</p>
          <p>INTERNET- {StoreSales?.sale?.internet}</p>
          <p>TV- {StoreSales?.sale?.tv}</p>
          <p>WHI- {StoreSales?.sale?.watch}</p>
          <p>
            ARPU-{" "}
            {(
              StoreSales?.sale?.arpu /
              (StoreSales?.subsTotal - StoreSales?.mcTotal)
            ).toFixed(2)}
          </p>
          {/* <p>
            {StoreSales.brand.toUpperCase()}{" "}
            {sessionStorage.getItem("storename").toUpperCase()}
          </p>
          <p>
            SUBS : {StoreSales.subsTotal} /{" "}
            {StoreSales.targets.hupTotal + StoreSales.targets.nacTotal} --{" "}
            {StoreSales.subsTerm}:TERM SUBS
          </p>
          <p>
            NAC : {StoreSales.nacTotal - StoreSales.sale.nacPrepaid} /{" "}
            {StoreSales.targets.nacTotal} --{" "}
            {StoreSales.sale.nacByod + StoreSales.sale.nacBasic}:MTM,{" "}
            {StoreSales.sale.nacTerm}:TERM
          </p>
          <p>PRE : {StoreSales.sale.nacPrepaid}</p>
          <p>
            HUP : {StoreSales.hupTotal} / {StoreSales.targets.hupTotal} --{" "}
            {StoreSales.sale.tvm}:TVM {StoreSales.tvmAttach}%
          </p>
          <p>
            MC : {StoreSales.mcTotal} / {StoreSales.targets.mcTotal}
          </p>
          <p>
            DP : {StoreSales.sale.dp} / {StoreSales.subsTerm} --{" "}
            {StoreSales.dpAttach}%
          </p>
          <p>MIGRATION : {StoreSales.sale.watch}</p>
          <p>IGNITE INTERNET : {StoreSales?.sale?.internet}</p>
          <p>IGNITE TV : {StoreSales?.sale?.tv}</p>
          <p>HOMEPHONE : {StoreSales?.sale?.smartHome}</p>
          <p>
            ACC : ${StoreSales.sale.accessories} / $
            {StoreSales.targets.accessoriesTotal}
          </p>
          <p>
            ARPU : ${(StoreSales.sale.arpu / StoreSales.subsTotal).toFixed(2)}
          </p> */}
          {/* <table style={{justifyContent: 'center', textAlign: 'center'}}>
                    <tr style={{padding: 5}}>
                        <th>{StoreSales.brand.toUpperCase()} {sessionStorage.getItem("storename").toUpperCase()}</th>
                        <th>TOTAL</th>
                        <th>TERM</th>
                        <th>MTM</th>
                    </tr>
                    <tr style={{padding: 5}}>
                        <td>SUBS</td>
                        <td>{StoreSales.subsTotal} / {StoreSales.targets.hupTotal + StoreSales.targets.nacTotal}</td>
                        <td>{StoreSales.subsTerm}</td>
                        <td>{StoreSales.subsTotal - StoreSales.subsTerm}</td>
                    </tr>
                    <tr style={{padding: 5}}>
                        <td>NAC</td>
                        <td>{StoreSales.nacTotal - StoreSales.sale.nacPrepaid} / {StoreSales.targets.nacTotal}</td>
                        <td>{StoreSales.sale.nacTerm}</td>
                        <td>{StoreSales.nacTotal - StoreSales.sale.nacTerm}</td>
                    </tr>
                    <tr style={{padding: 5}}>
                        <td>PREPAID</td>
                        <td>{StoreSales.sale.nacPrepaid}</td>
                        <th>TVM</th>
                        <th>TVM%</th>
                    </tr>
                    <tr style={{padding: 5}}>
                        <td>HUP</td>
                        <td>{StoreSales.hupTotal} / {StoreSales.targets.hupTotal}</td>
                        <td>{StoreSales.sale.tvm}</td>
                        <td>{StoreSales.tvmAttach}%</td>
                    </tr>
                    <tr style={{padding: 5}}>
                        <td>MASTERCARD</td>
                        <td>{StoreSales.mcTotal} / {StoreSales.targets.mcTotal}</td>
                        <th>DP ATTACH</th>
                    </tr>
                    <tr style={{padding: 5}}>
                        <td>DP</td>
                        <td>{StoreSales.sale.dp} / {StoreSales.subsTerm}</td>
                        <td>{StoreSales.dpAttach}%</td>
                        <td></td>
                    </tr>
                    <tr style={{padding: 5}}>
                        <td>ACCESSORIES</td>
                        <td>${StoreSales.sale.accessories} / {StoreSales.targets.accessoriesTotal}</td>
                    </tr>
                    <tr style={{padding: 5}}>
                        <td>ARPU</td>
                        <td>${(StoreSales.sale.arpu / StoreSales.subsTotal).toFixed(2)}</td>
                    </tr>
                </table> */}
        </Grid>
      </Grid>
      {/* <Grid item>
            <Button style={{ justifyContent: 'center', backgroundColor: '#1E2022', borderRadius: 20, textAlign: 'ceneter', fontSize: 20, color: 'white', padding: 5, fontWeight: 'initial', height: 50, width: 225}} onClick={() => Copy()}>Copy</Button>
            </Grid> */}
    </Grid>
  );
};

export default DailyTally;
