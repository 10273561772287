import styled from 'styled-components'
import {Link} from 'react-router-dom'

export const Container = styled.div`
    width: 80%;
    margin: auto;
    font-size: 1rem;
`;

export const SalesDiv = styled.div`
    background: #393E46;
    display: flex;
    height: 50px;
    margin: 10px;
    align-items: center;
    text-align: center;
    border-radius: 10px;
    padding: 15px;
    justify-content: space-evenly;
    
`;

export const ItemContainer = styled.div`
    display: inline-grid;
    align-items: center;
    width: 100px;
    padding: 10px;
`;


export const Item = styled.div`
    color: #FFFFFF;
    flex:1;
    order:0;
`;


export const Header = styled.h1`
    margin-top: 150px;
    display:flex;
    text-align: center;
    justify-content: center;
    padding: 20px;
`;


export const Btns = styled.div`
    display: flex;
    height: 50px;
    margin: 10px;
    align-items: center;
    border-radius: 10px;
    padding: 15px;
    
`;



export const BtnLink = styled.div`
    user-select: none;
    border-radius: 10px;
    background: #393E46;
    white-space: nowarp;
    padding: 8px 25px;
    color: #F0F5F9;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    

    &:active {
        background: #222831
    }
`;