import React, {useState} from 'react'
import Navbar from '../../Components/Area/Navbar'
import AreaDailyPage from '../../Components/Area/DailySalesPage'

const AreaDaily = () => {

    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

  return (
    <>
        <Navbar toggle={toggle}/>

        <AreaDailyPage />
        
    </>
  )
}

export default AreaDaily