import React from 'react'
import Navbar from '../../Components/Admin/Navbar/Navbar'
import Sidebar from '../../Components/Admin/Sidebar/Sidebar'
import './adminhome.scss'
import Users from '../../Components/Admin/Users'

function AdminHome() {
  return (
    <div className="home">
      <Sidebar />
      <div className="adminHomeContainer">
          <Navbar />
          <Users />
        </div>
      </div>
  )
}

export default AdminHome