import styled from 'styled-components'
import {Link as LinkR} from 'react-router-dom'

export const Container = styled.div`
    padding-top 150px;
    width: 80%;
    margin: auto;
    font-size: 1rem;

    text-align: center;
    justify-content: center;
`;

export const Inner = styled.div` 
    background: #C9D6DF;
    padding: 75px;
    display: grid;
    height: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 10px;
    width: 30%;
    margin: auto;
    
`;

export const Form = styled.form`
    color: #C9D6DF;
    display: grid;
    padding: 15px;
    margin: auto;
`;

export const Input = styled.input`
    margin-bottom: 10px;
    border: 2px solid #393E46;
    border-radius: 25px;
    background: #C9D6DF;
    white-space: nowarp;
    padding: 10px 22px;
    color: #393E46;
    font-size: 16px;
    outline: none;
    cursor: pointer;
    text-decoration: none;
`;

export const Header = styled.h1`
    display:flex;
    text-align: center;
    justify-content: center;
    padding: 20px;
`;

export const BtnLink = styled.button`
    margin-top: 25px;
    border-radius: 15px;
    background: #393E46;
    white-space: nowarp;
    padding: 10px 22px;
    color: #F0F5F9;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:active {
        background: #222831
    }
`;