import Nuts from '../../Components/Fun/nuts'


const Error = () => {

  return (
    <>
        <Nuts/> 
    </>
  )
}

export default Error