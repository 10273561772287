import React, { useState, useEffect } from 'react'
import axios from 'axios'
import StoreSales from '../DailySale'
import AreaSales from '../DailySalesTotal'
import {Item, SalesDiv, Container, Header, Btns, BtnLink, ItemContainer} from './WeeklySalePageElements'
import { useLocation, useNavigate, useParams} from 'react-router-dom'
import {Temporal} from '@js-temporal/polyfill'
import { CircularProgress } from '@mui/material'
import { useAlert } from 'react-alert'
import { TotalSales } from '../../Common/TotalSales'


function Sales() {
    
    const alert = useAlert()
    const navigate = useNavigate()
    let location = useLocation()

    const area = sessionStorage.getItem('area')

    let {year, month, day} = useParams();
    year = parseInt(year)
    month = parseInt(month)
    day = parseInt(day)
   
    const[inputYear, setInputYear] = useState(parseInt(year));
    const[inputMonth, setInputMonth] = useState(parseInt(month));
    const[inputDay, setInputDay] = useState(parseInt(day));


    const[storeSales, setStoreSales] = useState([]);
    const[areaSales, setAreaSales] = useState([]);
    

    
    const [isLoading, setIsLoading] = useState(true)

    
    let currentDate = Temporal.Now.plainDateISO()
    let inputDate = Temporal.PlainDate.from({ year: inputYear, month: inputMonth, day: inputDay })


    function Yesterday(){
        inputDate = inputDate.subtract({days: 1})

            setInputYear(inputDate.year)
            setInputMonth(inputDate.month)
            setInputDay(inputDate.day)
            navigate(`/area/daily/` + inputDate.year + `/` + inputDate.month + `/` + inputDate.day)
            
            
    }

    function Tomorrow(){

        inputDate = inputDate.add({days: 1})

        if(Temporal.PlainDate.compare(currentDate, inputDate) !== -1){
            
            setInputYear(inputDate.year)
            setInputMonth(inputDate.month)
            setInputDay(inputDate.day)

            navigate(`/area/daily/` + inputDate.year + `/` + inputDate.month + `/` + inputDate.day)
        }else{
            inputDate = inputDate.subtract({days: 1})
        }
    }



    useEffect(() => {
        setIsLoading(true)
        async function FetchData(){
                try{
                const {data} = await axios.get(`/area/areadaily/` + area + `/` + year + `/` + month + `/` + day, {withCredentials: true});
                    //console.log(data.sales)
                setStoreSales(data.sales)
                setAreaSales(TotalSales(data.sales))
                setIsLoading(false)

                }catch (err) {
                    console.log(err)
                    setTimeout(function() {
                        window.location.reload()
                        }, 500);
                        console.log(err.response.data.message)
                        alert.error('Refreshing page')
                    if(err.response.data.message === 'Not Authorized! Login to gain access!' && err.response.data.success === false){
                        alert.error(err.response.data.message)
                        sessionStorage.clear()
                        setTimeout(function() {
                            navigate("/")
                            alert.success("Signed Out!")
                        }, 2000);
                    }
                }
                

                
            }

        FetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])



return (
    <>
        <Container style={{justifyContent: 'center', allignItems: 'center'}}>
            <Header>Daily Sales</Header>
        </Container>

        <Container>
            <Btns>
                <Item><BtnLink onClick={() => Yesterday()}>Back</BtnLink></Item>
                <Item></Item>
                <Item></Item>
                <Item></Item>
                <Item></Item>
                <Item></Item>
                <Item></Item>
                <Item></Item>
                <Item></Item>
                <Item></Item>
                <Item><BtnLink onClick={() => Tomorrow()}>Next</BtnLink></Item>
            </Btns>
        </Container>

        <Container>
            <SalesDiv>
                <ItemContainer><Item>BRAND</Item></ItemContainer>
                <ItemContainer><Item>STORE</Item></ItemContainer>
                <ItemContainer><Item>NAC</Item></ItemContainer>
                <ItemContainer><Item>HUP</Item></ItemContainer>
                <ItemContainer><Item>MC</Item></ItemContainer>
                <ItemContainer><Item>ACC</Item></ItemContainer>
                <ItemContainer><Item>DP</Item></ItemContainer>
                <ItemContainer><Item>MISC</Item></ItemContainer>
                <ItemContainer><Item>TOTAL SUBS</Item></ItemContainer>
            </SalesDiv>
        </Container>

        { isLoading && <Header style={{justifyContent: 'center', marginTop: 15}}><CircularProgress /></Header>}

        <div className="bg-light mt-4">
            {storeSales && storeSales.map((sale) => {
                return(
                <StoreSales sale={sale} key={sale?.employeeID}/>
                )
            })}
        </div>
        <div>
            <AreaSales sale={areaSales}/>
        </div>
    </>
  )
}

export default Sales