import React, { useState, useEffect } from 'react'
import axios from 'axios'
import RepSales from '../../Rep/DailySale'
import StoreSales from '../../Rep/DailySalesTotal'
import {Item, SalesDiv, Container, Header, Btns, BtnLink, ItemContainer} from './MonthlySalesElements'
import { useLocation, useNavigate, useParams} from 'react-router-dom'
import {Temporal} from '@js-temporal/polyfill'
import { CircularProgress } from '@material-ui/core'


function MonthlySalesPage() {

    const navigate = useNavigate()
    let location = useLocation()

    const store = sessionStorage.getItem('store')

    let {year, month} = useParams();
    year = parseInt(year)
    month = parseInt(month)

    const[inputYear, setInputYear] = useState(parseInt(year));
    const[inputMonth, setInputMonth] = useState(parseInt(month));
    const[inputDay, setInputDay] = useState(parseInt(1));


    const[repSales, setRepSales] = useState([]);
    const[storeSales, setStoreSales] = useState(null);

    const [isLoading, setIsLoading] = useState(true)



    let currentDate = Temporal.Now.plainDateISO()
    let inputDate = Temporal.PlainDate.from({ year: inputYear, month: inputMonth, day: inputDay })


    
    function Yesterday(){
        inputDate = inputDate.subtract({months: 1})

            setInputYear(inputDate.year)
            setInputMonth(inputDate.month)
            navigate('/' + store + `/monthly/` + inputDate.year + `/` + inputDate.month)
            
            
    }

    function Tomorrow(){

        inputDate = inputDate.add({months: 1})

        if(Temporal.PlainDate.compare(currentDate, inputDate) !== -1){
            
            setInputYear(inputDate.year)
            setInputMonth(inputDate.month)

            navigate('/' + store + `/monthly/` + inputDate.year + `/` + inputDate.month)
        }else{
            inputDate = inputDate.subtract({months: 1})
        }
    }




    useEffect(() => {
        async function FetchData(){
            setIsLoading(true)
            if(Temporal.PlainDate.compare(currentDate, inputDate) === -1){
                setInputYear(currentDate.year)
                setInputMonth(currentDate.month)
            }
            try{


                const getUserDailySales = await axios.get(`/sales/monthly/` + store + `/` + inputYear + `/` + inputMonth, {withCredentials: true});
                
                const getStoreDailySales = await axios.get(`/sales/store/monthly/` + store + `/` + inputYear + `/` + inputMonth, {withCredentials: true});

                setStoreSales(getStoreDailySales?.data?.monthly);
                setRepSales(getUserDailySales?.data?.monthly);
                setIsLoading(false)


            }catch (err) {
                setTimeout(function() {
                    window.location.reload()
                    }, 500);
                    console.log(err.response.data.message)
                    alert.error('Refreshing page')
                    if(err.response.data.message === 'Not Authorized! Login to gain access!' && err.response.data.success === false){
                        alert.error(err.response.data.message)
                        sessionStorage.clear()
                        setTimeout(function() {
                            navigate("/")
                            alert.success("Signed Out!")
                        }, 2000);
                    }
                }
            }

        FetchData()
    }, [location])
    
  return (
    <>
        <Container>
            <Header>Monthly Sales</Header>
        </Container>

        <Container>
            <Btns>
                <Item><BtnLink onClick={() => Yesterday()}>Back</BtnLink></Item>
                <Item></Item>
                <Item></Item>
                <Item></Item>
                <Item></Item>
                <Item></Item>
                <Item></Item>
                <Item></Item>
                <Item></Item>
                <Item></Item>
                <Item></Item>
                <Item><BtnLink onClick={() => Tomorrow()}>Next</BtnLink></Item>
            </Btns>
        </Container>

        <Container>
            <SalesDiv>
                <ItemContainer><Item>REP</Item></ItemContainer>
                <ItemContainer><Item>NAC</Item></ItemContainer>
                <ItemContainer><Item>HUP</Item></ItemContainer>
                <ItemContainer><Item>MC</Item></ItemContainer>
                <ItemContainer><Item>ACC</Item></ItemContainer>
                <ItemContainer><Item>DP</Item></ItemContainer>
                <ItemContainer><Item>IGNITE</Item></ItemContainer>
                <ItemContainer><Item>MISC</Item></ItemContainer>
                <ItemContainer><Item>TOTAL SUBS</Item></ItemContainer>
            </SalesDiv>
        </Container>

        { isLoading && <Header style={{justifyContent: 'center', marginTop: 15}}><CircularProgress /></Header>}

        <div className="bg-light mt-4">
            {repSales.map((sale) => {
                  return(
                <RepSales sale={sale} key={sale?.employeeID} type='monthly'/>
                )
            })}
        </div>
            <div>
                <StoreSales sale={storeSales} type='monthly'/>
        </div>
    </>
  )
}

export default MonthlySalesPage