import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProtectedRoutes from "./Components/LoginForm/ProtectedRoutes";


//Any Pages
import Login from "./Pages/Any/Login";
//Admin Pages
import AdminHome from "./Pages/Admin/AdminHome";
//Rep Pages
import Home from "./Pages/Rep/Home";
import DailySales from "./Pages/Rep/DailySales";
import Sale from "./Pages/Rep/Sale"
import MonthlySales from "./Pages/Rep/MonthlySales";
import Management from "./Pages/Rep/Management";
import Error from "./Pages/Rep/Error";
import WeeklySales from './Pages/Rep/WeeklySales'
import Return from './Pages/Rep/Return'
import Profile from "./Pages/Rep/Profile"

import AreaHome from "./Pages/Area/Home";
import AreaDaily from "./Pages/Area/AreaDaily";
import RepSales from "./Pages/Rep/RepSales";

function App() {
  //Creating Role Based Routing
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/'>
          <Route index element={<Login />}/>
          <Route element={<ProtectedRoutes PermissionsRequired={400} MaxPermission={400}/>}>
            <Route path='admin'>
              <Route index element={<AdminHome />}/>
            </Route>
          </Route>

      <>
        <Route element={<ProtectedRoutes PermissionsRequired={1} MaxPermission={99} />}>
          <Route path=':store'>
            <Route index element={<Home/>}/>
          </Route>

          <Route path=':store/return'>
            <Route index element={<Return/>}/>
          </Route>

          <Route path=':store/daily/:year/:month/:day'>
            <Route index element={<DailySales/>}/>
          </Route>

          <Route path=':store/weekly/:employee/:year/:week'>
            <Route index element={<RepSales/>}/>
          </Route>

          <Route path=':store/weekly/:year/:week'>
            <Route index element={<WeeklySales/>}/>
          </Route>

          <Route path=':store/monthly/:year/:month'>
            <Route index element={<MonthlySales/>}/>
          </Route>

          <Route path=':store/sale/:id'>
            <Route index element={<Sale/>}/>
          </Route>
        </Route>

        <Route path='/profile'>
            <Route index element={<Profile/>}/>
          </Route>

        <Route element={<ProtectedRoutes PermissionsRequired={3} MaxPermission={99}/>}>
          <Route path=':store/management'>
            <Route index element={<Management/>}/>
          </Route>
          <Route path=':store/users'>
            <Route index element={<Management/>}/>
          </Route>
          <Route path='error'>
            <Route index element={<Error/>}/>
          </Route>
      </Route>
      </>
      <>
        <Route element={<ProtectedRoutes PermissionsRequired={100} MaxPermission={300}/>}>
            <Route path='area'>
              <Route index element={<AreaHome />}/>
            </Route>
            <Route path='area/daily/:year/:month/:day'>
              <Route index element={<AreaDaily />}/>
            </Route>
        </Route>
      </>
      <>
        <Route element={<ProtectedRoutes PermissionsRequired={200} MaxPermission={300}/>}>
            <Route path='region'>
              <Route index element={<Error />}/>
            </Route>
        </Route>
      </>
      <>
        <Route element={<ProtectedRoutes PermissionsRequired={300} MaxPermission={300}/>}>
            <Route path='national'>
              <Route index element={<Error />}/>
            </Route>
        </Route>
      </>

      <>
        <Route element={<ProtectedRoutes PermissionsRequired={500} MaxPermission={500}/>}>
            <Route path='*'>
              <Route index element={<Login />}/>
            </Route>
        </Route>
      </>

        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
