import './login.scss'
import React, {useState} from 'react'
import Login from '../../Components/LoginForm'

const LoginPage = () => {

    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

  return (
    <>
        <Login></Login>
    </>
  )
}

export default LoginPage