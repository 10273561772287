import { Button, CircularProgress, Grid, TextField } from '@mui/material'
import { Form } from 'formik'
import React, { useEffect, useState } from 'react'
import fido from '../../../Assets/FidoLogo.png'
import rogers from '../../../Assets/RogersLogo.png'
import none from '../../../Assets/NoLogo.png'
import UpdateUser from './UpdateUser'
import axios from 'axios'
import { useLocation, useNavigate } from 'react-router-dom'

function Profile() {
    const location = useLocation()
    const navigate = useNavigate()
    const [edit, setEdit] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [user, setUser] = useState()

    useEffect(() => {
        setIsLoading(true)
        async function FetchData(){

            try{
                const item = await axios.get(`/store/user/` + sessionStorage.getItem('employee'), {withCredentials: true});
                setUser(item.data.employee)
                setIsLoading(false)

            }catch (err) {
                if(err.response.status === 500){
                    setIsLoading(false)
                    console.log(err.response.data.message)
                    alert.error(err.response.data.message)
                }
                if(err.response.data.message === 'Not Authorized! Login to gain access!' && err.response.data.success === false){
                    alert.error(err.response.data.message)
                    sessionStorage.clear()
                    setTimeout(function() {
                        navigate("/")
                        alert.success("Signed Out!")
                    }, 2000);
                }
            }
                

                
            }

        FetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

  return (
  <>
  {isLoading ? <Grid container style={{justifyContent: 'center', marginTop: 150}}><CircularProgress /></Grid>  : 
  <Grid container style={{marginTop: 150}}>
        {/* <Grid container style={{justifyContent: 'center'}}>
            <h1>Profile</h1>
        </Grid> */}
        
        <Grid container style={{justifyContent: 'center'}}>
          <img sx={{boxShadow: 3}} src={rogers} style={{width: 150, borderRadius: 150, backgroundColor: "white"}} alt="Logo"/>
        </Grid>
        <Grid container style={{justifyContent: 'center'}}>

            <Grid container xs={12} style={{justifyContent: 'center', margin: 15}}>
                <h2>{user?.firstName} {user?.lastName}</h2>
            </Grid>

            <Grid container xs={12} style={{justifyContent: 'center', margin: 15}}>
                <h4>{user?.role}</h4>
            </Grid>

            <Grid container xs={12} style={{justifyContent: 'center', margin: 15}}>
                <h3>{user?.email}</h3>
            </Grid>

            <Grid container xs={'auto'} style={{justifyContent: 'right', margin: 15}}>
                <h3>Store: {user?.workplace?.store}</h3>
            </Grid>

            <Grid container xs={'auto'} style={{justifyContent: 'left', margin: 15}}>
                <h3>Brand: {user?.workplace?.brand}</h3>
            </Grid>

            {!edit &&<Grid  container xs={12} style={{justifyContent: 'center', margin: 50}}>
                <Button onClick={() => setEdit(true)}>Change Password</Button>
            </Grid>}
        </Grid>
        {edit && <Grid container>
            <UpdateUser employee={user}/>
            {edit &&<Grid  container xs={12} style={{justifyContent: 'center', margin: 50}}>
                <Button onClick={() => setEdit(false)}>Cancel</Button>
            </Grid>}
        </Grid>}
    </Grid>}
 </>
  )
}

export default Profile