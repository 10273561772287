import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  BtnLink,
  Container,
  Item,
  Sales,
  ToolItem,
  ItemContainer,
  ItemColor,
} from "./DailySaleElements";
import { Tooltip } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import { Temporal } from "@js-temporal/polyfill";

function Sale({ sale, type }) {
  if (type === "custom") {
    type = "daily";
  }
  let item = [
    "null",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  let info = item[0];
  let show = true;
  let guest = parseInt(sale?.store?.toString() + sale?.store?.toString());
  if (sale?.employeeID === guest && sale?.commission === 0) {
    show = false;
  }
  let dayString = null;
  if (type === "day") {
    dayString = new Temporal.PlainDateTime(sale?.year, sale?.month, sale?.day)
      .dayOfWeek;
    info = item[dayString];
    // alert(dayString)
  }

  // { sale object
  //     "sale": {
  //       "nacByod": 0,
  //       "nacTerm": 0,
  //       "nacBasic": 0,
  //       "nacPrepaid": 0,
  //       "hup": 0,
  //       "tvm": 0,
  //       "mbb": 0,
  //       "mc": 0,
  //       "dp": 0,
  //       "accessories": 0,
  //       "valuepack": 0,
  //       "watch": 0,
  //       "internet": 0,
  //       "tv": 0,
  //       "smartHome": 0,
  //       "express": 0,
  //       "bpo": 0,
  //       "arpu": 0
  //   },
  //   "targets": {
  //       "nacTotal": 5,
  //       "hupTotal": 3,
  //       "mcTotal": 1,
  //       "accessoriesTotal": 323,
  //       "dpTotal": 60
  //   },
  //   "_id": "652c0c48b2ab9e11c85db058",
  //   "year": 2023,
  //   "quarter": "Q4",
  //   "month": 10,
  //   "week": 42,
  //   "day": 15,
  //   "date": "2023/10/15",
  //   "store": 5501,
  //   "area": 35,
  //   "brand": "Fido",
  //   "region": "West",
  //   "dealer": "F20AA",
  //   "name": "Mehran",
  //   "employeeID": 12149803,
  //   "nacTotal": 0,
  //   "hupTotal": 0,
  //   "mbbTotal": 0,
  //   "mcTotal": 0,
  //   "subsTotal": 0,
  //   "subsTerm": 0,
  //   "tvmAttach": 0,
  //   "mbbAttach": 0,
  //   "mcAttach": 0,
  //   "dpAttach": 0,
  //   "ars": 0,
  //   "commission": 0,
  //   "__v": 0
  // }

  return (
    <>
      {show && (
        <Container>
          <Sales>
            <ItemContainer>
              <Item>{type === "day" ? info : sale?.name}</Item>
            </ItemContainer>

            <Tooltip
              placement="top"
              title={
                <div
                  style={{
                    backgroundColor: "#393E46",
                    borderRadius: 5,
                    color: "white",
                    padding: 10,
                    margin: 3,
                  }}
                >
                  <ToolItem>MTM NAC: {sale?.sale?.nacByod}</ToolItem>
                  <ToolItem>2YR NAC: {sale?.sale?.nacTerm}</ToolItem>
                  <ToolItem>BSC NAC: {sale?.sale?.nacBasic}</ToolItem>
                  <ToolItem>PREPAID: {sale?.sale?.nacPrepaid}</ToolItem>
                </div>
              }
            >
              <ItemContainer>
                <Item>
                  {sale?.nacTotal - sale?.sale?.nacPrepaid} /{" "}
                  {sale?.targets?.nacTotal ? sale?.targets?.nacTotal : 0}
                </Item>
                <ItemColor
                  style={{
                    backgroundColor: `${
                      sale?.nacTotal - sale?.sale?.nacPrepaid >=
                      sale?.targets?.nacTotal
                        ? "green"
                        : sale?.nacTotal - sale?.sale?.nacPrepaid >=
                          parseInt(sale?.targets?.nacTotal) / 2
                        ? "Yellow"
                        : "#EE4B2B"
                    }`,
                  }}
                ></ItemColor>
              </ItemContainer>
            </Tooltip>

            <Tooltip
              placement="top"
              title={
                <div
                  style={{
                    backgroundColor: "#393E46",
                    borderRadius: 5,
                    color: "white",
                    padding: 10,
                    margin: 3,
                  }}
                >
                  <ToolItem>HUP: {sale?.sale?.hup}</ToolItem>
                  <ToolItem>TVM: {sale?.sale?.tvm}</ToolItem>
                  <ToolItem>TVM%: {sale?.tvmAttach}%</ToolItem>
                </div>
              }
            >
              <ItemContainer>
                <Item>
                  {sale?.hupTotal} /{" "}
                  {sale?.targets?.hupTotal ? sale?.targets?.hupTotal : 0}
                </Item>
                <ItemColor
                  style={{
                    backgroundColor: `${
                      sale?.hupTotal >= sale?.targets?.hupTotal
                        ? "green"
                        : sale?.hupTotal >=
                          parseInt(sale?.targets?.hupTotal) / 2
                        ? "Yellow"
                        : "#EE4B2B"
                    }`,
                  }}
                ></ItemColor>
              </ItemContainer>
            </Tooltip>

            <Tooltip
              placement="top"
              title={
                <div
                  style={{
                    backgroundColor: "#393E46",
                    borderRadius: 5,
                    color: "white",
                    padding: 10,
                    margin: 3,
                  }}
                >
                  <ToolItem>MC: {sale?.sale?.mc}</ToolItem>
                  <ToolItem>MC ATTACH: {sale?.mcAttach}%</ToolItem>
                </div>
              }
            >
              <ItemContainer>
                <Item>
                  {sale?.mcTotal} /{" "}
                  {sale?.targets?.mcTotal ? sale?.targets?.mcTotal : 0}
                </Item>
                <ItemColor
                  style={{
                    backgroundColor: `${
                      sale?.mcTotal >= sale?.targets?.mcTotal
                        ? "green"
                        : sale?.mcTotal >= parseInt(sale?.targets?.mcTotal) / 2
                        ? "Yellow"
                        : "#EE4B2B"
                    }`,
                  }}
                ></ItemColor>
              </ItemContainer>
            </Tooltip>

            <Tooltip
              placement="top"
              title={
                <div
                  style={{
                    backgroundColor: "#393E46",
                    borderRadius: 5,
                    color: "white",
                    padding: 10,
                    margin: 3,
                  }}
                >
                  <ToolItem>ACC: ${sale?.sale?.accessories}</ToolItem>
                  <ToolItem>ARS: ${sale?.ars}</ToolItem>
                </div>
              }
            >
              <ItemContainer>
                <Item>
                  ${sale?.sale?.accessories}/
                  {sale?.targets?.accessoriesTotal
                    ? sale?.targets?.accessoriesTotal
                    : 0}
                </Item>
                <ItemColor
                  style={{
                    backgroundColor: `${
                      sale?.sale?.accessories >= sale?.targets?.accessoriesTotal
                        ? "green"
                        : sale?.sale?.accessories >=
                          parseInt(sale?.targets?.accessoriesTotal) / 2
                        ? "Yellow"
                        : "#EE4B2B"
                    }`,
                  }}
                ></ItemColor>
              </ItemContainer>
            </Tooltip>

            <Tooltip
              placement="top"
              background="clear"
              title={
                <div
                  style={{
                    backgroundColor: "#393E46",
                    borderRadius: 5,
                    color: "white",
                    padding: 10,
                    margin: 3,
                  }}
                >
                  <ToolItem>
                    DP: {sale?.sale?.dp} / {sale?.subsTerm}
                  </ToolItem>
                  <ToolItem>DP%: {sale?.dpAttach}%</ToolItem>
                </div>
              }
            >
              <ItemContainer>
                <Item>
                  {sale?.dpAttach}/
                  {sale?.targets?.dpTotal ? sale?.targets?.dpTotal : 0}%
                </Item>
                <ItemColor
                  style={{
                    backgroundColor: `${
                      sale?.dpAttach >= sale?.targets?.dpTotal
                        ? "green"
                        : sale?.sale?.dp >= parseInt(sale?.targets?.dpTotal) / 2
                        ? "Yellow"
                        : "#EE4B2B"
                    }`,
                  }}
                ></ItemColor>
              </ItemContainer>
            </Tooltip>
            <Tooltip
              placement="top"
              title={
                <div
                  style={{
                    backgroundColor: "#393E46",
                    borderRadius: 5,
                    color: "white",
                    padding: 10,
                    margin: 3,
                  }}
                >
                  <ToolItem>TV: {sale?.sale?.tv}</ToolItem>
                  <ToolItem>Internet: {sale?.sale?.internet}</ToolItem>
                  <ToolItem>Home Phone: {sale?.sale?.smartHome}</ToolItem>
                  <ToolItem>WHI: {sale?.sale?.watch}</ToolItem>
                  <ToolItem>Renew: {sale?.sale?.mbb}</ToolItem>
                </div>
              }
            >
              <ItemContainer>
                <Item>
                  {(sale?.sale?.tv +
                    sale?.sale?.internet +
                    sale?.sale?.smartHome +
                    sale?.sale?.watch +
                    sale?.sale?.mbb) |
                    0}
                </Item>
                <ItemColor
                  style={{
                    backgroundColor: `${
                      sale?.sale?.tv +
                        sale?.sale?.internet +
                        sale?.sale?.smartHome +
                        sale?.sale?.watch + sale?.sale?.mbb >
                      0
                        ? "green"
                        : "#EE4B2B"
                    }`,
                  }}
                ></ItemColor>
              </ItemContainer>
            </Tooltip>
            <Tooltip
              placement="top"
              title={
                <div
                  style={{
                    backgroundColor: "#393E46",
                    borderRadius: 5,
                    color: "white",
                    padding: 10,
                    margin: 3,
                  }}
                >
                  {type === "weekly" ? (
                    <ToolItem>
                      TOTAL SUBS:{" "}
                      {sale?.subsTotal + sale?.sale?.watch - sale?.mcTotal} /{" "}
                      {sale?.targets?.hupTotal + sale?.targets?.nacTotal}
                    </ToolItem>
                  ) : null}
                  <ToolItem>EXPRESS: {sale?.sale?.express}</ToolItem>
                  <ToolItem>VALUEPACK: {sale?.sale?.valuepack}</ToolItem>
                  <ToolItem>
                    ARPU: $
                    {(
                      sale?.sale?.arpu /
                      (sale?.subsTotal - sale?.mcTotal)
                    ).toFixed(2)}
                  </ToolItem>
                  <ToolItem>BPO USED: ${sale?.sale?.bpo}</ToolItem>
                  {/* {parseInt(sessionStorage.getItem("employee")) ===
                    sale?.employeeID ||
                  parseInt(sessionStorage.getItem("permission")) > 2 ? (
                    <ToolItem>EST EARNING: ${sale?.commission}</ToolItem>
                  ) : null} */}
                </div>
              }
            >
              <ItemContainer>
                <Item>Extras</Item>
              </ItemContainer>
            </Tooltip>

            {type === "weekly" ? (
              <ItemContainer>
                <Item>
                  <BtnLink
                    to={{
                      pathname:
                        `/` +
                        sale?.store +
                        `/weekly/` +
                        sale?.employeeID +
                        `/` +
                        sale?.year +
                        `/` +
                        sale?.week,
                    }}
                  >
                    View
                  </BtnLink>
                </Item>
              </ItemContainer>
            ) : (
              <>
                <Tooltip
                  placement="top"
                  title={
                    <div
                      style={{
                        backgroundColor: "#393E46",
                        borderRadius: 5,
                        color: "white",
                        padding: 10,
                        margin: 3,
                      }}
                    >
                      <ToolItem>
                        TOTAL SUBS:{" "}
                        {sale?.subsTotal + sale?.sale?.watch - sale?.mcTotal} /{" "}
                        {sale?.targets?.hupTotal + sale?.targets?.nacTotal}
                      </ToolItem>
                      <ToolItem>TERM SUBS: {sale?.subsTerm}</ToolItem>
                    </div>
                  }
                >
                  <ItemContainer>
                    <Item>
                      {sale?.subsTotal + sale?.sale?.watch - sale?.mcTotal}
                    </Item>
                  </ItemContainer>
                </Tooltip>
              </>
            )}
          </Sales>
        </Container>
      )}
    </>
  );
}

export default Sale;
