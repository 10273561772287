import React, { useState, useEffect } from "react";
import axios from "axios";
import { Item, SalesDiv, Container, Header } from "./HomePageElements";
import { useLocation, useNavigate } from "react-router-dom";
import SaleForm from "../SaleForm";
import { Temporal } from "@js-temporal/polyfill";
import { Grid, Modal, Paper } from "@mui/material";
import DailyAlert from "../DailyAlert";
import { useAlert } from "react-alert";

function Home() {
  const alert = useAlert();
  let currentDate = Temporal.Now.plainDateISO();
  const [year, setYear] = useState(currentDate.year);
  const [month, setMonth] = useState(currentDate.month);
  const [day, setDay] = useState(currentDate.day);
  //user info
  const [name, setName] = useState();
  const [storeStats, setStoreStats] = useState(null);

  const store = sessionStorage.getItem("store");

  let location = useLocation();

  const [storeData, setStoreData] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [memo, setMemo] = useState();

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 650,
    height: 450,
    bgcolor: "background.paper",
    borderRadius: 15,
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    setName(sessionStorage.getItem("name"));
    let modelOpen = sessionStorage.getItem("model");

    async function FetchData() {
      try {
        const getActive = await axios.put(
          `/store/active`,
          { store },
          { withCredentials: true }
        );
        const getStoreData = await axios.get(
          `/sales/store/` + store + `/` + year + `/` + month,
          { withCredentials: true }
        );
        const getStats = await axios.get(
          `/sales/stats/` + store + `/` + year + `/` + month + `/` + day,
          { withCredentials: true }
        );
        const getMemo = await axios.get(`/store/alert/get/` + store, {
          withCredentials: true,
        });
        if (getMemo.data.success === true) {
          setMemo(getMemo.data.alert);
          if (modelOpen === "true") {
            setOpen(true);
          }
        }

        setStoreData(getStoreData.data.info);
        setEmployees(getActive.data.users);
        setStoreStats(getStats.data.stats);
      } catch (err) {
        if (
          err.response.data.message ===
            "Not Authorized! Login to gain access!" &&
          err.response.data.success === false
        ) {
          alert.error(err.response.data.message);
          sessionStorage.clear();
          setTimeout(function () {
            navigate("/");
            alert.success("Signed Out!");
          }, 2000);
        }
      }
    }
    FetchData();
  }, [location]);

  return (
    <>
      <Container>
        <Header>Welcome back {name}!</Header>
      </Container>
      {memo && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Paper sx={style}>
            <Grid>
              <DailyAlert memo={memo} />
            </Grid>
          </Paper>
        </Modal>
      )}
      <Container>
        <SalesDiv>
          <Item>CSAT</Item>
          <Item>A&O</Item>
          <Item>TVM</Item>
          <Item>BPO</Item>
        </SalesDiv>
        <SalesDiv>
          <Item>{parseFloat(storeData?.csat).toFixed(1) | 0}%</Item>
          <Item>
            {parseFloat(storeStats?.actionsandoffers).toFixed(1) | 0}%
          </Item>
          <Item>{parseInt(storeStats?.tvm).toFixed(1) | 0}%</Item>
          <Item>${parseInt(storeData?.bpo).toFixed(0) | 0}</Item>
        </SalesDiv>
      </Container>

      <SaleForm employees={employees} />
    </>
  );
}

export default Home;
