import './navbar.scss'
import SearchIcon from '@mui/icons-material/Search';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ChatIcon from '@mui/icons-material/ChatBubbleOutline';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { useAlert } from 'react-alert';




function Navbar() {

  const alert = useAlert()
  const navigate = useNavigate()
  function logOut(){
    sessionStorage.clear()
    setTimeout(function() {navigate('/')}, 2000);
    alert.success('Logged Out')
  }

  return (
    <div className='navbar'>
      <div className="wrapper">
        <div className="search">
          <input type="text" placeholder='Search...'/>
          <SearchIcon/>
        </div>
        <div className="items">
          <Button className="item"><NotificationsIcon/></Button>
          <Button className="item"><ChatIcon/></Button>
          <Button className="item" onClick={() => logOut()}><ExitToAppIcon/></Button>
        </div>
      </div>
    </div>
  )
}

export default Navbar