import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { BtnLink, Container, Item, ItemColor, ItemContainer, Sales, ToolItem} from './DailySalesTotalElements'
import { Tooltip } from '@mui/material';
import { useLocation } from 'react-router-dom';

function Sale({sale}) {

    let stringMonth = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'][sale?.month - 1]


  return (
                <Container style={{marginBottom: 150}}>
                    <Sales>
                    <ItemContainer><Item>{sale?.date ?  `${sale?.date}` : sale?.week ? `Week: ${sale?.week}` : `${sale?.quarter} ${stringMonth}`}</Item></ItemContainer>
                    <ItemContainer><Item>{sale?.area ? sale?.area : "Area: "  + sessionStorage.getItem('area')}</Item></ItemContainer>

                    <Tooltip placement="top" title={
                            <div style={{backgroundColor: "#393E46", borderRadius: 5, color: 'white', padding:10, margin:3}}>
                            <ToolItem>MTM NAC: {sale?.sale?.nacByod}</ToolItem>
                            <ToolItem>2YR NAC: {sale?.sale?.nacTerm}</ToolItem>
                            <ToolItem>BSC NAC: {sale?.sale?.nacBasic}</ToolItem>
                            <ToolItem>PREPAID: {sale?.sale?.nacPrepaid}</ToolItem>
                            </div>
                        }>
                        <ItemContainer><Item>{sale?.nacTotal} / {sale?.targets?.nacTotal ? sale?.targets?.nacTotal : 0}</Item><ItemColor style={{backgroundColor: `${(sale?.nacTotal >= sale?.targets?.nacTotal) ? 'green' : (sale?.nacTotal >= (parseInt(sale?.targets?.nacTotal)) / 2) ? 'Yellow' : '#FF4433'}`}}></ItemColor></ItemContainer>
                        
                        </Tooltip>

                        <Tooltip placement="top" title={
                            <div style={{backgroundColor: "#393E46", borderRadius: 5, color: 'white', padding:10, margin:3}}>
                            <ToolItem>HUP: {sale?.sale?.hup}</ToolItem>
                            <ToolItem>TVM: {sale?.sale?.tvm}</ToolItem>
                            <ToolItem>TVM%: {sale?.tvmAttach}%</ToolItem>
                            </div>
                        }>
                        <ItemContainer><Item>{sale?.hupTotal} / {sale?.targets?.hupTotal ? sale?.targets?.hupTotal : 0}</Item><ItemColor style={{backgroundColor: `${(sale?.hupTotal >= sale?.targets?.hupTotal) ? 'green' : (sale?.hupTotal >= (parseInt(sale?.targets?.hupTotal)) / 2) ? 'Yellow' : '#FF4433'}`}}></ItemColor></ItemContainer>
                        </Tooltip>

                        <Tooltip placement="top" title={
                            <div style={{backgroundColor: "#393E46", borderRadius: 5, color: 'white', padding:10, margin:3}}>
                            <ToolItem>MC: {sale?.sale?.mc}</ToolItem>
                            <ToolItem>MC ATTACH: {sale?.mcAttach}%</ToolItem>
                            </div>
                        }>
                        <ItemContainer><Item>{sale?.mcTotal} / {sale?.targets?.mcTotal ? sale?.targets?.mcTotal : 0}</Item><ItemColor style={{backgroundColor: `${(sale?.mcTotal >= sale?.targets?.mcTotal) ? 'green' : (sale?.mcTotal >= (parseInt(sale?.targets?.mcTotal)) / 2) ? 'Yellow' : '#FF4433'}`}}></ItemColor></ItemContainer>
                        </Tooltip>

                        <Tooltip placement="top" title={
                            <div style={{backgroundColor: "#393E46", borderRadius: 5, color: 'white', padding:10, margin:3}}>
                            <ToolItem>ACC: ${sale?.sale?.accessories}</ToolItem>
                            <ToolItem>ARS: ${sale?.ars}</ToolItem>
                            </div>
                        }>
                        <ItemContainer><Item>${sale?.sale?.accessories}/{sale?.targets?.accessoriesTotal ? sale?.targets?.accessoriesTotal : 0}</Item><ItemColor style={{backgroundColor: `${(sale?.sale?.accessories >= sale?.targets?.accessoriesTotal) ? 'green' : (sale?.sale?.accessories >= (parseInt(sale?.targets?.accessoriesTotal)) / 2) ? 'Yellow' : '#FF4433'}`}}></ItemColor></ItemContainer>
                        
                        </Tooltip>

                        <Tooltip placement="top" title={
                            <div style={{backgroundColor: "#393E46", borderRadius: 5, color: 'white', padding:10, margin:3}}>
                            <ToolItem>DP: {sale?.sale?.dp} / {sale?.subsTerm}</ToolItem>
                            <ToolItem>DP%: {sale?.dpAttach}%</ToolItem>
                            </div>
                        }>
                       <ItemContainer><Item>{sale?.dpAttach}/{sale?.targets?.dpTotal ? sale?.targets?.dpTotal : 0}%</Item><ItemColor style={{backgroundColor: `${(sale?.dpAttach >= sale?.targets?.dpTotal) ? 'green' : (sale?.sale?.dp >= (parseInt(sale?.targets?.dpTotal)) / 2) ? 'Yellow' : '#FF4433'}`}}></ItemColor></ItemContainer>
                       
                        </Tooltip>
                        <Tooltip placement="top" title={
                            <div style={{backgroundColor: "#393E46", borderRadius: 5, color: 'white', padding:10, margin:3}}>
                            <ToolItem>MBB: {sale?.sale?.mbb}</ToolItem>
                            <ToolItem>EXPRESS: {sale?.sale?.express}</ToolItem>
                            <ToolItem>VALUEPACK: {sale?.sale?.valuepack}</ToolItem>
                            <ToolItem>BPO USED: ${sale?.sale?.bpo}</ToolItem>
                            {(parseInt(sessionStorage.getItem('employee')) === sale?.employeeID) ? <ToolItem>EST EARNING: ${sale?.commission}</ToolItem> : null}
                            </div>
                        }>
                        <ItemContainer><Item>Extras</Item></ItemContainer>
                        </Tooltip>
                        <>
                        <Tooltip placement="top" title={
                            <div style={{backgroundColor: "#393E46", borderRadius: 5, color: 'white', padding:10, margin:3}}>
                            {sale?.date ? <ToolItem>TOTAL SUBS: {sale?.subsTotal}</ToolItem> : null}
                            <ToolItem>TOTAL SUBS: {sale?.subsTotal}</ToolItem>
                            <ToolItem>TERM SUBS: {sale?.subsTerm}</ToolItem>
                            </div>
                        }>
                         <ItemContainer><Item>{sale?.subsTotal}</Item></ItemContainer>
                         </Tooltip>
                         </>
                    </Sales>
                </Container>
  )
}

export default Sale